import React, { Component, Fragment } from 'react';
import { Route, HashRouter as Router, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl-redux';
import { OidcProvider } from 'redux-oidc';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

import AppTimer from './AppTimer';
import NavContainer from '../containers/NavContainer/NavContainer';
import CallbackPage from './auth/CallbackPage/CallbackPage';
import SignOnPage from './auth/SignOnPage/SignOnPage';
import Signout from './auth/Signout/Signout';
import SearchPage from './search/SearchPage/SearchPage';
import RequireAuth from './hoc/RequireAuth/RequireAuth';
import NoRequireAuth from './hoc/NoRequireAuth/NoRequireAuth';
import UserProfilePage from './user/UserProfilePage/UserProfilePage';
import GroupProfilePage from './group/GroupProfilePage/GroupProfilePage';
import GenericProfilePage from './generic/GenericProfilePage/GenericProfilePage';
import NotFoundPage from './shared/pages/NotFoundPage/NotFoundPage';
import MessageContainer from '../containers/MessageContainer/MessageContainer';
import ErrorBoundary from './error/ErrorBoundary/ErrorBoundary';
import ReportPage from './shared/pages/ReportPage/ReportPage';
import store from '../store';
import userManager from '../utils/userManager';
import * as routes from '../utils/routes';

import './App.css';

const AuthNavContainer = RequireAuth(NavContainer, false);
const AuthMessageContainer = RequireAuth(MessageContainer, false, false, true);

// Component needs to be a class to support the DragDropContext applied to it.
// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  render() {
    const { hash } = window.location;
    if (!hash.includes('callback')) localStorage.setItem('initialURL', hash.substr(1));

    return (
      <ErrorBoundary>
        <Provider store={store}>
          <OidcProvider store={store} userManager={userManager}>
            <IntlProvider locale="en" textComponent={React.Fragment}>
              <Router>
                <Fragment>
                  <AuthNavContainer />
                  <div className="App">
                    <AppTimer />
                    <AuthMessageContainer />
                    <Switch>
                      <Route exact path={routes.BASE_ROUTE} component={NoRequireAuth(SignOnPage)} />
                      <Route exact path={routes.CALLBACK_ROUTE} component={CallbackPage} />
                      <Route
                        exact
                        path={routes.SIGNOUT_ROUTE}
                        component={RequireAuth(Signout, true, true)}
                      />
                      <Route
                        exact
                        path={routes.USERS_PROFILE_ROUTE}
                        component={RequireAuth(UserProfilePage)}
                      />
                      <Route
                        exact
                        path={routes.USERS_AND_GROUPS_SEARCH_ROUTE}
                        component={RequireAuth(SearchPage)}
                      />
                      <Route
                        exact
                        path={routes.USERS_AND_GROUPS_REPORT_ROUTE}
                        component={RequireAuth(ReportPage)}
                      />
                      <Route
                        exact
                        path={routes.GROUPS_PROFILE_ROUTE}
                        component={RequireAuth(GroupProfilePage)}
                      />
                      <Route
                        exact
                        path={routes.GENERICS_REPORT}
                        component={RequireAuth(ReportPage)}
                      />
                      <Route
                        exact
                        path={routes.GENERICS_PROFILE_ROUTE}
                        component={RequireAuth(GenericProfilePage)}
                      />
                      <Route
                        exact
                        path={routes.GENERICS_SEARCH_ROUTE}
                        component={RequireAuth(SearchPage)}
                      />
                      <Route path={routes.NOT_FOUND_ROUTE} component={RequireAuth(NotFoundPage)} />
                      <Redirect to={routes.NOT_FOUND_ROUTE} />
                    </Switch>
                  </div>
                </Fragment>
              </Router>
            </IntlProvider>
          </OidcProvider>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default DragDropContext(HTML5Backend)(App);
