import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './Breadcrumb.css';

const Breadcrumb = ({ path, label }) => (
  <div className="Breadcrumb">
    <Link to={path}>
      <span className="inline-icon icon-dropdown-arrow" />
      {label}
    </Link>
  </div>
);

Breadcrumb.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default Breadcrumb;
