import * as types from '../actionTypes';
import {
  logErrorToConsole,
} from '../../../utils/helpers';
import { createAction } from '../index';

export const modelFormActions = {
  clear: () => createAction(types.MODEL_FORM_CLEAR),
  clearPasswordError: () => createAction(types.MODEL_FORM_CLEAR_PASSWORD_ERROR),
  set: data => createAction(types.MODEL_FORM_SET, { data }),
};

export function clearModelFormAction() {
  return async dispatch => dispatch(modelFormActions.clear());
}

export function clearModelFormPasswordErrorAction() {
  return async dispatch => dispatch(modelFormActions.clearPasswordError());
}

export function setModelFormAction(object) {
  return async (dispatch) => {
    try {
      dispatch(modelFormActions.set(object));
      Promise.resolve();
    } catch (error) {
      logErrorToConsole(error);
      Promise.reject();
    }
  };
}
