import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Spinner from 'ui-library/lib/components/general/Spinner';
import DropDownList from '../../list-inputs/DropDownList/DropDownList';
import ReportTable from '../../tables/ReportTable';
import { getLabel, convertTimestampToDate } from '../../../../utils/helpers';

class RecentLoginSummary extends Component {
  state = {
    isFetching: false,
    options: undefined,
    selectedOption: undefined,
  };

  componentDidMount() {
    this.setDropDownOptions();
  }

  setDropDownOptions = () => {
    const options = Object.keys(this.props.loginHistory).map(key => (
      {
        id: key,
        label: getLabel(key),
        value: key,
      }
    ));

    this.setState({
      options,
      selectedOption: options[0],
    });
  }

  displayContent() {
    const { loginHistory } = this.props;
    const { isFetching, selectedOption } = this.state;
    const data = {
      isFetching,
      results: {
        header: [],
        rows: [],
      },
    };
    const filters = [
      'timestamp',
      'client-ip-address',
      'authentication-method',
      'additional-attempt-count',
    ];

    if (!_.isEmpty(loginHistory[selectedOption.id])) {
      if (selectedOption.id === 'failed-attempts') {
        filters.unshift('failure-reason');
      }

      filters.forEach(filter => data.results.header.push(getLabel(filter)));

      loginHistory[selectedOption.id].forEach((item) => {
        const row = filters.map(filter => convertTimestampToDate(item[filter]));
        data.results.rows.push(row);
      });
    }

    return (
      <Spinner show={isFetching}>
        <ReportTable data={data} />
      </Spinner>
    );
  }

  handleDropDownChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  render() {
    const { options, selectedOption } = this.state;
    return (
      <>
        <DropDownList
          handleOnChange={this.handleDropDownChange}
          label={{ id: 'components.drop-down.labels.default' }}
          options={options}
          selectedOption={selectedOption}
        />
        {
          !_.isUndefined(selectedOption) &&
          <div className="AccordionProfile">
            {this.displayContent()}
          </div>
        }
      </>
    );
  }
}


RecentLoginSummary.propTypes = {
  loginHistory: PropTypes.shape({}).isRequired,
};

export default RecentLoginSummary;
