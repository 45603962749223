const generateModelFormAttributesPayload = (attributes, relationshipOptions) => {
  let payload = {
    attributes,
  };

  if (relationshipOptions) {
    let relationships;
    Object.keys(relationshipOptions).forEach((key) => {
      const relationshipsKey = (key === 'generics') ? 'orgs' : key;
      const { id, type } = relationshipOptions[key];
      relationships = {
        ...relationships,
        [relationshipsKey]: {
          data: {
            id,
            type,
          },
        },
      };
    });

    payload = {
      ...payload,
      relationships,
    };
  }
  return payload;
};

export default generateModelFormAttributesPayload;
