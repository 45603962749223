import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import className from 'classnames';
import FormDropDownList from 'ui-library/lib/components/forms/FormDropDownList';
import intlShape from '../../../../utils/intlPropType';
import './DropDownList.css';

export const DropDownList = (props) => {
  const {
    disabled,
    errorMessage,
    handleOnChange,
    handleOnToggle,
    hideLabel,
    intl,
    label,
    labelPrompt,
    noneOption,
    required,
    options,
    selectedOption,
    onSearch,
  } = props;
  const error = errorMessage ? intl.formatMessage({ id: errorMessage }) : '';
  const inputProps = {};

  if (noneOption) {
    inputProps.noneOption = noneOption;
  }

  return (
    <FormDropDownList
      className={className(
        { 'drop-down__label--hide': hideLabel },
      )}
      disabled={disabled}
      errorMessage={error}
      label={intl.formatMessage({ id: label.id }, { displayName: label.displayName })}
      labelPrompt={intl.formatMessage({ id: labelPrompt })}
      onToggle={() => handleOnToggle()}
      onValueChange={option => handleOnChange(option)}
      options={options}
      required={required}
      searchType={FormDropDownList.SearchTypes.BOX}
      selectedOption={selectedOption}
      onSearch={onSearch}
      {...inputProps}
    />
  );
};

DropDownList.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  handleOnToggle: PropTypes.func,
  hideLabel: PropTypes.bool,
  intl: intlShape.isRequired,
  label: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }),
  labelPrompt: PropTypes.string,
  noneOption: PropTypes.shape({
    label: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  required: PropTypes.bool,
  selectedOption: PropTypes.shape({}),
  onSearch: PropTypes.func,
};

DropDownList.defaultProps = {
  disabled: false,
  errorMessage: '',
  handleOnToggle: () => {},
  hideLabel: false,
  label: {
    id: 'common.select',
    displayName: '',
  },
  labelPrompt: 'components.search-bar-drop-down.list-prompt',
  noneOption: undefined,
  required: false,
  options: [],
  selectedOption: {},
  onSearch: () => {},
};

export default injectIntl((DropDownList));
