import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LinkDropDownList from 'ui-library/lib/components/forms/LinkDropDownList';

class SortDropdown extends Component {
  constructor(props) {
    super(props);

    this.handleSortToggle = this.handleSortToggle.bind(this);

    this.state = {
      sortDropdownOpen: false,
    };
  }

  handleSortToggle() {
    this.setState({ sortDropdownOpen: !this.state.sortDropdownOpen });
  }

  render() {
    return (
      <LinkDropDownList
        flags={['use-portal', 'p-stateful']}
        className={this.props.className}
        label={this.props.label}
        closeOnClick
        onClick={this.props.onSortClick}
        onToggle={this.handleSortToggle}
        open={this.state.sortDropdownOpen}
        options={this.props.options}
        selectedOption={this.props.selectedOption}
      />
    );
  }
}

SortDropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onSortClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  selectedOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
};

SortDropdown.defaultProps = {
  className: 'SortDropdown',
  selectedOption: {
    label: '',
    value: 0,
  },
};

export default SortDropdown;
