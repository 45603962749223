// Vendors
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

// UI-Library
import FormTextField from 'ui-library/lib/components/forms/FormTextField';
import Link from 'ui-library/lib/components/general/Link';
import EllipsisLoader from 'ui-library/lib/components/general/EllipsisLoader';

// Store
import { generatePasswordAction, clearGeneratePasswordAction } from '../../../../store/actions/userActions';

// Styling
import './ResetPasswordField.css';

const fieldName = 'userPassword';

const ResetPasswordField = (props) => {
  const {
    errorMessage,
    onBlur,
    onChange,
    password,
    required,
    serverError,
    setFieldTouched,
    setFieldValue,
    showHelpHint,
    userId,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleClearPassword = () => dispatch(clearGeneratePasswordAction());
    return handleClearPassword;
  }, [dispatch]);

  const handleGeneratePassword = async () => {
    setIsLoading(true);

    try {
      const value = await dispatch(generatePasswordAction(userId));
      setIsLoading(false);
      setFieldTouched(fieldName);
      setFieldValue(fieldName, value);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const errorText = serverError || (errorMessage && intl.formatMessage({ id: errorMessage }));
  const helpHint = showHelpHint
    ? intl.formatMessage({ id: 'components.forms.reset-password-field.modal-content' })
    : undefined;

  return (
    <div className="ResetPasswordField">
      <FormTextField
        required={required}
        maskValue
        showReveal
        name="userPassword"
        onBlur={onBlur}
        onChange={(e) => {
          setFieldTouched('userPassword');
          onChange(e);
        }}
        value={password}
        labelText={intl.formatMessage({ id: 'components.forms.reset-password-field.label' })}
        labelHelpText={helpHint}
        errorMessage={errorText}
      />
      <div className="generate-link">
        {
          isLoading
            ? (
              <div data-testid="loader">
                <EllipsisLoader loading />
              </div>
            )
            : (
              <Link
                title={intl.formatMessage({
                  id: 'components.forms.reset-password-field.generate-password',
                })}
                type="block"
                onClick={handleGeneratePassword}
              />
            )
        }
      </div>
    </div>
  );
};

ResetPasswordField.propTypes = {
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  password: PropTypes.string,
  required: PropTypes.bool,
  serverError: PropTypes.string,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  showHelpHint: PropTypes.bool,
  userId: PropTypes.string,
};

ResetPasswordField.defaultProps = {
  errorMessage: '',
  password: '',
  required: false,
  serverError: '',
  showHelpHint: false,
  userId: '',
};

export default ResetPasswordField;
