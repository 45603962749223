/* eslint-disable import/first */
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';

import './index.css';
import 'ui-library/lib/css/ui-library.css';

ReactDOM.render(
  <App />,
  document.querySelector('#root'),
);
