import _ from 'lodash';

export const CANCEL = 'CANCEL';
export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export function createRequestTypes(base) {
  if (!base) {
    throw new Error('cannot create request type with base = \'\' or base = null');
  }
  return [CANCEL, REQUEST, SUCCESS, FAILURE].reduce((accumulator, type) => {
    // eslint-disable-next-line no-param-reassign
    accumulator[type] = `${base}_${type}`;
    return accumulator;
  }, {});
}

export function createAction(type, payload = {}) {
  return {
    type,
    ...payload,
  };
}

export function createActionType(type) {
  let result = type;

  if (!_.isEmpty(type)) {
    result = _.padEnd(type.toUpperCase(), (type.length + 1), '_');
  }

  return result;
}
