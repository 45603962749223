import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ title, subtitle, children }) => (
  <div className="page-header">
    <div className="page-header__text">
      <div className="page-header__title">
        {title}
      </div>
      {subtitle &&
        <div className="page-header__subtitle">
          {subtitle}
        </div>
      }
    </div>
    <div className="page-header__child">
      {children}
    </div>
  </div>
);

PageTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

PageTitle.defaultProps = {
  subtitle: undefined,
  children: undefined,
};

export default PageTitle;
