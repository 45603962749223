import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import PageTitle from '../PageTitle/PageTitle';

const Page = ({
  children,
  breadcrumb,
  title,
  className,
  pageHeaderChildren,
}) => {
  const BreadcrumbComponent = breadcrumb && (
    <Breadcrumb
      path={breadcrumb.path}
      label={breadcrumb.label}
    />
  );

  const PageHeaderChildrenComponent = pageHeaderChildren && (
    <div className="page-header__child">
      {pageHeaderChildren}
    </div>
  );

  const PageHeaderComponent = (title || PageHeaderChildrenComponent) && (
    <PageTitle
      title={title}
    >
      {PageHeaderChildrenComponent}
    </PageTitle>
  );

  return (
    <div className={`Page ${className}`}>
      {BreadcrumbComponent}
      {PageHeaderComponent}
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  pageHeaderChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  breadcrumb: PropTypes.shape({
    path: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};

Page.defaultProps = {
  children: undefined,
  pageHeaderChildren: undefined,
  breadcrumb: undefined,
  title: '',
  className: '',
};

export default Page;
