// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ResetPasswordTooltip from '../ResetPasswordTooltip/ResetPasswordTooltip';
import EnableUserToggle from '../EnableUserToggle/EnableUserToggle';

// Store
import { editUserAction } from '../../../../store/actions/userActions';

// Styling
import './AdminActions.css';

export const AdminActions = (props) => {
  const {
    userId,
    isLocked,
    isDisabled,
    showEnableToggle,
    showResetPasswordButton,
  } = props;

  return (
    <div className="AdminActions">
      {
        showResetPasswordButton
        && (
          <ResetPasswordTooltip
            disabled={isDisabled}
            userId={userId}
          />
        )
      }
      {
        showEnableToggle
        && (
          <EnableUserToggle
            userId={userId}
            isLocked={isLocked}
            isDisabled={isDisabled}
            editUser={props.editUserAction}
          />
        )
      }
    </div>
  );
};

AdminActions.propTypes = {
  userId: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  editUserAction: PropTypes.func.isRequired,
  showResetPasswordButton: PropTypes.bool,
  showEnableToggle: PropTypes.bool,
};

AdminActions.defaultProps = {
  showResetPasswordButton: true,
  showEnableToggle: true,
};

const connectedComponent = connect(
  null,
  {
    editUserAction,
  },
)(AdminActions);

export default connectedComponent;
