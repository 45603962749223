import React from 'react';
import PropTypes from 'prop-types';
import MembershipSummary from '../MembershipSummary/MembershipSummary';
import { getTitleOrPrimaryAttributeValue } from '../../../../utils/helpers';

const UserGroupsSummary = ({
  groups,
  groupsMeta,
  columnCount,
  columnSize,
}) => {
  // Get groups that have a title attribute to display (config error might result in empty title)
  const displayableGroups = groups
    .reduce((result, group) => {
      const groupMeta = groupsMeta[group.type].attributes;
      const titleAttribute = getTitleOrPrimaryAttributeValue(group.attributes, groupMeta);
      if (titleAttribute) {
        result.push({
          id: group.id,
          displayName: titleAttribute,
          type: group.type,
        });
      }
      return result;
    }, []);

  return (
    <MembershipSummary
      memberList={displayableGroups}
      columnCount={columnCount}
      columnSize={columnSize}
    />
  );
};

UserGroupsSummary.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      attributes: PropTypes.shape({}).isRequired,
    }),
  })).isRequired,
  groupsMeta: PropTypes.shape({}).isRequired,
  columnCount: PropTypes.number,
  columnSize: PropTypes.number,
};

UserGroupsSummary.defaultProps = {
  columnCount: 8,
  columnSize: 10,
};

export default UserGroupsSummary;
