class CustomError extends Error {
  constructor(errorObj = {}) {
    super(errorObj.message);
    this.name = 'DelegatedAdminApplicationError';
    this.custom = true;
    this.intlKey = errorObj.intlKey;
  }
}

export default CustomError;
