import * as types from '../actionTypes';

export function setModalAction(data) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_MODAL,
      data,
    });
  };
}

export default setModalAction;
