import React, { memo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Spinner from 'ui-library/lib/components/general/Spinner';

import ResetPasswordField from '../ResetPasswordField/ResetPasswordField';
import EditableFieldV2 from '../EditableFieldV2/EditableFieldV2';

const FormFields = (props) => {
  const {
    // Formik-related props
    arrayHelpers,
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    setStatus,
    status,
    touched,
    values,

    // Component props
    attr,
    formValues,
    meta,
    readOnlyAttributes,
    resetForm,
    selected,
    resourceTypes,
    serverErrors,
  } = props;

  const handleCustomValidationChange = (attribute, isValid) => {
    setStatus({
      ...status,
      customValidation: {
        ...status.customValidation,
        [attribute]: isValid,
      },
    });
  };

  const attrMeta = _.get(meta, `attributes.${attr}`);
  let serverError;

  if (touched[attr] && serverErrors[attr]) serverError = serverErrors[attr].message;

  if (attr === 'userPassword') {
    const errorMessage = (!_.isEmpty(errors)) ? errors[0] : '';
    const password = (values[0] === '') ? values[0] : values;
    return (
      <div className="input-row" key={attr}>
        <ResetPasswordField
          errorMessage={errorMessage}
          onBlur={handleBlur}
          onChange={handleChange}
          password={password}
          required={attrMeta.required}
          serverError={serverError}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          showHelpHint
        />
      </div>
    );
  }

  if (!attrMeta) {
    return <Spinner show key={attr} />;
  }

  return (
    <div className="input-row" key={attr}>
      <EditableFieldV2
        arrayHelpers={arrayHelpers}
        attr={attr}
        errors={errors}
        formValues={formValues}
        meta={attrMeta}
        onChange={handleChange}
        onValidationChange={handleCustomValidationChange}
        onValueChange={setFieldValue}
        readOnlyAttributes={readOnlyAttributes}
        resetForm={resetForm}
        resourceType={selected}
        resourceTypes={resourceTypes}
        serverError={serverError}
        setFieldTouched={setFieldTouched}
        values={values}
      />
    </div>
  );
};

FormFields.propTypes = {
  // Formik-related props
  arrayHelpers: PropTypes.oneOfType([PropTypes.shape()]).isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]),
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  status: PropTypes.shape({
    customValidation: PropTypes.objectOf(PropTypes.bool).isRequired,
  }).isRequired,
  touched: PropTypes.oneOfType([PropTypes.shape()]).isRequired,
  values: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.PropTypes.shape({}),
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.PropTypes.shape({}),
      PropTypes.string,
    ])),
  ]).isRequired,

  // Component props
  attr: PropTypes.string.isRequired,
  formValues: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    attributeCategories: PropTypes.arrayOf(PropTypes.string),
    attributes: PropTypes.shape({}).isRequired,
  }).isRequired,
  readOnlyAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
  resetForm: PropTypes.bool,
  selected: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  serverErrors: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

FormFields.defaultProps = {
  errors: {},
  resetForm: false,
};

/*
 * This function tells React if it should re-render FormFields
 * False = execute as normal
 * True = FormFields will not re-render and
 * the previous props will be used
 */

const isFieldTouched = (_prevProps, nextProps) => {
  const nextAttr = nextProps.attr;
  const nextTouched = nextProps.touched;
  if (_.isEmpty(nextTouched)) {
    return false;
  }
  return !nextTouched[nextAttr];
};

export default memo(FormFields, isFieldTouched);
