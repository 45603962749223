// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';

// UI-Library
import LabelValuePairs from 'ui-library/lib/components/layout/LabelValuePairs';

// Components
import ModalWithButton from '../../modals/ModalWithButton/ModalWithButton';
import RecentLoginSummary from '../RecentLoginSummary/RecentLoginSummary';

// Utils
import {
  createStatusIcon,
  createStatusLabel,
  createStatusMessage,
  getAccountInfoDataPair,
  getAccountInfoValue,
} from '../../../../utils/helpers';
import intlShape from '../../../../utils/intlPropType';

// Styling
import './AccountInfoSummary.css';

export const AccountInfoSummary = ({
  intl,
  name,
  passwordHistory,
}) => {
  const history = passwordHistory && passwordHistory[0];
  const dataPairs = {};
  const categories = {
    account: [
      'last-login-time',
      'recent-login-history',
    ],
    password: [
      'password-expiration-time',
      'password-changed-time',
      'password-policy-dn',
    ],
  };
  const baseLabel = 'components.summaries.password-history.status';
  const accountStatusLabel = intl.formatMessage({ id: `${baseLabel}.account-usability-errors.label` });
  if (history) {
    Object.entries(categories).forEach(([key, filters]) => {
      dataPairs[key] = [{
        title: intl.formatMessage(
          { id: 'components.summaries.password-history.title' },
          { key },
        ),
      }];

      if (key === 'account') {
        const errors = history['account-usability-errors'];
        if (!_.isEmpty(errors)) {
          errors.forEach((error, index) => {
            const status = createStatusLabel(error['type-name']);
            const icon = createStatusIcon(status);
            dataPairs[key].push(getAccountInfoDataPair({
              label: (index === 0) ? accountStatusLabel : '',
              icon,
              message: `${baseLabel}.account-usability-errors.value`,
              value: status,
            }));
            dataPairs[key].push({
              label: '',
              value: <div className="account-errors__message">{createStatusMessage(error.message)}</div>,
            });
          });
        } else {
          dataPairs[key].push(getAccountInfoDataPair({
            label: accountStatusLabel,
            icon: 'SUCCESS',
            message: `${baseLabel}.account-usability-errors.active`,
          }));
        }
      }

      filters.forEach((item) => {
        const label = intl.formatMessage({ id: `${baseLabel}.${item}.label` });
        let value = history[item];

        if (item === 'recent-login-history') {
          value = Object.values(value).some(arr => !_.isEmpty(arr));
        }

        if (value) {
          if (item === 'recent-login-history') {
            value = (
              <ModalWithButton
                buttonProps={{
                  inline: true,
                  label: {
                    id: 'components.modal.button.label',
                    label: 'View History',
                  },
                }}
                modalTitle={{
                  id: 'components.modal.title',
                  title: `Login History - ${name}`,
                }}
                maximize
              >
                <RecentLoginSummary
                  loginHistory={history[item]}
                />
              </ModalWithButton>
            );
          }

          dataPairs[key].push(getAccountInfoDataPair({
            label,
            icon: 'EMPTY',
            message: `${baseLabel}.${item}.value`,
            value: getAccountInfoValue(item, value),
          }));
        } else {
          dataPairs[key].push(getAccountInfoDataPair({
            label,
            icon: 'WARNING',
            message: `${baseLabel}.${item}.non`,
          }));
        }
      });
    });
  } else {
    dataPairs.none = [getAccountInfoDataPair({
      label: accountStatusLabel,
      icon: 'WARNING',
      message: `${baseLabel}.account-usability-errors.not-active`,
    })];
  }

  return (
    <div className="account-information">
      {
        Object.entries(dataPairs).map(([key, value]) => (
          <div key={key} className="account-information__data-pairs">
            <LabelValuePairs dataPairs={value} />
          </div>
        ))
      }
    </div>
  );
};

AccountInfoSummary.propTypes = {
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  passwordHistory: PropTypes.arrayOf(PropTypes.shape({})),
};

AccountInfoSummary.defaultProps = {
  passwordHistory: undefined,
};

export default injectIntl(AccountInfoSummary);
