import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import InlineMessage from 'ui-library/lib/components/general/InlineMessage';
import './Message.css';

export const Message = (props) => {
  const {
    bordered,
    className,
    message,
    type,
  } = props;
  return (
    <div className={className || 'message'}>
      <InlineMessage
        bordered={bordered}
        type={InlineMessage.MessageTypes[type]}
      >
        <FormattedMessage id={message} />
      </InlineMessage>
    </div>
  );
};

Message.propTypes = {
  bordered: PropTypes.bool,
  className: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string.isRequired,
};

Message.defaultProps = {
  bordered: false,
  className: undefined,
  message: '',
};

export default Message;
