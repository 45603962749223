import React from 'react';
import PropTypes from 'prop-types';
import LabelValuePairs from 'ui-library/lib/components/layout/LabelValuePairs';
import { getDataPair } from '../../../../utils/helpers';

export const AttributeCategoryItem = ({
  category,
  attributes,
}) => {
  const dataPairs = [
    {
      title: category.name,
    },
  ];

  category.attributes.forEach((attribute) => {
    dataPairs.push(getDataPair(attributes[attribute.name], attribute.value));
  });

  return (
    <div className="AttributeCategoryItem" key={category.name}>
      <LabelValuePairs dataPairs={dataPairs} />
    </div>
  );
};

AttributeCategoryItem.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    attributes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
      }),
    })).isRequired,
  }).isRequired,
};

export default AttributeCategoryItem;
