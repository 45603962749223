import React, { Children } from 'react';
import PropTypes from 'prop-types';
import UIColumnLayout from 'ui-library/lib/components/general/ColumnLayout';
import './ColumnLayout.css';

export const ColumnLayout = ({
  columns,
  gap,
  children,
}) => {
  const columnWrapper = {};
  const result = [];
  const columnItems = Children.toArray(children);

  // Generate placeholder columns
  for (let i = 0; i < columns; i += 1) {
    columnWrapper[`column${i}`] = [];
  }

  const itemsPerColumn = Math.ceil(columnItems.length / columns);

  // Place child elements into the given number of columns
  for (let i = 0; i < columnItems.length; i += 1) {
    const columnIndex = Math.floor(i / itemsPerColumn);
    columnWrapper[`column${columnIndex}`].push((
      <div
        key={i}
        style={{ marginBottom: `${gap}px` }}
        className="ColumnLayout__column-item"
      >
        {columnItems[i]}
      </div>
    ));
  }

  // Insert the columns into the final result
  for (let i = 0; i < columns; i += 1) {
    result.push((
      <UIColumnLayout.Column key={i}>
        {columnWrapper[`column${i}`]}
      </UIColumnLayout.Column>
    ));
  }

  return (
    <UIColumnLayout.Row>
      {result}
    </UIColumnLayout.Row>
  );
};

ColumnLayout.propTypes = {
  columns: PropTypes.number,
  gap: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ColumnLayout.defaultProps = {
  columns: 2,
  gap: 20,
};

export default ColumnLayout;
