import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { Redirect, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { SIGNOUT_ROUTE } from '../utils/routes';

export class AppTimer extends Component {
  constructor(props) {
    super(props);

    const { TIMEOUT_LENGTH_MINS } = window;
    const minutes =
      Number.isInteger(Math.abs(TIMEOUT_LENGTH_MINS)) && TIMEOUT_LENGTH_MINS !== 0
        ? Math.abs(TIMEOUT_LENGTH_MINS)
        : 30;

    // milliseconds * seconds * minutes
    this.timeout = 1000 * 60 * minutes;
    this.idleTimer = null;
    this.onIdle = this.onIdle.bind(this);

    this.state = {
      redirect: false,
    };
  }

  onIdle() {
    return this.setState({ redirect: this.props.authenticated }, () => this.idleTimer.reset());
  }

  render() {
    const { redirect } = this.state;
    const { location } = this.props;
    const fromLocation = _.get(location, 'state.from.pathname', '/');

    return (
      <IdleTimer
        ref={(ref) => { this.idleTimer = ref; }}
        element={document}
        onIdle={this.onIdle}
        timeout={this.timeout}
        startOnMount
      >
        {
          redirect &&
          fromLocation !== location.pathname &&
          <Redirect to={{
              pathname: SIGNOUT_ROUTE,
              state: {
                from: location,
              },
            }}
          />
        }
      </IdleTimer>
    );
  }
}

AppTimer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({}),
  }).isRequired,
  authenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticated: !!state.authentication.isAuthenticated,
  };
}

export default withRouter(connect(mapStateToProps)(AppTimer));
