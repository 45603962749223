import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import InlineMessage, { MessageTypes } from 'ui-library/lib/components/general/InlineMessage';
import './PermissionMessage.css';

export const PermissionMessage = (props) => {
  const {
    parentError,
    hasParentPermissions,
    hasGroupPermission,
    parentResourceType,
    groupResourceType,
  } = props;
  let message = 'actions.resource-types';

  if (parentError) {
    message += '.get-parent-failure';
  } else if (!hasGroupPermission) {
    message += '.no-group-permissions';
  } else if (!hasParentPermissions) {
    message += '.no-parent-permissions';
  }

  return (
    <div className="permission-message">
      <InlineMessage type={(parentError) ? MessageTypes.ERROR : MessageTypes.WARNING}>
        <FormattedMessage
          id={message}
          values={{
            parentResource: parentResourceType,
            groupResource: groupResourceType,
          }}
        />
      </InlineMessage>
    </div>
  );
};

PermissionMessage.propTypes = {
  parentError: PropTypes.string,
  hasParentPermissions: PropTypes.bool.isRequired,
  hasGroupPermission: PropTypes.bool.isRequired,
  parentResourceType: PropTypes.string,
  groupResourceType: PropTypes.string,
};

PermissionMessage.defaultProps = {
  parentError: undefined,
  parentResourceType: undefined,
  groupResourceType: undefined,
};

export default PermissionMessage;
