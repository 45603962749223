import _ from 'lodash';
import store from '../store';
import { MODEL_TYPES } from './modelTypes';

export const RESOURCE_TYPES = {
  USER: 'user',
  GROUP: 'group',
  GENERIC: 'generic',
};

export const PROP_RESOURCE_TYPES = Object.values(RESOURCE_TYPES);

export function getResourceTypes() {
  return _.get(store.getState(), 'resourceTypes.data', []);
}

export function getResourceById(resourceId) {
  const resourceTypes = getResourceTypes();
  return resourceTypes.find(resourceType => resourceType.id === resourceId);
}

export function getFirstResourceOfType(type) {
  const resourceTypes = getResourceTypes();
  return resourceTypes.find(resourceType => resourceType.attributes.resourceType === type);
}

export function getResourcesOfType(type) {
  const resourceTypes = getResourceTypes();
  return resourceTypes.filter(resourceType => resourceType.attributes.resourceType === type);
}

export function getResourceByURL(URL) {
  const resourceTypes = getResourceTypes();
  const modelTypesValues = Object.values(MODEL_TYPES);
  let modifiedURL = URL;
  modelTypesValues.forEach((value) => {
    if (URL.includes(value)) {
      modifiedURL = URL.replace(`/${value}`, '');
    }
  });
  return resourceTypes.filter(resourceType => modifiedURL.includes(resourceType.id))[0];
}

export function iconFor(type) {
  if (type === RESOURCE_TYPES.USER) {
    return 'user';
  } else if (type === RESOURCE_TYPES.GROUP) {
    return 'group';
  }
  return 'puzzle';
}
