import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Spinner from 'ui-library/lib/components/general/Spinner';
import Messages from 'ui-library/lib/components/general/messages/Messages';
import MessageActions from 'ui-library/lib/components/general/messages/MessagesActions';
import MessageContainer from '../../../../containers/MessageContainer/MessageContainer';
import Message from '../../message/Message/Message';
import ModelFormV2 from '../../forms/ModelFormV2/ModelFormV2';
import { getReferenceAction, editReferenceAction } from '../../../../store/actions/resourceTypes/resourceTypes';
import { setModalAction } from '../../../../store/actions/modal/modal';

export class EditModal extends Component {
  componentDidMount() {
    const { value: { id, type } } = this.props;
    this.props.getReferenceAction(id, type);
  }

  handleEdit = async (updatedAttrs) => {
    const {
      closeAlertModal,
      closeModal,
      resourceType,
      value: { id, type },
    } = this.props;
    closeAlertModal();
    try {
      const res = await this.props.editReferenceAction(
        id, updatedAttrs, false, { type, resourceType },
      );

      if (res.status === 200) {
        this.props.setModalAction({ dirty: false });
        closeModal();
        this.props.handleSave(res.data);
      }
    } catch (error) {
      // Errors handled by actions
    }
  }

  render() {
    const {
      data,
      error,
      hasAlertSave,
      isModalOpen,
      isFetching,
      messages,
      meta,
      resourceTypes,
      selected,
    } = this.props;

    return (
      <>
        {
          !_.isEmpty(messages) &&
          <MessageContainer
            messages={messages}
            onRemoveMessage={MessageActions.removeAt}
            defaultMessageLayout={Messages.Layouts.BANNER}
          />
        }
        <Spinner show={isFetching}>
          {
            error
            ?
              <Message
                bordered
                message={error}
                type="ERROR"
              />
            :
              <ModelFormV2
                hasAlertSave={hasAlertSave}
                isModalOpen={isModalOpen}
                meta={meta}
                model={data}
                onSave={this.handleEdit}
                selected={selected}
                resourceTypes={resourceTypes}
              />
          }
        </Spinner>
      </>
    );
  }
}

EditModal.propTypes = {
  closeAlertModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
  editReferenceAction: PropTypes.func.isRequired,
  error: PropTypes.string,
  getReferenceAction: PropTypes.func.isRequired,
  hasAlertSave: PropTypes.bool.isRequired,
  handleSave: PropTypes.func,
  isFetching: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(PropTypes).isRequired,
  meta: PropTypes.shape({}),
  resourceType: PropTypes.string.isRequired,
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.shape({}).isRequired,
  setModalAction: PropTypes.func.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

EditModal.defaultProps = {
  data: undefined,
  error: undefined,
  handleSave: () => {},
  meta: {},
};

function mapStateToProps(state) {
  const { messages } = state.messageContainer;
  const {
    data,
    error,
    isFetching,
    meta,
  } = { ...state.resourceTypes.edit };

  return {
    data,
    error,
    isFetching,
    messages,
    meta,
  };
}

export default connect(
  mapStateToProps,
  {
    editReferenceAction,
    getReferenceAction,
    setModalAction,
  },
)(EditModal);
