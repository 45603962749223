import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const NotFoundPage = (props) => {
  const defaultProps = {
    path: '/search/users',
    message: 'page.not-found.action-sentence',
  };
  const { state = defaultProps } = props.location;
  const { path, message } = state;

  return (
    <div className="NotFoundPage">
      <h1>
        <FormattedMessage id="page.not-found.header" />
      </h1>
      <p>
        <FormattedMessage id="page.not-found.description" />
      </p>
      <p>
        <Link to={path}>
          <FormattedMessage id="page.not-found.action-link-text" />
        </Link>

        <FormattedMessage id={message} />
      </p>
    </div>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      path: PropTypes.string,
      message: PropTypes.string,
    }),
  }).isRequired,
};

export default NotFoundPage;
