import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import FormTextField from 'ui-library/lib/components/forms/form-text-field';
import ReportFilters from 'ui-library/lib/components/layout/ReportFilters';
import Button, { buttonTypes } from 'ui-library/lib/components/buttons/Button';
import DropDownMultiSelectionList from '../../shared/list-inputs/DropDownMultiSelectionList/DropDownMultiSelectionList';
import DropDownList from '../../shared/list-inputs/DropDownList/DropDownList';
import ReportFiltersEntry from '../ReportFiltersEntry/ReportFiltersEntry';
import intlShape from '../../../utils/intlPropType';
import { getReportResultsAction } from '../../../store/actions/report/report';
import {
  setDropDownMultiOptions,
  setDropDownOptions,
} from '../../../utils/helpers';
import { FIELD_TYPES } from '../../../utils/formHelpers';
import './ReportFilters.css';

export class ReportingFilters extends Component {
  state = {
    selectedFields: {
      error: undefined,
      items: [],
      selectedAttrs: [],
    },
    filterType: {
      noneOption: undefined,
      selected: undefined,
      value: '',
    },
  };

  componentDidUpdate(prevProps) {
    const prevPropsAttributes = prevProps.report.filters.attributes;
    const { attributes } = this.props.report.filters;

    if (!_.isEqual(prevPropsAttributes, attributes)) {
      this.setDefaults();
    }
  }

  setDefaults = () => {
    const { intl } = this.props;
    const noneOption = {
      label: intl.formatMessage({ id: 'components.drop-down.labels.default' }),
    };
    this.setState({
      selectedFields: {
        error: undefined,
        items: [],
        selectedAttrs: [],
      },
      filterType: {
        noneOption,
        selected: noneOption,
        value: '',
      },
    }, () => this.selectAllSelectedFieldsAttrs());
  }

  getReportResults = () => {
    const { selectedOptions } = this.props;
    const { selectedFields, filterType } = this.state;
    const { selected } = filterType;
    const { selectedAttrs } = selectedFields;

    this.props.getReportResultsAction({
      clearReportResults: true,
      filterType: (selected && selected.id) ? filterType : undefined,
      selectedOptions,
      selectedFieldsAttrs: selectedAttrs.map(item => item.attr),
    });
  }

  selectAllSelectedFieldsAttrs = () => {
    const { attributes } = this.props.report.filters;

    this.setState({
      selectedFields: {
        ...this.state.selectedFields,
        items: setDropDownMultiOptions(attributes),
        selectedAttrs: setDropDownMultiOptions(attributes),
      },
    });
  }

  handleSelectedFieldsDropDownChange = (selectedItemIds) => {
    const { attributes } = this.props.report.filters;
    const items = setDropDownMultiOptions(attributes);
    const selectedAttrs = items
      .filter(item => selectedItemIds.indexOf(item.id) >= 0);
    const error = (_.isEmpty(selectedItemIds) && !_.isEmpty(attributes)) ? 'components.drop-down-multi.errors.empty' : undefined;
    this.setState({
      selectedFields: {
        ...this.state.selectedFields,
        error,
        selectedAttrs,
      },
    });
  }

  handleFilterTypeDropDownChange = (selected) => {
    const { dataType } = selected;
    const value = (dataType === FIELD_TYPES.BOOLEAN) ? false : '';
    this.setState({
      filterType: {
        ...this.state.filterType,
        selected,
        value,
      },
    });
  }

  handleEntryChange = (value) => {
    this.setState({
      filterType: {
        ...this.state.filterType,
        value,
      },
    });
  }

  render() {
    const {
      intl,
      report,
    } = this.props;
    const {
      filterTypeAttrs,
    } = this.props.report.filters;
    const {
      selectedFields,
      filterType,
    } = this.state;
    const error = !_.isUndefined(selectedFields.error);

    return (
      <ReportFilters
        disabled={report.isFetching || error}
        buttonLabel={intl.formatMessage({ id: 'common.run' })}
        onClick={this.getReportResults}
        filters={[
          [
            <DropDownMultiSelectionList
              error={selectedFields.error}
              label="report.filter.dropdown-multi.label"
              items={selectedFields.items}
              handleDropDownMultiChange={this.handleSelectedFieldsDropDownChange}
            />,
          ],
          [
            <DropDownList
              disabled={error}
              handleOnChange={this.handleFilterTypeDropDownChange}
              label={{
                    id: 'report.filter.dropdown.label',
                    displayName: '',
                  }}
              noneOption={filterType.noneOption}
              options={setDropDownOptions(filterTypeAttrs)}
              selectedOption={filterType.selected}
            />,
            (filterType.selected && filterType.selected.dataType)
              ?
                <div className="report__filters-entries">
                  <ReportFiltersEntry
                    disabled={error}
                    handleEntryChange={this.handleEntryChange}
                    selectedOptionEntry={filterType.selected}
                    filterType={filterType}
                  />
                </div>
              :
                <div className="report__filters-entries">
                  <FormTextField disabled />
                </div>,
            /*
             * ReportFilters does not provide a reset button.
             * Reset button always needs to be the last child.
             */
            <Button
              disabled={report.isFetching}
              label={intl.formatMessage({ id: 'common.reset' })}
              type={buttonTypes.LINK}
              onClick={async () => {
                await this.setDefaults();
                this.getReportResults();
              }}
            />,
          ],
        ]}
      />
    );
  }
}

ReportingFilters.propTypes = {
  getReportResultsAction: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  report: PropTypes.shape({
    isFetching: PropTypes.bool,
    filters: PropTypes.shape({
      attributes: PropTypes.shape({}),
      selectedFieldsAttrs: PropTypes.arrayOf(PropTypes.string),
      filterTypeAttrs: PropTypes.shape({}),
    }),
    results: PropTypes.shape({
      header: PropTypes.arrayOf(PropTypes.string),
      rows: PropTypes.arrayOf(PropTypes.arrayOf),
    }),
  }).isRequired,
  selectedOptions: PropTypes.shape({}),
};

ReportingFilters.defaultProps = {
  selectedOptions: {},
};

export default injectIntl(connect(
  null,
  {
    getReportResultsAction,
  },
)(ReportingFilters));
