import _ from 'lodash';
import { RESOURCE_TYPES } from './resourceTypes';
import { MODEL_TYPES } from './modelTypes';

export const BASE_ROUTE = '/';
export const CALLBACK_ROUTE = '/callback';
export const SIGNOUT_ROUTE = '/signout';
export const NOT_FOUND_ROUTE = '/not-found';
export const PROFILE_ROUTE = '/:model/:type/:id';
export const USERS_AND_GROUPS_SEARCH_ROUTE = `/search/:model(${MODEL_TYPES.USERS}|${MODEL_TYPES.GROUPS})`;
export const GROUPS_SEARCH_ROUTE = `/search/${MODEL_TYPES.GROUPS}`;
export const GROUPS_REPORT = `/report/${MODEL_TYPES.GROUPS}`;
export const GROUPS_PROFILE_ROUTE = `/profile/${MODEL_TYPES.GROUPS}/:type/:id`;
export const USERS_SEARCH_ROUTE = `/search/${MODEL_TYPES.USERS}`;
export const USERS_REPORT = `/report/${MODEL_TYPES.USERS}`;
export const USERS_PROFILE_ROUTE = `/profile/${MODEL_TYPES.USERS}/:type/:id`;
export const GENERICS_SEARCH_ROUTE = `/search/:model(${MODEL_TYPES.GENERICS})/:type`;
export const GENERICS_REPORT = `/report/:model(${MODEL_TYPES.GENERICS})/:type`;
export const GENERICS_PROFILE_ROUTE = `/profile/${MODEL_TYPES.GENERICS}/:type/:id`;
export const USERS_AND_GROUPS_REPORT_ROUTE = `/report/:model(${MODEL_TYPES.USERS}|${MODEL_TYPES.GROUPS})`;

const userRoutes = [USERS_SEARCH_ROUTE, USERS_REPORT];
const groupRoutes = [GROUPS_SEARCH_ROUTE, GROUPS_REPORT];
const genericRoutes = [GENERICS_SEARCH_ROUTE, GENERICS_REPORT];

export function generatedRoute(modelType, options = {}) {
  const id = _.get(options, 'id', undefined);

  return `/${modelType}/${id}`;
}

export function isRouteOfType(path, type) {
  if (type === RESOURCE_TYPES.USER) {
    return userRoutes.includes(path);
  } else if (type === RESOURCE_TYPES.GROUP) {
    return groupRoutes.includes(path);
  } else if (type === RESOURCE_TYPES.GENERIC) {
    return genericRoutes.includes(path);
  }

  return false;
}
