// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { generatePath } from 'react-router';

// UI-Library
import ExpandableRow from 'ui-library/lib/components/rows/expandable-row';
import Button, { buttonTypes } from 'ui-library/lib/components/buttons/Button';

// Components
import AdminActions from '../../shared/admin-actions/AdminActions/AdminActions';
import AccordionProfile from '../AccordionProfile/AccordionProfile';

// Store
import { getSearchResults } from '../../../store/actions/search/search';
import { deleteUserAction } from '../../../store/actions/userActions';
import { deleteGroupAction } from '../../../store/actions/groups/groups';
import { deleteGenericAction } from '../../../store/actions/generics/index';

// Utils
import { MODEL_TYPES, PROP_MODEL_TYPES } from '../../../utils/modelTypes';
import { displayPrimaryAttr } from '../../../utils/helpers';
import permissions, { PERMISSIONS } from '../../../utils/permissions/permissions';
import { getResourceById, RESOURCE_TYPES } from '../../../utils/resourceTypes';
import * as routes from '../../../utils/routes';
import intlShape from '../../../utils/intlPropType';

// Styling
import './SearchResultItem.css';

export class SearchResultItem extends Component {
  constructor(props) {
    super(props);

    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleToggle = this.handleToggle.bind(this);

    this.state = {
      goToEdit: false,
      isConfirmDeleteOpen: false,
      isDeleting: false,
      isExpanded: props.isExpanded,
    };
  }

  searchAttributesString() {
    const { result, searchAttributesObj } = this.props;
    let string = '';

    if (searchAttributesObj) {
      Object.keys(searchAttributesObj).forEach((attr, index) => {
        if (result.attributes[attr]) {
          const [value] = result.attributes[attr];

          if (index > 0) {
            string += ` | ${value}`;
          } else {
            string += `${value}`;
          }
        }
      });
    }

    return string;
  }

  rowProperties() {
    const {
      result,
      primarySearchAttribute,
      meta,
      modelType,
    } = this.props;

    const editPermissions = permissions.getEditPermissions(result.type);
    const title = displayPrimaryAttr(result.attributes, primarySearchAttribute, meta);
    const subtitle =
      modelType === MODEL_TYPES.USERS
        ? this.searchAttributesString()
        : undefined;

    const rowAccessories = this.rowAccessories(editPermissions);
    const showEdit = editPermissions.hasUpdatePermission
      || editPermissions.hasUpdateProfilePermission
      || (editPermissions.hasManageGroupPermission && _.get(getResourceById(result.type), 'attributes.resourceType') === RESOURCE_TYPES.GROUP);

    return {
      title,
      subtitle,
      rowAccessories,
      showEdit,
    };
  }

  displayStatus() {
    const { attributes } = this.props.result;
    const isLocked = _.get(attributes, '[dadmin-account-locked][0]', false);
    const isDisabled = _.get(attributes, '[ds-pwp-account-disabled][0]', false);

    if (isLocked) {
      return (
        <span className="user-status_locked">
          <FormattedMessage id="user.status.locked" className="test" />
        </span>
      );
    } else if (isDisabled) {
      return (
        <span className="user-status_disabled">
          <FormattedMessage id="user.status.disabled" />
        </span>
      );
    }

    return null;
  }

  rowAccessories(editPermissions) {
    const {
      meta,
      modelType,
      result: {
        id,
        attributes,
      },
    } = this.props;

    const {
      hasUpdatePermission,
      hasResetPasswordPermission,
      hasUpdateProfilePermission,
    } = editPermissions;

    const { isExpanded } = this.state;
    const showEnableToggle = Object.prototype.hasOwnProperty.call(meta.attributes, 'ds-pwp-account-disabled')
      && (hasUpdatePermission || hasUpdateProfilePermission);
    const showResetPasswordButton = isExpanded &&
      (hasUpdatePermission || hasResetPasswordPermission);
    const showAdminActions = hasUpdatePermission ||
      hasResetPasswordPermission || hasUpdateProfilePermission;

    if (modelType === MODEL_TYPES.USERS && showAdminActions) {
      return (
        <span className="row-accessories-content">
          <AdminActions
            userId={id}
            isLocked={_.get(attributes, '[dadmin-account-locked][0]', false)}
            isDisabled={_.get(attributes, '[ds-pwp-account-disabled][0]', false)}
            showResetPasswordButton={showResetPasswordButton}
            showEnableToggle={showEnableToggle}
          />
        </span>
      );
    }
    return undefined;
  }

  handleEditButtonClick() {
    this.setState({ goToEdit: true });
  }

  handleToggle() {
    this.setState({ isExpanded: !this.state.isExpanded });
  }

  render() {
    const {
      handleDelete,
      intl,
      meta,
      modelType,
      result,
      referencePermission,
      search: { queryString },
    } = this.props;
    const { type, id } = result;
    const {
      goToEdit,
      isConfirmDeleteOpen,
      isDeleting,
      isExpanded,
    } = this.state;
    const canDelete = permissions.hasPermission(PERMISSIONS.DELETE, type);

    if (goToEdit) {
      let path;

      if (modelType === MODEL_TYPES.USERS) {
        path = routes.USERS_PROFILE_ROUTE;
      } else if (modelType === MODEL_TYPES.GROUPS) {
        path = routes.GROUPS_PROFILE_ROUTE;
      } else if (modelType === MODEL_TYPES.GENERICS) {
        path = routes.GENERICS_PROFILE_ROUTE;
      }

      return (
        <Redirect
          to={{
            pathname: generatePath(path, { type, id }),
          }}
        />
      );
    }

    return (
      <ExpandableRow
        flags={['expandable-row-class', 'use-portal', 'p-stateful']}
        onEditButtonClick={this.handleEditButtonClick}
        onDelete={() => {
          this.setState({ isConfirmDeleteOpen: true });
        }}
        onDeleteCancelClick={() => {
          this.setState({ isConfirmDeleteOpen: false });
        }}
        onDeleteConfirmClick={async () => {
          const deleteAction = {
            [MODEL_TYPES.USERS]: this.props.deleteUserAction,
            [MODEL_TYPES.GROUPS]: this.props.deleteGroupAction,
            [MODEL_TYPES.GENERICS]: this.props.deleteGenericAction,
          };
          this.setState({ isDeleting: true });

          const res = await deleteAction[modelType](id, type);

          if (res === 'success') {
            this.setState({ isConfirmDeleteOpen: false, isDeleting: false });
            this.props.getSearchResults(queryString, { skipMessage: true });
            handleDelete();
          } else {
            this.setState({ isDeleting: false });
          }
        }}
        showDeleteConfirm={isConfirmDeleteOpen}
        confirmDeleteTitle={intl.formatMessage({ id: 'common.delete' })}
        confirmDeleteContent={({
          onCancel,
          onConfirm,
          confirmLabel,
          cancelLabel,
        }) => (
          <div className="delete-tooltip">
            <p><FormattedMessage id="actions.delete.confirm" /></p>
            <div className="delete-tooltip__buttons">
              <Button
                label={cancelLabel}
                type={buttonTypes.CANCEL}
                onClick={onCancel}
              />
              <Button
                label={confirmLabel}
                type={buttonTypes.DANGER}
                onClick={onConfirm}
                loading={isDeleting}
                disabled={isDeleting}
              />
            </div>
          </div>
        )}

        showDelete={canDelete}
        expanded={isExpanded}
        onToggle={this.handleToggle}
        {...this.rowProperties()}
      >
        <AccordionProfile
          modelType={modelType}
          item={result}
          meta={meta}
          referencePermission={referencePermission}
        />
      </ExpandableRow>
    );
  }
}

SearchResultItem.propTypes = {
  deleteGenericAction: PropTypes.func.isRequired,
  deleteGroupAction: PropTypes.func.isRequired,
  deleteUserAction: PropTypes.func.isRequired,
  getSearchResults: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isExpanded: PropTypes.bool,
  meta: PropTypes.shape({
    attributes: PropTypes.shape({}).isRequired,
  }).isRequired,
  modelType: PropTypes.oneOf(PROP_MODEL_TYPES).isRequired,
  primarySearchAttribute: PropTypes.string,
  result: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      cn: PropTypes.arrayOf(PropTypes.string),
      mail: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    relationships: PropTypes.shape({
      groups: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
  search: PropTypes.shape({
    queryString: PropTypes.string,
  }).isRequired,
  searchAttributesObj: PropTypes.shape({}),
  referencePermission: PropTypes.bool.isRequired,
};

SearchResultItem.defaultProps = {
  isExpanded: false,
  primarySearchAttribute: undefined,
  searchAttributesObj: {},
};

function mapStateToProps(state) {
  const { queryString } = state.search;

  return {
    search: {
      queryString,
    },
  };
}

export default injectIntl(connect(
  mapStateToProps,
  {
    deleteGenericAction,
    deleteGroupAction,
    deleteUserAction,
    getSearchResults,
  },
)(SearchResultItem));
