import { createRequestTypes } from './index';

// Auth Actions
export const AUTHENTICATION = createRequestTypes('AUTHENTICATION');
export const CALLBACK_ERROR = 'CALLBACK_ERROR';
export const UNAUTHENTICATE_USER = 'UNAUTHENTICATE_USER';

// Resource Types Actions
export const RESOURCE_TYPES = createRequestTypes('RESOURCE_TYPES');
export const PARENT_RESOURCES = createRequestTypes('PARENT_RESOURCE_TYPE');
export const CLEAR_PARENT_RESOURCES = 'CLEAR_PARENT_RESOURCES';
export const SELECT_RESOURCE_TYPE = 'SELECT_RESOURCE_TYPE';
export const SELECT_SECONDARY_RESOURCE_TYPE = 'SELECT_SECONDARY_RESOURCE_TYPE';
export const RESOURCE_TYPES_FILTER = 'RESOURCE_TYPES_FILTER';
export const GROUPS_RESOURCES = createRequestTypes('GROUPS_RESOURCE_TYPE');
export const CREATE_SELECT_RESOURCE_TYPE = 'CREATE_SELECT_RESOURCE_TYPE';
export const CREATE_SELECT_SECONDARY_RESOURCE_TYPE = 'CREATE_SELECT_SECONDARY_RESOURCE_TYPE';
export const CREATE_GROUPS_RESOURCES = createRequestTypes('CREATE_GROUPS_RESOURCE_TYPE');
export const CREATE_PARENT_RESOURCES = createRequestTypes('CREATE_PARENT_RESOURCE_TYPE');
export const CREATE_SELECT_GROUP_RESOURCE_TYPE = 'CREATE_SELECT_GROUP_RESOURCE_TYPE';
export const CLEAR_CREATE_PARENT_RESOURCES = 'CLEAR_CREATE_PARENT_RESOURCES';
export const EDIT_REFERENCE = createRequestTypes('EDIT_REFERENCE');
export const EDIT_REFERENCE_UPDATE = createRequestTypes('EDIT_REFERENCE_UPDATE');

// Search Actions
export const SEARCH_RESULTS = createRequestTypes('SEARCH_RESULTS');
export const REFERENCE_SEARCH_RESULTS = createRequestTypes('REFERENCE_SEARCH_RESULTS');
export const CLEAR_SEARCH_RESULTS = 'clear_search_results';
export const SEARCH_GROUP_MEMBERS_ERROR = 'search_group_members_error';
export const SEARCH_GROUP_MEMBERS_SUCCESS = 'search_group_members_success';
export const SORT_SEARCH_RESULTS = 'sort_search_results';

// User Actions
export const GET_USER = 'GET_USER';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const CREATE_USER_TRIGGERED = 'create_user_triggered';
export const CREATE_USER_SUCCESS = 'create_user_success';
export const CREATE_USER_ERROR = 'create_user_error';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_UPDATED_ERROR = 'USER_UPDATED_ERROR';
export const GENERATE_PASSWORD = createRequestTypes('GENERATE_PASSWORD');
export const CLEAR_GENERATE_PASSWORD = 'CLEAR_GENERATE_PASSWORD';
export const GET_USER_GROUPS = 'get_user_groups';
export const GET_USER_GROUPS_ERROR = 'get_user_groups_error';
export const CLEAR_NEW_USER = 'clear_new_user';
export const USER_FORM_IS_DIRTY = 'user_form_is_dirty';
export const USER_FORM_IS_CLEAN = 'user_form_is_clean';

// Group Actions
export const GET_GROUP = 'get_group';
export const GET_GROUP_ERROR = 'get_group_error';
export const GET_GROUP_MEMBERS_SUCCESS = 'get_group_members_success';
export const GET_GROUP_MEMBERS_ERROR = 'get_group_members_error';
export const ADD_GROUP_MEMBER = 'add_group_member';
export const ADD_GROUP_MEMBER_ERROR = 'add_group_member_error';
export const REMOVE_GROUP_MEMBER = 'remove_group_member';
export const REMOVE_GROUP_MEMBER_ERROR = 'remove_group_member_error';
export const CREATE_GROUP = createRequestTypes('CREATE_GROUP');
export const CLEAR_NEW_GROUP = 'CLEAR_NEW_GROUP';
export const GROUP_FORM_IS_DIRTY = 'GROUP_FORM_IS_DIRTY';
export const GROUP_FORM_IS_CLEAN = 'GROUP_FORM_IS_CLEAN';
export const UPDATE_GROUP = createRequestTypes('UPDATE_GROUP');

// Generic Resource Actions
export const CREATE_GENERIC = createRequestTypes('CREATE_GENERIC');
export const GET_GENERIC = createRequestTypes('GET_GENERIC');
export const UPDATE_GENERIC = createRequestTypes('UPDATE_GENERIC');

// Model Form Actions
export const MODEL_FORM_CLEAR = 'MODEL_FORM_CLEAR';
export const MODEL_FORM_CLEAR_PASSWORD_ERROR = 'MODEL_FORM_CLEAR_PASSWORD_ERROR';
export const MODEL_FORM_SET = 'MODEL_FORM_SET';
export const MODEL_FORM_ERROR = 'MODEL_FORM_ERROR';

// General Actions
export const OIDC_TOKEN_ERROR = 'oidc_token_error';
export const OIDC_TOKEN_FOUND = 'oidc_token_found';
export const OIDC_CONNECTION_ERROR = 'oidc_connection_error';

// Report Preview Actions
export const REPORT_RESULTS = createRequestTypes('REPORT_RESULTS');
export const REPORT_DOWNLOAD = createRequestTypes('REPORT_DOWNLOAD');
export const REPORT_UPLOAD = createRequestTypes('REPORT_UPLOAD');
export const CLEAR_REPORT_RESULTS = 'CLEAR_REPORT_RESULTS';
export const SET_REPORT_FILTERS = 'SET_REPORT_FILTERS';
export const CLEAR_REPORT = 'CLEAR_REPORT';

// Modal Actions
export const SET_MODAL = 'SET_MODAL';

// Linking Actions
export const LINK_OBJECTS = createRequestTypes('LINK_OBJECTS');
export const UNLINK_OBJECTS = createRequestTypes('UNLINK_OBJECTS');
