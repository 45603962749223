import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'ui-library/lib/components/general/Spinner';

import { getGroupAction, editGroupAction } from '../../../store/actions/groups/groups';
import { searchGroupMembersAction, clearSearchResultsAction } from '../../../store/actions/search/search';
import { unlinkObjects, linkObjects } from '../../../store/actions/correlatedResources/correlatedResources';
import { getTitleOrPrimaryAttributeValue } from '../../../utils/helpers';
import { MODEL_TYPES } from '../../../utils/modelTypes';
import * as routes from '../../../utils/routes';
import EditModelPage from '../../shared/pages/EditModelPage/EditModelPage';
import permissions, { PERMISSIONS } from '../../../utils/permissions/permissions';

import './GroupProfilePage.css';

export class GroupProfilePage extends Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.handleUnlinkObjects = this.handleUnlinkObjects.bind(this);
    this.handlelinkObjects = this.handlelinkObjects.bind(this);
    this.getGroup = this.getGroup.bind(this);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getGroup();
  }

  componentWillUnmount() {
    this.handleClearSearch();
  }

  async getGroup() {
    const { id, type } = this.props.match.params;

    try {
      await this.props.getGroupAction(id, type);
      this.setState({ isLoading: false });
    } catch (e) {
      // Errors caught in actions
    }
  }

  handleSearch(queryString = '', resourceType = '', orgs = '', groupResourceType = false) {
    const { group } = this.props;
    this.props.searchGroupMembersAction(
      queryString, group.id, resourceType, orgs, groupResourceType,
    );
  }

  handleClearSearch() {
    this.props.clearSearchResultsAction({ subquery: true });
  }

  async handleUnlinkObjects(correlationCfg, secondaryObject) {
    const { group } = this.props;
    await this.props.unlinkObjects(correlationCfg, group, secondaryObject);
  }

  async handlelinkObjects(correlationCfg, docToBeLinked) {
    const { group } = this.props;
    await this.props.linkObjects(correlationCfg, group, docToBeLinked);
  }

  render() {
    const {
      error,
      group,
      groupMeta,
      groupMembers,
    } = this.props;
    const { isLoading } = this.state;
    let groupTitle;
    let canUpdate;
    let canManage;

    if (group) {
      groupTitle = getTitleOrPrimaryAttributeValue(
        group.attributes,
        groupMeta,
      );
      canUpdate = permissions.hasPermission(PERMISSIONS.UPDATE, group.type) ||
        permissions.hasPermission(PERMISSIONS.UPDATE_PROFILE, group.type);
      canManage = permissions.hasPermission(PERMISSIONS.MANAGE_GROUP_MEMBERSHIP, group.type);
    }

    return (
      <Spinner show={isLoading}>
        <EditModelPage
          profilePageModel={MODEL_TYPES.GROUPS}
          model={group}
          modelMeta={groupMeta}
          modelDisplayAttributeValue={groupTitle}
          membershipResults={groupMembers}
          editModelAction={this.props.editGroupAction}
          onSearch={this.handleSearch}
          onClearSearch={this.handleClearSearch}
          error={error}
          breadcrumbPath={routes.GROUPS_SEARCH_ROUTE}
          canUpdateModel={canUpdate}
          canManageMembershipForModel={canManage}
          showAttributesOnMembershipManagement={(!canUpdate && canManage)}
          onUnlink={this.handleUnlinkObjects}
          onLink={this.handlelinkObjects}
          getModelType={this.getGroup}
        />
      </Spinner>
    );
  }
}

GroupProfilePage.propTypes = {
  getGroupAction: PropTypes.func.isRequired,
  editGroupAction: PropTypes.func.isRequired,
  searchGroupMembersAction: PropTypes.func.isRequired,
  clearSearchResultsAction: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({}).isRequired,
    included: PropTypes.arrayOf(PropTypes.shape({})),
    relationships: PropTypes.shape({
      members: PropTypes.shape({
        data: PropTypes.shape({
          directMembers: PropTypes.arrayOf(PropTypes.shape({})),
          indirectMembers: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    }),
    type: PropTypes.string,
  }),
  groupMeta: PropTypes.shape({
    attributes: PropTypes.shape({}).isRequired,
    included: PropTypes.shape({}),
  }),
  groupMembers: PropTypes.shape({
    groupId: PropTypes.string.isRequired,
    queryString: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    nonMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    meta: PropTypes.shape({
      directMembers: PropTypes.shape({}),
      indirectMembers: PropTypes.shape({}),
      nonMembers: PropTypes.shape({}),
    }).isRequired,
    error: PropTypes.string,
  }),
  error: PropTypes.string,
  unlinkObjects: PropTypes.func.isRequired,
  linkObjects: PropTypes.func.isRequired,
};

GroupProfilePage.defaultProps = {
  group: undefined,
  groupMeta: undefined,
  groupMembers: undefined,
  error: undefined,
};

function mapStateToProps(state) {
  const {
    groupMembers,
  } = state.search;
  const {
    error,
    group,
    included,
    meta,
  } = state.group;
  const completeGroup = group && {
    ...group.data,
    included,
  };

  return {
    error,
    groupMembers,
    group: completeGroup,
    groupMeta: meta,
  };
}

export default connect(
  mapStateToProps,
  {
    getGroupAction,
    editGroupAction,
    searchGroupMembersAction,
    clearSearchResultsAction,
    unlinkObjects,
    linkObjects,
  },
)(GroupProfilePage);
