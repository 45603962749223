import _ from 'lodash';
import { MODEL_TYPES } from '../../utils/modelTypes';
import { sortData, getPrimarySearchAttributeKey, getTitleAttributeKey } from '../../utils/helpers';
import * as types from '../actions/actionTypes';
import { createActionType, REQUEST, SUCCESS, FAILURE } from '../actions';

const defaultState = {
  dataType: undefined,
  queryString: undefined,
  results: [],
  isFetching: false,
  error: undefined,
  groupMembers: undefined, // used for member searches on the group edit page
  reference: {
    dataType: undefined,
    error: undefined,
    isFetching: false,
    queryString: undefined,
    results: [],
  },
};

export default function (state = defaultState, action) {
  const REFERENCE = action.REFERENCE || '';
  switch (action.type) {
    case types[`${createActionType(REFERENCE)}SEARCH_RESULTS`][REQUEST]: {
      let result = {
        ...state,
        isFetching: true,
      };

      if (REFERENCE !== '') {
        result = {
          ...state,
          reference: {
            ...state.reference,
            isFetching: true,
          },
        };
      }

      return result;
    }
    case types[`${createActionType(REFERENCE)}SEARCH_RESULTS`][SUCCESS]: {
      if (action.data.subquery && action.data.dataType === MODEL_TYPES.GROUPS) {
        return {
          ...state,
          isFetching: false,
        };
      }

      let result = {
        ...state,
        dataType: action.data.dataType,
        queryString: action.data.queryString,
        results: action.data.results,
        included: action.data.included,
        meta: action.data.meta,
        primarySearchAttribute: getPrimarySearchAttributeKey(action.data.meta),
        groupTitleAttribute: getTitleAttributeKey(action.data.meta),
        error: undefined,
        isFetching: false,
      };

      if (REFERENCE !== '') {
        result = {
          ...state,
          reference: {
            ...state.reference,
            dataType: action.data.dataType,
            queryString: action.data.queryString,
            results: action.data.results,
            included: action.data.included,
            meta: action.data.meta,
            primarySearchAttribute: getPrimarySearchAttributeKey(action.data.meta),
            groupTitleAttribute: getTitleAttributeKey(action.data.meta),
            error: undefined,
            isFetching: false,
          },
        };
      }

      return result;
    }
    case types[`${createActionType(REFERENCE)}SEARCH_RESULTS`][FAILURE]: {
      let result = {
        ...state,
        ...defaultState,
        dataType: action.data.dataType,
        queryString: action.data.queryString,
        error: action.data.error,
        isFetching: false,
      };

      if (REFERENCE !== '') {
        result = {
          ...state,
          reference: {
            ...state.reference,
            ...defaultState.reference,
            dataType: action.data.dataType,
            queryString: action.data.queryString,
            error: action.data.error,
            isFetching: false,
          },
        };
      }

      return result;
    }
    case types.CLEAR_SEARCH_RESULTS:
      if (action.data.subquery) {
        return {
          ...state,
          groupMembers: undefined,
        };
      }

      return {
        ...state,
        ...defaultState,
      };
    case types.SORT_SEARCH_RESULTS:
      return {
        ...state,
        dataType: action.data.dataType,
        results: sortData(state.results, action.data.sortBy),
      };
    case types.SEARCH_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        groupMembers: {
          groupId: action.data.groupId,
          queryString: action.data.queryString,
          resourceType: action.data.resourceType,
          orgs: action.data.orgs,
          members: action.data.members,
          nonMembers: action.data.nonMembers,
          meta: action.data.meta,
          error: undefined,
        },
      };
    case types.SEARCH_GROUP_MEMBERS_ERROR:
      return {
        ...state,
        groupMembers: {
          groupId: action.data.groupId,
          queryString: action.data.queryString,
          members: [],
          nonMembers: [],
          meta: action.data.meta,
          error: action.data.error,
        },
      };
    case types.USER_UPDATED:
    case types.UPDATE_GROUP[SUCCESS]:
    case types.UPDATE_GENERIC[SUCCESS]: {
      let resultsObj = {};
      let { id } = action.data;

      if (action.data.newId) {
        id = action.data.newId;
      }

      if (state.results) {
        resultsObj = {
          results: state.results.map((result) => {
            const { attributes } = result;
            const newResult = {
              ...result,
              id,
              attributes: {
                ...action.data.updatedAttributes,
              },
            };

            if (result.id !== action.data.id) {
              return result;
            }

            /*
             * The response from resetting the password will typically return an empty object
             * so we need to make sure we keep most previous attributes
             */
            if (action.data.hasNewPassword) {
              newResult.attributes = {
                ...attributes,
                ...newResult.attributes,
              };
            }

            return newResult;
          }),
        };
      }

      return {
        ...state,
        ...resultsObj,
      };
    }
    case types.GET_USER: {
      const userSearchAttr = getPrimarySearchAttributeKey(action.data.meta);
      const groupTitleAttr = getTitleAttributeKey(action.data.meta.included.groups);
      let resultsObj;

      if (state.results) {
        resultsObj = {
          results: state.results.map((user) => {
            if (user.id !== action.data.user.id) {
              return user;
            }
            return {
              ...user,
              included: action.data.included,
              meta: action.data.meta,
              primarySearchAttribute: userSearchAttr,
              groupTitleAttribute: groupTitleAttr,
            };
          }),
        };
      }

      return {
        ...state,
        ...resultsObj,
      };
    }
    case types.GET_USER_ERROR: {
      const userIdToRemove = _.get(action, 'data.user.id');

      return {
        ...state,
        results: state.results.filter(user => user.id !== userIdToRemove),
      };
    }
    case types.GET_GENERIC[SUCCESS]: {
      const genericSearchAttr = getPrimarySearchAttributeKey(action.data.meta);
      const groupTitleAttr = getTitleAttributeKey(action.data.meta.included.groups);
      let resultsObj;

      if (state.results) {
        resultsObj = {
          results: state.results.map((generic) => {
            if (generic.id !== action.data.generic.id) {
              return generic;
            }
            return {
              ...generic,
              included: action.data.included,
              meta: action.data.meta,
              primarySearchAttribute: genericSearchAttr,
              groupTitleAttribute: groupTitleAttr,
            };
          }),
        };
      }

      return {
        ...state,
        ...resultsObj,
      };
    }
    case types.ADD_GROUP_MEMBER:
      if (action.data.profilePageModel === MODEL_TYPES.USERS) {
        return {
          ...state,
        };
      }

      if (state.groupMembers && state.groupMembers.groupId === action.data.group.id) {
        const memberIds = action.data.members.map(m => m.id);
        return {
          ...state,
          groupMembers: {
            ...state.groupMembers,
            members: [...action.data.members, ...state.groupMembers.members],
            nonMembers: state.groupMembers.nonMembers.filter(m => !memberIds.includes(m.id)),
          },
        };
      }

      return {
        ...state,
      };
    case types.REMOVE_GROUP_MEMBER:
      if (action.data.profilePageModel === MODEL_TYPES.USERS) {
        return {
          ...state,
        };
      }

      if (state.groupMembers && state.groupMembers.groupId === action.data.group.id) {
        const memberIds = action.data.members.map(m => m.id);
        return {
          ...state,
          groupMembers: {
            ...state.groupMembers,
            members: state.groupMembers.members.filter(m => !memberIds.includes(m.id)),
            nonMembers: [...action.data.members, ...state.groupMembers.nonMembers],
          },
        };
      }

      return {
        ...state,
      };
    default:
      return state;
  }
}
