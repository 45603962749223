import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import { FormattedMessage } from 'react-intl';
import Spinner from 'ui-library/lib/components/general/Spinner';
import ReportTable from 'ui-library/lib/components/tables/ReportTable';
import Button, { buttonTypes } from 'ui-library/lib/components/buttons/Button';
import Message from '../../shared/message/Message/Message';
import { getReportResultsAction } from '../../../store/actions/report/report';
import { MODEL_TYPES } from '../../../utils/modelTypes';
import { formatBodyData } from '../../../utils/helpers';
import './ReportTable.css';

export class ReportPreviewTable extends Component {
  state = {
    headData: [],
    hasNext: true,
    batches: [{ id: 0, data: [] }],
    sort: {},
  };

  componentDidUpdate(prevProps) {
    const {
      model,
      report,
      selectedOptions,
    } = this.props;
    const prevPropsRows = prevProps.report.results.rows;
    const { rows } = report.results;
    const prevSelectedResourceTypeId = prevProps.selectedResourceTypeId;
    const { selectedResourceTypeId } = this.props;
    const { filters } = report;
    const prevFilters = prevProps.report.filters;

    if (prevSelectedResourceTypeId !== selectedResourceTypeId || !_.isEqual(prevFilters, filters)) {
      this.resetState();
    }

    if (model === MODEL_TYPES.GROUPS) {
      if (!_.isEqual(prevProps.selectedOptions.groupId, selectedOptions.groupId)) {
        this.resetState();
      }
    }

    if (!_.isEqual(prevPropsRows, rows)) {
      this.setInitialBatch();
    }
  }

  // infinite scroll callbacks/functions
  onNextBatch = () => {
    const { rows } = this.props.report.results;
    const { batches } = this.state;
    const numBatches = batches.length;
    const newBatchStart = numBatches * this.ENTRIES_PER_BATCH;
    const newBatchEnd = newBatchStart + this.ENTRIES_PER_BATCH;
    const newBatchData = rows.slice(newBatchStart, newBatchEnd);
    const newBatch = { id: numBatches, data: newBatchData };
    const newBatches = _.clone(batches);
    newBatches.push(newBatch);
    const hasNext = newBatchEnd <= rows.length;
    this.setState({ batches: newBatches, hasNext });
  };
  setInitialBatch = () => {
    const { header, rows } = this.props.report.results;
    const dataSlice = (rows).slice(0, this.ENTRIES_PER_BATCH);
    const newBatch = { id: 0, data: formatBodyData(dataSlice) };
    const hasNext = this.ENTRIES_PER_BATCH <= rows.length;
    this.setState({
      headData: header,
      hasNext,
      batches: [newBatch],
    });
  }

  getHeadContentType = (sortFunction) => {
    const { sort } = this.state;
    const { attributes } = this.props.report.filters;
    const HeaderCell = (props) => {
      let linkClass = null;
      if (sort.column === props.index) {
        if (sort.ascending) {
          linkClass = 'ascending';
        } else {
          linkClass = 'descending';
        }
      }

      const attr = Object
        .entries(attributes)
        .filter(([_key, value]) => value.displayName === props.data)
        .map(([key, value]) => ({
          attr: key,
          sortAttribute: value.sortAttribute,
        }))[0];

      if (attr && !attr.sortAttribute) {
        return props.data;
      }

      return (
        <Button
          className={linkClass}
          inline
          label={props.data}
          onClick={() => sortFunction(props.index, attr.attr)}
          type={buttonTypes.LINK}
        />
      );
    };
    return (<HeaderCell />);
  };

  handleSortBatches = (index, attr) => {
    const { sort } = this.state;
    const { selectedFieldsAttrs } = this.props.report.filters;
    const { selectedOptions } = this.props;
    const ascending = (sort && sort.column === index)
      ? !sort.ascending : true;
    const newSort = {
      column: index,
      ascending,
    };
    this.setState({ sort: newSort });
    this.props.getReportResultsAction({
      clearReportResults: true,
      selectedOptions,
      selectedFieldsAttrs,
      sort: {
        attr,
        ascending,
      },
    });
  };

  resetState = () => {
    this.setState({
      headData: [],
      hasNext: true,
      batches: [{ id: 0, data: [] }],
      sort: {},
    });
  }

  displayReportTable = () => {
    const {
      headData,
      hasNext,
      batches,
    } = this.state;
    const infiniteScrollProps = {
      onLoadPrev: _.noop,
      onLoadNext: this.onNextBatch,
      hasNext,
      batches,
    };
    const resultsCount = this.props.report.results.rows.length;
    /*
     * Need to use headData (state) instead of header (props)
     * Rows not at full width if the headData is given first than the infiniteScroll props
     * Both need to be given at the same time
     */
    if (_.isEmpty(headData)) {
      return (
        <Message
          className="report__message"
          message="components.dual-column-search.shared.nothing-to-display"
          type="NOTICE"
        />
      );
    }

    return (
      <>
        <div className="report__results-count">
          <FormattedMessage
            id="report.results"
            values={{ resultsCount }}
          />
        </div>
        <ReportTable
          fixedHead
          headContentType={this.getHeadContentType(this.handleSortBatches)}
          headData={headData}
          infiniteScroll={infiniteScrollProps}
        />
      </>
    );
  }

  ENTRIES_PER_BATCH = 50;

  render() {
    const { error, report } = this.props;

    if (error) {
      return (
        <Message
          className="report__message"
          message={error}
          type="ERROR"
        />
      );
    }

    return (
      <Spinner show={report.isFetching}>
        { this.displayReportTable() }
      </Spinner>
    );
  }
}

ReportPreviewTable.propTypes = {
  error: PropTypes.string,
  getReportResultsAction: PropTypes.func.isRequired,
  model: PropTypes.string.isRequired,
  report: PropTypes.shape({
    isFetching: PropTypes.bool,
    filters: PropTypes.shape({
      attributes: PropTypes.shape({}),
      selectedFieldsAttrs: PropTypes.arrayOf(PropTypes.string),
      scimFilter: PropTypes.string,
    }),
    results: PropTypes.shape({
      header: PropTypes.arrayOf(PropTypes.string),
      rows: PropTypes.instanceOf(Array),
    }),
  }),
  selectedOptions: PropTypes.shape({
    groupId: PropTypes.string,
  }),
  selectedResourceTypeId: PropTypes.string.isRequired,
};

ReportPreviewTable.defaultProps = {
  error: '',
  report: {
    isFetching: false,
    filters: {
      attributes: {},
      selectedFieldsAttrs: undefined,
      scimFilter: undefined,
    },
    results: {
      header: [],
      rows: [],
    },
  },
  selectedOptions: {},
};

export default connect(
  null,
  {
    getReportResultsAction,
  },
)(ReportPreviewTable);
