import * as types from '../../actions/actionTypes';
import { FAILURE, SUCCESS } from '../../actions';

export const defaultState = {
  error: undefined,
  dirty: false,
  isValid: false,
  currentAttrs: {},
  updatedAttrs: {},
  customIsValid: {},
  attributeErrors: {},
  reference: {
    errors: {},
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.MODEL_FORM_CLEAR: {
      return defaultState;
    }
    case types.MODEL_FORM_CLEAR_PASSWORD_ERROR: {
      const newState = {
        ...state,
        attributeErrors: {
          ...state.attributeErrors,
        },
      };

      if ('userPassword' in newState.attributeErrors) delete newState.attributeErrors.userPassword;

      return newState;
    }
    case types.MODEL_FORM_SET:
      return {
        ...state,
        ...action.data,
      };
    case types.MODEL_FORM_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    case types.UPDATE_GENERIC[SUCCESS]:
      return {
        ...state,
        attributeErrors: defaultState.attributeErrors,
      };
    case types.CREATE_USER_ERROR:
    case types.USER_UPDATED_ERROR:
    case types.CREATE_GROUP[FAILURE]:
    case types.UPDATE_GROUP[FAILURE]:
    case types.CREATE_GENERIC[FAILURE]:
    case types.UPDATE_GENERIC[FAILURE]:
      return {
        ...state,
        attributeErrors: action.data.attributeErrors,
      };
    default:
      return state;
  }
}
