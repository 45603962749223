import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import className from 'classnames';

import ScrollBox from 'ui-library/lib/components/layout/ScrollBox';
import Icon from 'ui-library/lib/components/general/Icon';
import { iconFor, getResourceById } from '../../../../utils/resourceTypes';

import './MembershipSummary.css';

const MembershipSummary = ({
  memberList,
  columnSize,
}) => {
  const members = memberList.filter(member => member);

  return (
    <div className="MembershipSummary">
      <div className="MembershipSummary__title">
        <FormattedMessage
          id="components.user-groups-summary.title"
          values={{
            count: memberList.length,
          }}
        />
      </div>
      <ScrollBox height={250}>
        <div
          className={className(
            { MembershipSummary__members: memberList.length > columnSize },
          )}
        >
          {
            members.map(member => (
              <div key={member.id}>
                <Icon
                  iconName={iconFor(_.get(getResourceById(member.type), 'attributes.resourceType', ''))}
                  type="leading"
                />
                {member.displayName}
              </div>
            ))
          }
        </div>
      </ScrollBox>
    </div>
  );
};

MembershipSummary.propTypes = {
  memberList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  columnSize: PropTypes.number.isRequired,
};

export default MembershipSummary;
