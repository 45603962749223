import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getTitleOrPrimaryAttributeValue } from '../../../../utils/helpers';
import MembershipSummary from '../MembershipSummary/MembershipSummary';

const GroupMembershipSummary = ({
  memberList,
  groupMeta,
  columnSize,
  model,
  sizeLimitExceeded,
}) => {
  // Arrange group members into format that MembershipSummary can use
  const displayableGroups = (
    memberList
      .reduce((result, member) => {
        result.push({
          id: member.id,
          displayName: member.type === model.type
            ? getTitleOrPrimaryAttributeValue(member.attributes, groupMeta)
            : getTitleOrPrimaryAttributeValue(
              member.attributes,
              _.get(groupMeta, `included[${member.type}].attributes`),
            ),
          type: member.type,
        });
        return result;
      }, [])
  );

  if (!_.isEmpty(displayableGroups)) {
    return (
      <MembershipSummary
        memberList={displayableGroups}
        columnSize={columnSize}
      />
    );
  }

  if (_.isEmpty(displayableGroups) && sizeLimitExceeded) {
    return (
      <FormattedMessage id="components.accordion-profile.size-limit-exceeded" />
    );
  }

  return (
    <FormattedMessage id="components.accordion-profile.no-members" />
  );
};

GroupMembershipSummary.propTypes = {
  memberList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    attributes: PropTypes.shape({}).isRequired,
    included: PropTypes.arrayOf(PropTypes.shape({})),
  })),
  model: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  columnSize: PropTypes.number,
  groupMeta: PropTypes.shape({}).isRequired,
  sizeLimitExceeded: PropTypes.bool.isRequired,
};

GroupMembershipSummary.defaultProps = {
  columnSize: 10,
  memberList: [],
};

export default GroupMembershipSummary;
