import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { loadUser } from 'redux-oidc';

import rootReducer from './reducers';
import userManager from '../utils/userManager';
import { flattenMessages } from '../utils/helpers';
import en from '../locales/en.json';

const defaultLocale = 'en';
const locales = {
  en,
};

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(
  rootReducer,
  {
    intl: {
      defaultLocale,
      messages: flattenMessages(locales[defaultLocale]),
    },
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
loadUser(store, userManager);

export default store;
