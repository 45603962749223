import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FormDropDownList from 'ui-library/lib/components/forms/FormDropDownList';
import InputWidths from 'ui-library/lib/components/forms/InputWidths';
import FormSearchBox from 'ui-library/lib/components/forms/FormSearchBox';
import Spinner from 'ui-library/lib/components/general/Spinner';

import SearchDropdownItem from './SearchDropdownItem/SearchDropdownItem';
import Message from '../../message/Message/Message';
import './SearchDropdown.css';

const SearchDropdown = (props) => {
  const {
    disabled,
    errorMessage,
    handleReferenceClick,
    handleReferenceSearch,
    labelText,
    labelHelpText,
    name,
    options,
    referenceResourceType,
    searchBoxPlaceholder,
    value,
  } = props;
  const [searchString, setSearchString] = useState('');
  const [openResults, setOpenResults] = useState(false);
  const { error, isFetching, results } = useSelector(state => state.search.reference);
  const messageType = (error) ? 'ERROR' : 'NOTICE';
  const message = (error) ? 'components.drop-down.reference.error' : 'components.dual-column-search.shared.nothing-to-display';
  const searchValue = (value) ? value.label : '';
  const inputProps = {};

  useEffect(() => {
    setSearchString(searchValue);
  }, [searchValue]);

  const handleOnClick = (option) => {
    const { label } = option;
    setSearchString(label);
    setOpenResults(false);
    handleReferenceClick(
      name,
      { id: option.value, label, type: referenceResourceType },
    );
  };

  if (!disabled) {
    inputProps.renderInput = (
      (renderProps, _Element) => {
        const { ...passProps } = renderProps;
        return (
          <FormSearchBox
            {...passProps}
            placeholder={searchBoxPlaceholder}
            value={searchString}
            onBlur={() => {
              if (value && !openResults) {
                setSearchString(value.label);
              }
            }}
            onClear={() => {
              setSearchString('');
              setOpenResults(false);
              handleReferenceClick(name, null);
            }}
            onValueChange={val => setSearchString(val)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpenResults(true);
                handleReferenceSearch(searchString);
              }
            }}
          />
        );
      }
    );
  }

  return (
    <FormDropDownList
      className="reference"
      disabled={disabled}
      errorMessage={errorMessage}
      label={labelText}
      labelHelpText={labelHelpText}
      name={name}
      open={openResults}
      options={options}
      searchType={FormDropDownList.SearchTypes.BOX}
      selectedOption={value}
      width={InputWidths.MD}
      renderList={
        (renderProps, Element) => (
          <Element {...renderProps}>
            <Spinner show={isFetching}>
              {
                results.length === 0 &&
                <Message
                  message={message}
                  type={messageType}
                />
              }
              { options.map(option => (
                <SearchDropdownItem
                  handleOnClick={handleOnClick}
                  option={option}
                />
                ))
              }
            </Spinner>
          </Element>
        )
      }
      {...inputProps}
    />
  );
};

SearchDropdown.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleReferenceClick: PropTypes.func.isRequired,
  handleReferenceSearch: PropTypes.func.isRequired,
  labelHelpText: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  referenceResourceType: PropTypes.string.isRequired,
  searchBoxPlaceholder: PropTypes.string,
  value: PropTypes.shape({
    label: PropTypes.string,
  }),
};

SearchDropdown.defaultProps = {
  disabled: false,
  errorMessage: '',
  labelHelpText: '',
  labelText: '',
  options: [],
  searchBoxPlaceholder: '',
  value: null,
};


export default SearchDropdown;
