import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { FormattedMessage } from 'react-intl';
import Spinner from 'ui-library/lib/components/general/Spinner';
import ReportTable from 'ui-library/lib/components/tables/ReportTable';
import Button, { buttonTypes } from 'ui-library/lib/components/buttons/Button';
import Message from '../message/Message/Message';
import { formatBodyData } from '../../../utils/helpers';
import './ReportTable.css';

export class ReportPreviewTable extends Component {
  state = {
    headData: [],
    hasNext: true,
    batches: [{ id: 0, data: [] }],
    sort: {},
  };

  componentDidMount() {
    const { header } = this.props.data.results;
    this.setState({ headData: header });
    this.setInitialBatch();
  }

  componentDidUpdate(prevProps) {
    const prevPropsRows = prevProps.data.results.rows;
    const { rows } = this.props.data.results;
    if (!_.isEqual(prevPropsRows, rows)) {
      this.resetState();
      this.setInitialBatch();
    }
  }

  // infinite scroll callbacks/functions
  onNextBatch = () => {
    const { rows } = this.props.data.results;
    const { batches } = this.state;
    const numBatches = batches.length;
    const newBatchStart = numBatches * this.ENTRIES_PER_BATCH;
    const newBatchEnd = newBatchStart + this.ENTRIES_PER_BATCH;
    const newBatchData = rows.slice(newBatchStart, newBatchEnd);
    const newBatch = { id: numBatches, data: newBatchData };
    const newBatches = _.clone(batches);
    newBatches.push(newBatch);
    const hasNext = newBatchEnd <= rows.length;
    this.setState({ batches: newBatches, hasNext });
  };
  setInitialBatch = () => {
    const { header, rows } = this.props.data.results;
    const dataSlice = (rows).slice(0, this.ENTRIES_PER_BATCH);
    const newBatch = { id: 0, data: formatBodyData(dataSlice) };
    const hasNext = this.ENTRIES_PER_BATCH <= rows.length;
    this.setState({
      headData: header,
      hasNext,
      batches: [newBatch],
    });
  }

  getHeadContentType = (sortFunction) => {
    const { sort } = this.state;
    const HeaderCell = (props) => {
      let linkClass = null;
      if (sort.column === props.index) {
        if (sort.ascending) {
          linkClass = 'ascending';
        } else {
          linkClass = 'descending';
        }
      }
      return (
        <Button
          className={linkClass}
          inline
          label={props.data}
          onClick={e => sortFunction(props.index, e)}
          type={buttonTypes.LINK}
        />
      );
    };
    return (<HeaderCell />);
  };

  handleSortBatches = (index) => {
    const { sort, batches } = this.state;
    const ascending = (sort && sort.column === index)
      ? !sort.ascending : true;
    const nextBatchData = _.sortBy(
      _.flatten(batches.map(batch => batch.data), true),
      (a) => {
        const val = a[index];
        return (!_.isUndefined(val) && _.isString(val)) ? val.toLowerCase() : val;
      });

    if (ascending !== true) {
      nextBatchData.reverse();
    }

    const newBatches = _.range(batches.length).map((batchNumber) => {
      const start = batchNumber * this.ENTRIES_PER_BATCH;
      const end = start + this.ENTRIES_PER_BATCH;
      const slice = nextBatchData.slice(start, end);
      return ({ id: batchNumber, data: slice });
    });
    const newSort = {
      column: index,
      ascending,
    };
    this.setState({ batches: newBatches, sort: newSort });
  };

  resetState = () => {
    this.setState({
      headData: [],
      hasNext: true,
      batches: [{ id: 0, data: [] }],
      sort: {},
    });
  }

  displayReportTable = () => {
    const {
      headData,
      hasNext,
      batches,
    } = this.state;
    const infiniteScrollProps = {
      onLoadPrev: _.noop,
      onLoadNext: this.onNextBatch,
      hasNext,
      batches,
    };
    const resultsCount = this.props.data.results.rows.length;
    /*
     * Need to use headData (state) instead of header (props)
     * Rows not at full width if the headData is given first than the infiniteScroll props
     * Both need to be given at the same time
     */
    if (_.isEmpty(headData)) {
      return (
        <div className="report">
          <Message
            className="report__message"
            message="components.dual-column-search.shared.nothing-to-display"
            type="NOTICE"
          />
        </div>
      );
    }

    return (
      <div className="report">
        <div className="report__results-count">
          <FormattedMessage id="report.results" values={{ resultsCount }} />
        </div>
        <ReportTable
          fixedHead
          headContentType={this.getHeadContentType(this.handleSortBatches)}
          headData={headData}
          infiniteScroll={infiniteScrollProps}
        />
      </div>
    );
  }

  ENTRIES_PER_BATCH = 50;

  render() {
    const { data } = this.props;
    return (
      <Spinner show={data.isFetching}>
        { this.displayReportTable() }
      </Spinner>
    );
  }
}

ReportPreviewTable.propTypes = {
  data: PropTypes.shape({
    isFetching: PropTypes.bool,
    results: PropTypes.shape({
      header: PropTypes.arrayOf(PropTypes.string),
      rows: PropTypes.instanceOf(Array),
    }),
  }),
};

ReportPreviewTable.defaultProps = {
  data: {
    isFetching: false,
    results: {
      header: [],
      rows: [],
    },
  },
};

export default ReportPreviewTable;
