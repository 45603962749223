// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

// UI-Library
import Messages from 'ui-library/lib/components/general/messages/Messages';
import MessageActions from 'ui-library/lib/components/general/messages/MessagesActions';
import Spinner from 'ui-library/lib/components/general/Spinner';
import InputRow from 'ui-library/lib/components/layout/InputRow';

// Components
import MessageContainer from '../../../../containers/MessageContainer/MessageContainer';
import ResourceTypeContainer from '../../../../containers/ResourceTypeContainer/ResourceTypeContainer';
import DropDownList from '../../list-inputs/DropDownList/DropDownList';
import ModelFormV2 from '../../forms/ModelFormV2/ModelFormV2';
import PermissionMessage from '../Message/PermissionMessage';

// Store
import { filterResourceTypesAction } from '../../../../store/actions/resourceTypes/resourceTypes';
import { setModalAction } from '../../../../store/actions/modal/modal';

// Utils
import { MODEL_TYPES } from '../../../../utils/modelTypes';
import { RESOURCE_TYPES, PROP_RESOURCE_TYPES, getResourceById } from '../../../../utils/resourceTypes';
import permissions, { PERMISSIONS } from '../../../../utils/permissions/permissions';
import { createDropDownOptions } from '../../../../utils/helpers';

export class CreateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areFieldsValid: true,
      fields: undefined,
    };
  }

  componentDidMount() {
    this.createDropDowns([MODEL_TYPES.GROUPS, MODEL_TYPES.GENERICS]);
    this.filterResourceTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    /* eslint-disable react/no-did-update-set-state */
    const {
      selected,
      selectedGroupsResource,
      selectedParentResource,
    } = this.props;

    if (prevProps.selected !== selected) {
      this.filterResourceTypes();
    }

    if (prevProps.selectedGroupsResource !== selectedGroupsResource) {
      this.setDropDownOptions(selectedGroupsResource, MODEL_TYPES.GROUPS);
    }

    if (prevProps.selectedParentResource !== selectedParentResource) {
      this.setDropDownOptions(selectedParentResource, MODEL_TYPES.GENERICS);
    }

    if (prevState.fields !== this.state.fields) {
      const { fields } = this.state;
      const optionValues = _.mapValues(fields, 'options');
      const areAllOptionsUndefined = _.every(optionValues, _.isUndefined);
      const areAnySelectedOptionsUndefined = (
        Object
          .values(fields)
          .some(val => _.get(val, 'options') && _.isUndefined(_.get(val, 'selectedOption')))
      );
      if (areAllOptionsUndefined) {
        this.setState({ areFieldsValid: true });
      } else {
        this.setState({ areFieldsValid: !areAnySelectedOptionsUndefined });
      }
    }
  }

  setDropDownOptions = (props, type) => {
    const { data, meta } = props;

    if (!data || data.length === 0 || !meta) {
      this.setState(state => ({
        fields: {
          ...state.fields,
          [type]: {
            ...state.fields[type],
            options: undefined,
            selectedOption: undefined,
          },
        },
      }));
      return;
    }

    const options = createDropDownOptions(data, meta);

    this.setState(state => ({
      fields: {
        ...state.fields,
        [type]: {
          ...state.fields[type],
          options,
          selectedOption: options[0],
        },
      },
    }));
  }

  createDropDowns = (types) => {
    types.forEach((type) => {
      this.setState(state => ({
        fields: {
          ...state.fields,
          [type]: {
            errorMessage: 'validation.required',
            label: `containers.model-form.${type}-dropdown-label`,
            labelPrompt: 'components.search-bar-drop-down.list-prompt',
            options: undefined,
            required: true,
            selectedOption: undefined,
            showError: false,
            type,
          },
        },
      }));
    });
  }

  async filterResourceTypes() {
    const { selected } = this.props;
    const { resourceType } = selected.attributes;
    const filter = {
      byType: [resourceType],
      byPermission: PERMISSIONS.CREATE,
    };
    const getParent = _.get(selected, 'attributes.parentResourceType', undefined);

    await this.props.filterResourceTypesAction(filter, {
      getParent,
      getGroups: true,
      CREATE: PERMISSIONS.CREATE,
    });
  }

  handleCreate = async (attrs) => {
    const { closeAlertModal, closeModal, handleSave } = this.props;
    const { fields } = this.state;
    let relationshipOptions;
    Object.keys(fields).forEach((key) => {
      const { selectedOption } = fields[key];
      if (selectedOption) {
        relationshipOptions = {
          ...relationshipOptions,
          [key]: selectedOption,
        };
      }
    });
    closeAlertModal();
    try {
      const res = await this.props.createAction(attrs, relationshipOptions);
      if (res.status === 200) {
        this.props.setModalAction({ dirty: false });
        closeModal();
        handleSave(res.data.data);
      }
    } catch (e) {
      // Handle errors in actions
    }
  }

  handleDropDownChange = (selectedOption, type) => {
    this.setState(state => ({
      fields: {
        ...state.fields,
        [type]: {
          ...state.fields[type],
          selectedOption,
        },
      },
    }));
  };

  handleDropDownToggle = (type) => {
    this.setState((state) => {
      const {
        required,
        selectedOption,
      } = state.fields[type];
      return ({
        fields: {
          ...state.fields,
          [type]: {
            ...state.fields[type],
            showError: required && !selectedOption,
          },
        },
      });
    });
  }

  handleDropDownReset = () => {
    const { fields } = this.state;
    Object.keys(fields).forEach((key) => {
      const { options } = fields[key];
      if (options && options.length > 1) {
        this.setState(state => ({
          fields: {
            ...state.fields,
            [key]: {
              ...state.fields[key],
              selectedOption: undefined,
              showError: false,
            },
          },
        }));
      }
    });
  }

  render() {
    const {
      hasAlertSave,
      isModalOpen,
      messages,
      meta,
      resourceTypes,
      selected,
      selectedGroupsResource,
      selectedParentResource,
    } = this.props;
    const {
      resourceType,
      parentResourceType,
    } = selected.attributes;
    const {
      areFieldsValid,
      fields,
    } = this.state;
    const showSpinner = !!(
      selectedParentResource.isRequesting || selectedGroupsResource.isRequesting
    );
    let groupResourceType;

    if (fields) {
      if (fields.groups.selectedOption) {
        groupResourceType = fields.groups.selectedOption.type;
      }
    }

    const parentError = selectedParentResource.error;
    const {
      hasPermission,
      hasParentPermissions,
      hasGroupPermission,
    } = permissions.hasPermissionsForModal({
      parentResourceType,
      groupResourceType,
    });

    return (
      <>
        {
          !_.isEmpty(messages) &&
          <MessageContainer
            messages={messages}
            onRemoveMessage={MessageActions.removeAt}
            defaultMessageLayout={Messages.Layouts.BANNER}
          />
        }
        {
          resourceType !== RESOURCE_TYPES.GENERIC &&
          <ResourceTypeContainer
            filter={{
              byPermission: PERMISSIONS.CREATE,
              byType: [resourceType],
            }}
            getParent
            getGroups
            isModalOpen
            showLabel
          />
        }
        <Spinner show={showSpinner}>
          {
            !_.isEmpty(fields) &&
            Object.keys(fields).map((key) => {
              const {
                errorMessage,
                label,
                labelPrompt,
                options,
                required,
                selectedOption,
                showError,
                type,
                } = fields[key];

                if (options && options.length > 0) {
                return (
                  <InputRow key={key}>
                    <DropDownList
                      errorMessage={(showError) ? errorMessage : ''}
                      handleOnToggle={() => this.handleDropDownToggle(type)}
                      label={{
                        id: label,
                        displayName: _.get(getResourceById(parentResourceType), 'attributes.displayName', undefined),
                      }}
                      labelPrompt={labelPrompt}
                      handleOnChange={option => this.handleDropDownChange(option, type)}
                      options={options}
                      required={required}
                      selectedOption={selectedOption}
                    />
                  </InputRow>
                );
                }
                return null;
            })
          }
          {
            (parentError || !hasPermission)
            ?
              <PermissionMessage
                parentError={parentError}
                hasParentPermissions={hasParentPermissions}
                hasGroupPermission={hasGroupPermission}
                parentResourceType={parentResourceType}
                groupResourceType={groupResourceType}
              />
            :
              <ModelFormV2
                areFieldsValid={areFieldsValid}
                hasAlertSave={hasAlertSave}
                isModalOpen={isModalOpen}
                meta={meta}
                onReset={this.handleDropDownReset}
                onSave={this.handleCreate}
                selected={selected}
                resourceTypes={resourceTypes.data}
              />
          }
        </Spinner>
      </>
    );
  }
}

CreateModal.propTypes = {
  closeAlertModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  createAction: PropTypes.func.isRequired,
  filterResourceTypesAction: PropTypes.func.isRequired,
  hasAlertSave: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string,
    }).isRequired,
  }),
  messages: PropTypes.arrayOf([PropTypes.shape()]),
  meta: PropTypes.shape({
    attributeCategories: PropTypes.arrayOf(PropTypes.string),
    attributes: PropTypes.shape({}).isRequired,
  }).isRequired,
  resourceTypes: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
  }),
  selected: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      displayName: PropTypes.string,
      parentResourceType: PropTypes.string,
      resourceType: PropTypes.oneOf(PROP_RESOURCE_TYPES).isRequired,
    }).isRequired,
  }).isRequired,
  selectedGroupsResource: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({}),
    error: PropTypes.string,
    isRequesting: PropTypes.bool,
  }),
  selectedParentResource: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({}),
    error: PropTypes.string,
    isRequesting: PropTypes.bool,
  }),
  setModalAction: PropTypes.func.isRequired,
};

CreateModal.defaultProps = {
  match: {
    params: { type: undefined },
  },
  resourceTypes: {
    data: [],
  },
  selectedGroupsResource: {
    data: [],
    meta: undefined,
    error: undefined,
    isRequesting: false,
  },
  selectedParentResource: {
    data: [],
    meta: undefined,
    error: undefined,
    isRequesting: false,
  },
  messages: {
    undefined,
  },
};

function mapStateToProps(state) {
  const { resourceTypes } = state;
  const { create } = state.resourceTypes;
  const meta = create.selected.attributes;

  return {
    meta,
    resourceTypes,
    selected: create.selected,
    selectedGroupsResource: create.selectedGroupsResource,
    selectedParentResource: create.selectedParentResource,
  };
}

export default connect(
  mapStateToProps,
  {
    filterResourceTypesAction,
    setModalAction,
  },
)(CreateModal);
