import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-jdateformatparser';
import { FormattedMessage } from 'react-intl';
import FormIntegerField from 'ui-library/lib/components/forms/form-integer-field';
import Toggle from 'ui-library/lib/components/forms/form-toggle';
import Calendar from 'ui-library/lib/components/calendars/Calendar';
import FormTextField from 'ui-library/lib/components/forms/form-text-field';
import Disabled from 'ui-library/lib/components/layout/Disabled';

import { FIELD_TYPES } from '../../../utils/formHelpers';
import './ReportFiltersEntry.css';

const ReportFiltersEntry = (props) => {
  const { disabled } = props;
  const {
    selected: {
      dataType,
      dateTimeFormat,
    },
    value,
  } = props.filterType;

  const handleChange = (e) => {
    const { handleEntryChange } = props;
    const val = (e.target) ? e.target.value : e;
    handleEntryChange(val);
  };

  if (dataType === FIELD_TYPES.INTEGER) {
    return (
      <FormIntegerField
        disabled={disabled}
        onValueChange={(val) => {
          if (FormIntegerField.isValid(val) || val === '-') {
            handleChange(val);
          }
        }}
        value={value}
      />
    );
  }
  if (dataType === FIELD_TYPES.BOOLEAN) {
    return (
      <div className="report-filters__toggle">
        <span>
          <FormattedMessage id="components.toggle.false" />
        </span>
        <Toggle
          className="toggle"
          toggled={value}
          onToggle={() => handleChange(!value)}
        />
        <span>
          <FormattedMessage id="components.toggle.true" />
        </span>
      </div>
    );
  }
  if (dataType === FIELD_TYPES.DATETIME) {
    const toMomentFormatString = moment().toMomentFormatString(dateTimeFormat);
    const calendar = (<Calendar
      className="calendar"
      closeOnSelect
      computableFormat={toMomentFormatString}
      date={value}
      format={toMomentFormatString}
      onInputTextValueChange={val => handleChange(val)}
      onValueChange={(val) => {
        let date = moment(val).format(toMomentFormatString);
        if (!moment(date).isValid()) {
          date = '';
        }
        handleChange(date);
      }}
      placeholder={toMomentFormatString}
    />);
    // Calendar component has no disabled prop. Need to use <Disabled>
    if (disabled) {
      return (
        <Disabled>
          {calendar}
        </Disabled>
      );
    }
    return calendar;
  }
  return (
    <FormTextField
      disabled={disabled}
      onChange={handleChange}
      value={value}
    />
  );
};

ReportFiltersEntry.propTypes = {
  disabled: PropTypes.bool.isRequired,
  filterType: PropTypes.shape({
    selected: PropTypes.shape({
      dataType: PropTypes.string,
      dateTimeFormat: PropTypes.string,
    }),
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
  }).isRequired,
  handleEntryChange: PropTypes.func.isRequired,
};

export default ReportFiltersEntry;
