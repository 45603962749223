import { getPrimarySearchAttributeKey, getTitleAttributeKey } from '../../../utils/helpers';
import * as types from '../../actions/actionTypes';
import { MODEL_TYPES } from '../../../utils/modelTypes';
import { SUCCESS, FAILURE } from '../../actions';

export default function (state = {}, action) {
  switch (action.type) {
    case types.GET_GROUP: {
      const userSearchAttr = getPrimarySearchAttributeKey(action.data.meta.included.users);
      const groupTitleAttr = getTitleAttributeKey(action.data.meta);

      return {
        ...state,
        group: {
          data: action.data.group,
        },
        included: action.data.included,
        meta: action.data.meta,
        primarySearchAttribute: userSearchAttr,
        groupTitleAttribute: groupTitleAttr,
        error: undefined,
      };
    }
    case types.GET_GROUP_ERROR:
      return {
        ...state,
        group: {
          data: {},
        },
        error: action.data.error,
      };
    case types.CREATE_GROUP[SUCCESS]:
      return {
        ...state,
        newGroup: {
          data: action.data.group,
        },
      };
    case types.CREATE_GROUP[FAILURE]:
      return {
        ...state,
        newGroup: {
          data: {},
        },
        error: action.data.error,
      };
    case types.UPDATE_GROUP[SUCCESS]: {
      let groupObj = {};
      let { id } = action.data;

      if (action.data.newId) {
        id = action.data.newId;
      }

      if (state.group) {
        groupObj = {
          group: {
            ...state.group,
            data: {
              ...state.group.data,
              id,
              attributes: {
                ...action.data.updatedAttributes,
              },
            },
          },
        };
      }

      return {
        ...state,
        ...groupObj,
      };
    }
    case types.UPDATE_GROUP[FAILURE]:
      return {
        ...state,
        error: action.data.error,
      };
    case types.GET_GROUP_MEMBERS_SUCCESS: {
      // Replace array element if already in array, otherwise concat.
      let groupMembers = [action.data];
      if (state.groupMembers) {
        // If the group already shows up in the array, replace it instead of adding a new entry
        if (state.groupMembers.some(member => member.groupId === action.data.groupId)) {
          groupMembers = replaceGroupMembers(state.groupMembers, action.data);
        } else {
          // Add the group to the array
          groupMembers = [...state.groupMembers, action.data];
        }
      }
      return {
        ...state,
        groupMembers,
      };
    }
    case types.GET_GROUP_MEMBERS_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    case types.ADD_GROUP_MEMBER:
      if (action.data.profilePageModel === MODEL_TYPES.GROUPS) {
        return {
          ...state,
          included: [
            ...state.included,
            ...action.data.members,
          ],
        };
      }

      return {
        ...state,
      };
    case types.ADD_GROUP_MEMBER_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    case types.REMOVE_GROUP_MEMBER: {
      if (action.data.profilePageModel === MODEL_TYPES.GROUPS) {
        const members = state.included.filter(m => !action.data.members.includes(m));
        return {
          ...state,
          included: [
            ...members,
          ],
        };
      }

      return {
        ...state,
      };
    }
    case types.REMOVE_GROUP_MEMBER_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    case types.CLEAR_NEW_GROUP:
      return {
        ...state,
        newGroup: undefined,
      };
    case types.GROUP_FORM_IS_DIRTY:
      return {
        ...state,
        groupFormIsDirty: true,
      };
    case types.GROUP_FORM_IS_CLEAN:
      return {
        ...state,
        groupFormIsDirty: false,
      };
    default:
      return state;
  }
}

function replaceGroupMembers(groupMembers, updatedGroup) {
  return groupMembers.map((member) => {
    if (member.groupId === updatedGroup.groupId) {
      return {
        ...member,
        members: updatedGroup.members,
      };
    }
    return member;
  });
}
