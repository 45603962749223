import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import Spinner from 'ui-library/lib/components/general/Spinner';

import { getGenericAction, editGenericAction } from '../../../store/actions/generics/index';
import { getSearchResults } from '../../../store/actions/search/search';
import { unlinkObjects, linkObjects } from '../../../store/actions/correlatedResources/correlatedResources';
import EditModelPage from '../../shared/pages/EditModelPage/EditModelPage';
import { MODEL_TYPES } from '../../../utils/modelTypes';
import { getTitleOrPrimaryAttributeValue } from '../../../utils/helpers';
import * as routes from '../../../utils/routes';
import permissions, { PERMISSIONS } from '../../../utils/permissions/permissions';

export class GenericProfilePage extends Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.handleUnlinkObjects = this.handleUnlinkObjects.bind(this);
    this.handleLinkObjects = this.handleLinkObjects.bind(this);
    this.getGeneric = this.getGeneric.bind(this);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getGeneric();
  }

  async getGeneric() {
    const { id, type } = this.props.match.params;

    try {
      await this.props.getGenericAction(id, type);
      this.setState({ isLoading: false });
    } catch (e) {
      // Errors caught in actions
    }
  }

  handleSearch(queryString = '', options = {}) {
    this.props.getSearchResults(queryString, {
      ...options,
    });
  }

  handleClearSearch(options = {}) {
    this.handleSearch('', {
      skipMessage: true,
      hideSpinner: true,
      ...options,
    });
  }

  async handleUnlinkObjects(correlationCfg, secondaryObject) {
    const { generic } = this.props;
    await this.props.unlinkObjects(correlationCfg, generic, secondaryObject);
  }

  async handleLinkObjects(correlationCfg, docToBeLinked) {
    const { generic } = this.props;
    await this.props.linkObjects(correlationCfg, generic, docToBeLinked);
  }

  render() {
    const {
      generic,
      meta,
      genericGroups,
      error,
    } = this.props;
    const { isLoading } = this.state;
    let breadcrumbPath;
    let genericName;
    let canUpdate;
    let canManage;

    if (generic) {
      breadcrumbPath = generatePath(routes.GENERICS_SEARCH_ROUTE, {
        model: MODEL_TYPES.GENERICS,
        type: generic.type,
      });
      genericName = getTitleOrPrimaryAttributeValue(generic.attributes, meta);
      canUpdate = permissions.hasPermission(PERMISSIONS.UPDATE, generic.type) ||
        permissions.hasPermission(PERMISSIONS.UPDATE_PROFILE, generic.type);
      canManage = permissions.manageGroups();
    }

    return (
      <Spinner show={isLoading}>
        <EditModelPage
          profilePageModel={MODEL_TYPES.GENERICS}
          model={generic}
          modelMeta={meta}
          modelDisplayAttributeValue={genericName}
          membershipResults={genericGroups}
          editModelAction={this.props.editGenericAction}
          onSearch={this.handleSearch}
          onClearSearch={this.handleClearSearch}
          error={error}
          breadcrumbPath={breadcrumbPath}
          canUpdateModel={canUpdate}
          canManageMembershipForModel={canManage}
          onUnlink={this.handleUnlinkObjects}
          onLink={this.handleLinkObjects}
          getModelType={this.getGeneric}
        />
      </Spinner>
    );
  }
}

GenericProfilePage.propTypes = {
  editGenericAction: PropTypes.func.isRequired,
  getGenericAction: PropTypes.func.isRequired,
  getSearchResults: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  generic: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    attributes: PropTypes.shape({}).isRequired,
    included: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  meta: PropTypes.shape({
    attributes: PropTypes.shape({}).isRequired,
    included: PropTypes.shape({}),
  }),
  error: PropTypes.string,
  genericGroups: PropTypes.shape({
    dataType: PropTypes.string,
    queryString: PropTypes.string.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({})),
    included: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({}),
    primarySearchAttribute: PropTypes.string,
    groupTitleAttribute: PropTypes.string,
    error: PropTypes.string,
  }),
  unlinkObjects: PropTypes.func.isRequired,
  linkObjects: PropTypes.func.isRequired,
};

GenericProfilePage.defaultProps = {
  generic: undefined,
  error: undefined,
  meta: undefined,
  genericGroups: undefined,
};

function mapStateToProps(state) {
  const {
    generic,
    included,
    genericGroups,
    error,
    meta,
  } = state.generic;
  const completeGeneric = generic && {
    ...generic.data,
    included,
  };

  return {
    error,
    meta,
    genericGroups,
    generic: completeGeneric,
  };
}

export default connect(
  mapStateToProps,
  {
    getGenericAction,
    editGenericAction,
    getSearchResults,
    unlinkObjects,
    linkObjects,
  },
)(GenericProfilePage);
