import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createAction, FAILURE, SUCCESS } from '..';
import endpoints from '../../../utils/apiEndpoints';
import axios from '../../../utils/axiosInstance';
import { LINK_OBJECTS, UNLINK_OBJECTS } from '../actionTypes';
import {
  dispatchErrorMessage,
  dispatchSuccessMessage,
  generateHttpErrorMessage,
} from '../../../utils/helpers';
import { getUserAction } from '../userActions';
import { getResourceById, RESOURCE_TYPES } from '../../../utils/resourceTypes';
import { getGroupAction } from '../groups/groups';
import { getGenericAction } from '../generics';

export const correlatedResourceActions = {
  linkObjectsSuccess: data => createAction(LINK_OBJECTS[SUCCESS], { data }),
  linkObjectsFailure: data => createAction(LINK_OBJECTS[FAILURE], { data }),
  unlinkObjectsSuccess: data => createAction(UNLINK_OBJECTS[SUCCESS], { data }),
  unlinkObjectsFailure: data => createAction(UNLINK_OBJECTS[FAILURE], { data }),
};

export function linkObjects(correlationCfg, primaryObject, secondaryObject) {
  return async (dispatch) => {
    if (correlationCfg.isCorrelatedResource) {
      const {
        primary,
        secondary,
        useSecondaryValueForLinking,
      } = correlationCfg.correlationAttributes;

      let correlationAttr;
      let objectToUpdate;
      let updatedValues;
      let objectType;

      if (useSecondaryValueForLinking) {
        correlationAttr = primary;

        const primaryValues = primaryObject.attributes[primary] || [];
        const secondaryValue = secondaryObject.attributes[secondary][0];
        primaryValues.push(secondaryValue);

        objectToUpdate = primaryObject;
        updatedValues = primaryValues;
        objectType = primaryObject.type;
      } else {
        correlationAttr = secondary;

        const primaryValue = primaryObject.attributes[primary][0];
        const secondaryValues = secondaryObject.attributes[secondary] || [];
        secondaryValues.push(primaryValue);

        objectToUpdate = secondaryObject;
        updatedValues = secondaryValues;
        objectType = correlationCfg.correlatedResourceType;
      }

      try {
        await updateObject(objectToUpdate, objectType, correlationAttr, updatedValues);
        dispatch({
          type: LINK_OBJECTS[SUCCESS],
        });

        dispatchSuccessMessage(
          dispatch,
          <FormattedMessage
            id="actions.update.success"
            values={{
              resourceType: correlationCfg.currentEntry,
            }}
          />,
        );

        await reloadPrimaryObject(dispatch, primaryObject);
      } catch (error) {
        const errorMessage = generateHttpErrorMessage(error);
        const { intlKey } = errorMessage;
        dispatch({
          type: LINK_OBJECTS[FAILURE],
          data: {
            error: intlKey,
          },
        });

        dispatchErrorMessage(
          dispatch,
          <>{error.response.data?.errors[0].title}
          </>
          ,
        );
      }
    }
  };
}

export function unlinkObjects(correlationCfg, primaryObject, secondaryObject) {
  return async (dispatch) => {
    if (correlationCfg.isCorrelatedResource) {
      const {
        primary,
        secondary,
        useSecondaryValueForLinking,
      } = correlationCfg.correlationAttributes;

      let correlationAttr;
      let objectToUpdate;
      let updatedValues;
      let objectType;

      if (useSecondaryValueForLinking) {
        correlationAttr = primary;

        const secondaryValues = secondaryObject.attributes[secondary];

        let primaryValues = primaryObject.attributes[primary];
        primaryValues = primaryValues.filter(v => !secondaryValues.includes(v));

        objectToUpdate = primaryObject;
        updatedValues = primaryValues;
        objectType = primaryObject.type;
      } else {
        correlationAttr = secondary;
        const primaryValues = primaryObject.attributes[primary];

        let secondaryValues = secondaryObject.attributes[secondary] || [];
        secondaryValues = secondaryValues.filter(v => !primaryValues.includes(v));

        objectToUpdate = secondaryObject;
        updatedValues = secondaryValues;
        objectType = correlationCfg.correlatedResourceType;
      }

      try {
        await updateObject(objectToUpdate, objectType, correlationAttr, updatedValues);

        dispatch({
          type: UNLINK_OBJECTS[SUCCESS],
        });

        dispatchSuccessMessage(
          dispatch,
          <FormattedMessage
            id="actions.update.success"
            values={{
              resourceType: correlationCfg.currentEntry,
            }}
          />,
        );

        await reloadPrimaryObject(dispatch, primaryObject);
      } catch (error) {
        const errorMessage = generateHttpErrorMessage(error);
        const { intlKey, statusCode } = errorMessage;
        dispatch({
          type: UNLINK_OBJECTS[FAILURE],
          data: {
            error: intlKey,
          },
        });

        dispatchErrorMessage(
          dispatch,
          <FormattedMessage
            id={intlKey}
            values={{
              statusCode,
            }}
          />,
        );
      }
    }
  };
}

async function updateObject(object, type, attributeName, attributeValues) {
  const patchAttributes = {};
  patchAttributes[attributeName] = attributeValues;

  const patchData = {
    data: {
      attributes: patchAttributes,
    },
  };

  const url = endpoints.endpointForResource(type, {
    id: object.id,
  });

  await axios.patch(url, patchData);
}

async function reloadPrimaryObject(dispatch, primaryObject) {
  const resourceType = getResourceById(primaryObject.type);
  switch (resourceType.attributes.resourceType) {
    case RESOURCE_TYPES.USER:
      await dispatch(getUserAction(primaryObject.id, primaryObject.type));
      break;
    case RESOURCE_TYPES.GROUP:
      await dispatch(getGroupAction(primaryObject.id, primaryObject.type));
      break;
    default:
      await dispatch(getGenericAction(primaryObject.id, primaryObject.type));
  }
}
