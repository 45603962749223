import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logErrorToConsole } from '../../../utils/helpers';
import ErrorPage from '../ErrorPage/ErrorPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    logErrorToConsole(error);
    this.setState({ hasError: true, error, info });
  }

  render() {
    const { hasError, error, info } = this.state;

    if (hasError) {
      return <ErrorPage error={error} info={info} />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorBoundary;
