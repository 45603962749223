import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';

import { unauthenticateUser } from '../../../store/actions/authentication/authentication';
import userManager from '../../../utils/userManager';
import { logErrorToConsole } from '../../../utils/helpers';

import './Signout.css';

export class Signout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: undefined,
    };
  }

  componentDidMount() {
    document.body.classList.add('auth-page');
    this.props.unauthenticateUser();
    userManager.metadataService.getMetadata()
      .then((meta) => {
        this.setState({ url: meta.ping_end_session_endpoint });
      })
      .catch((error) => {
        logErrorToConsole(error);
      });
  }

  componentWillUnmount() {
    localStorage.removeItem('initialURL');
    document.body.classList.remove('auth-page');
  }

  static onLoadHandler() {
    userManager.removeUser()
      .catch((error) => {
        logErrorToConsole(error);
      });
  }

  render() {
    return (
      <>
        {
          this.state.url &&
          <Iframe
            url={this.state.url}
            width="100%"
            height="100%"
            onLoad={Signout.onLoadHandler}
          />
        }
      </>
    );
  }
}

Signout.propTypes = {
  unauthenticateUser: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    unauthenticateUser,
  },
)(Signout);
