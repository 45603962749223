import * as types from '../../actions/actionTypes';

export const defaultState = {
  dirty: false,
  open: false,
  valid: true,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.SET_MODAL:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
