// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// UI-Library
import Modal from 'ui-library/lib/components/general/Modal';
import UILibLink from 'ui-library/lib/components/general/Link';

// Styling
import './AlertModal.css';

export const AlertModal = ({
  cancelText,
  closeText,
  isOpen,
  modalDescription,
  modalTitle,
  onCancel,
  onClose,
  onSave,
  preventSave,
  saveText,
}) => {
  if (isOpen) {
    return (
      <div className="modal-container">
        <Modal
          flags={['use-portal']}
          type="alert"
          expanded={isOpen}
        >
          {
            modalTitle &&
            <div className="title modal-body__title"> {modalTitle} </div>
          }

          {
            modalDescription &&
            <p> {modalDescription} </p>
          }

          <div className="buttons">
            {
              onCancel &&
              <button className="cancel" onClick={onCancel}>
                {cancelText}
              </button>
            }

            <button className="primary" onClick={onSave} disabled={preventSave}>
              {saveText}
            </button>

            <br />
            <br />

            {
              onClose &&
              <UILibLink
                type="block"
                title={closeText}
                className="close"
                onClick={onClose}
                onKeyPress={onClose}
              />
            }
          </div>
        </Modal>
      </div>
    );
  }

  return null;
};

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.string,
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  saveText: PropTypes.string,
  onClose: PropTypes.func,
  closeText: PropTypes.string,
  preventSave: PropTypes.bool,
};

AlertModal.defaultProps = {
  cancelText: '',
  closeText: '',
  isOpen: false,
  modalDescription: '',
  modalTitle: '',
  onCancel: undefined,
  onClose: undefined,
  preventSave: false,
  saveText: '',
};

export default AlertModal;
