import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import axios from 'axios';
import axiosInstance from '../../../utils/axiosInstance';
import endpoints from '../../../utils/apiEndpoints';
import * as types from '../actionTypes';
import {
  createAction,
  CANCEL,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../index';
import {
  createDownloadFile,
  dispatchErrorMessage,
  dispatchSuccessMessage,
  generateHttpErrorMessage,
  generateScimFilter,
  getNonBlacklistedSelectedFieldsAttributes,
  logErrorToConsole,
  setFilterTypeAttrs,
} from '../../../utils/helpers';

export const reportActions = {
  request: () => createAction(types.REPORT_RESULTS[REQUEST]),
  success: data => createAction(types.REPORT_RESULTS[SUCCESS], { data }),
  failure: data => createAction(types.REPORT_RESULTS[FAILURE], { data }),
  downloadRequest: () => createAction(types.REPORT_DOWNLOAD[REQUEST]),
  downloadSuccess: () => createAction(types.REPORT_DOWNLOAD[SUCCESS]),
  downloadFailure: error => createAction(types.REPORT_DOWNLOAD[FAILURE], { error }),
  uploadCancel: () => createAction(types.REPORT_UPLOAD[CANCEL]),
  uploadRequest: () => createAction(types.REPORT_UPLOAD[REQUEST]),
  uploadSuccess: () => createAction(types.REPORT_UPLOAD[SUCCESS]),
  uploadFailure: error => createAction(types.REPORT_UPLOAD[FAILURE], { error }),
};

let ajaxRequest = null;
let importAjaxRequest = null;

export function getReportResultsAction(options = {}) {
  return async (dispatch, getState) => {
    const resourceEndpoint = _.get(getState(), 'resourceTypes.selected.attributes.resourceEndpoint');
    const filters = _.get(getState(), 'report.filters');
    const groupId = _.get(options, 'selectedOptions.groupId', undefined);
    const resourceId = _.get(options, 'selectedOptions.resourceId', undefined);
    const selectedFieldsAttrs = _.get(options, 'selectedFieldsAttrs', undefined);
    const filterType = _.get(options, 'filterType', undefined);
    const sort = _.get(options, 'sort', undefined);

    let attributes = _.get(getState(), 'resourceTypes.selected.attributes.attributes');
    attributes = getNonBlacklistedSelectedFieldsAttributes(attributes);
    let attributesKeys = selectedFieldsAttrs || Object.keys(attributes);
    let scimFilter;
    let sortOrder;

    if (groupId) {
      if (resourceId) {
        const search = _.get(getState(), 'search');
        const groupAttributes = search.meta.included[resourceId].attributes.attributes;
        const nonBlackListedGroupAttrs = getNonBlacklistedSelectedFieldsAttributes(groupAttributes);
        attributes = nonBlackListedGroupAttrs;
        attributesKeys = selectedFieldsAttrs || Object.keys(nonBlackListedGroupAttrs);
      }
    }

    if (options.clearReportResults) {
      await dispatch({ type: types.CLEAR_REPORT_RESULTS });
    } else {
      await dispatch({ type: types.CLEAR_REPORT });
    }

    const params = {
      attributes: attributesKeys.join(','),
    };

    if (filterType) {
      const scim = generateScimFilter(filterType);
      params.scimFilter = scim;
      scimFilter = scim;
    }

    if (sort) {
      const { attr, ascending } = sort;
      const sortOrderAttr = `${ascending ? '+' : '-'}${attr}`;
      params.scimFilter = filters.scimFilter;
      params.sortOrder = sortOrderAttr;
      // eslint-disable-next-line prefer-destructuring
      scimFilter = filters.scimFilter;
      sortOrder = sortOrderAttr;
    }

    dispatch({
      type: types.SET_REPORT_FILTERS,
      data: {
        filters: {
          attributes,
          filterTypeAttrs: setFilterTypeAttrs(attributes),
          selectedFieldsAttrs: attributesKeys,
          scimFilter,
        },
        sortOrder,
      },
    });
    dispatch(reportActions.request());

    // cancel  previous ajax if exists
    if (ajaxRequest) {
      ajaxRequest.cancel();
    }

    // creates a new token for upcomming ajax (overwrite the previous one)
    ajaxRequest = axios.CancelToken.source();

    const endpoint = endpoints
      .endpointForResource(resourceEndpoint, {
        groupId,
        resourceId,
        report_preview: true,
      });

    const res = await axiosInstance.get(endpoint, {
      cancelToken: ajaxRequest.token,
      params,
    }).catch((error) => {
      if (axios.isCancel(error)) {
        // handle error
      } else {
        logErrorToConsole(error);
        const errorMessage = error.response.data.errors[0].title;
        dispatch(reportActions.failure({ error: errorMessage }));
      }
    });

    if (res) {
      dispatch(reportActions.success({
        results: res.data.data,
        selected: {
          resourceEndpoint,
          groupId,
          resourceId,
        },
      }));
    }
  };
}

export function generateReportAction() {
  return async (dispatch, getState) => {
    const { filters, selected, sortOrder } = _.get(getState(), 'report');
    const { resourceEndpoint, groupId, resourceId } = selected;

    try {
      const endpoint = endpoints.endpointForResource(
        resourceEndpoint, { groupId, resourceId, report: true },
      );
      const params = {
        attributes: filters.selectedFieldsAttrs.join(','),
      };

      if (filters.scimFilter) {
        params.scimFilter = filters.scimFilter;
      }

      if (sortOrder) {
        params.sortOrder = sortOrder;
      }

      dispatch(reportActions.downloadRequest());

      const response = await axiosInstance
        .get(endpoint, { params })
        .then(res => createDownloadFile(res, 'report.csv'));
      dispatch(reportActions.downloadSuccess());
      return Promise.resolve(response);
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatch(reportActions.downloadFailure(intlKey));

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );

      return Promise.reject(error);
    }
  };
}

export function importReportAction(file, options = {}) {
  return async (dispatch, getState) => {
    let { resourceEndpoint } = _.get(getState(), 'resourceTypes.create.selected.attributes');
    const relationshipOptions = _.get(options, 'relationshipOptions', undefined);
    const groups = _.get(options, 'groups', undefined);
    const cancelRequest = _.get(options, 'cancelRequest', false);
    const formData = new FormData();
    formData.append('file', file);

    if (groups) {
      resourceEndpoint = _.get(getState(), 'resourceTypes.create.secondarySelected.attributes.resourceEndpoint', undefined);
    }

    const endpoint =
        endpoints.endpointForResource(resourceEndpoint, { relationshipOptions, import: true });

    dispatch(reportActions.uploadRequest());

    let res;

    if (importAjaxRequest && cancelRequest) {
      // cancel  previous ajax if exists
      importAjaxRequest.cancel();
      dispatch(reportActions.uploadCancel());
    } else {
      // creates a new token
      importAjaxRequest = axios.CancelToken.source();

      res = await axiosInstance.post(endpoint, formData, {
        cancelToken: importAjaxRequest.token,
      }).catch((error) => {
        let e = error;
        importAjaxRequest = null;
        if (axios.isCancel(error)) {
          // handle error
        } else {
          logErrorToConsole(error);
          const statusCode = _.get(error, 'response.status', 0);
          let intlKey = 'report.upload.error.default';
          let errorTitle;

          if (statusCode === 400) {
            intlKey = 'report.upload.error.title';
            errorTitle = _.get(error, 'response.data.errors[0].title', undefined);
          }

          if (statusCode === 422) {
            intlKey = 'report.upload.error.file';
            e = createDownloadFile(error.response, 'report-reject.csv');
          }

          dispatch(reportActions.uploadFailure({
            intlKey,
            errorTitle,
          }));

          dispatch({
            type: types.MODEL_FORM_ERROR,
            data: {
              error: intlKey,
            },
          });

          dispatchErrorMessage(
            dispatch,
            <FormattedMessage
              id={intlKey}
              values={{
                errorTitle,
              }}
            />,
          );
        }
        return Promise.reject(e);
      });

      if (res) {
        dispatch({ type: types.MODEL_FORM_CLEAR });
        dispatch(reportActions.uploadSuccess());
        dispatchSuccessMessage(
          dispatch,
          <FormattedMessage
            id="report.upload.success.file"
            values={{
              fileName: file.name,
            }}
          />,
        );
        importAjaxRequest = null;
      }
    }
    return Promise.resolve(res);
  };
}

export function clearReportAction() {
  return async (dispatch) => {
    dispatch({
      type: types.CLEAR_REPORT,
    });
  };
}

export function clearReportResultsAction() {
  return async (dispatch) => {
    dispatch({
      type: types.CLEAR_REPORT_RESULTS,
    });
  };
}

export default getReportResultsAction;
