import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import className from 'classnames';
import DetailsTooltip from 'ui-library/lib/components/tooltips/DetailsTooltip';
import SelectionFilterLabel from 'ui-library/lib/components/forms/SelectionFilterLabel';
import SelectionList from 'ui-library/lib/components/forms/selection-list/index';
import intlShape from '../../../../utils/intlPropType';
import './DropDownMultiSelectionList.css';

export class DropDownMultiSelectionList extends Component {
  state = {
    selectedItemIds: [],
  };

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    if (!_.isEqual(prevProps.items, items)) {
      this.handleSelectAll();
    }
  }

  handleChange = (selectedItemIds) => {
    const { handleDropDownMultiChange } = this.props;
    this.setState({ selectedItemIds });
    handleDropDownMultiChange(selectedItemIds);
  };

  handleSelectAll = () => {
    const { items, handleDropDownMultiChange } = this.props;
    const selectedItemIds = items.map(item => item.id);
    this.setState({ selectedItemIds });
    handleDropDownMultiChange(selectedItemIds);
  };


  render() {
    const {
      error,
      label,
      intl,
      items,
      searchPlaceholder,
    } = this.props;
    const LABELS = {
      labelOnlySelected: intl.formatMessage({ id: 'components.drop-down-multi.labels.onlySelected' }),
      labelSelectAll: intl.formatMessage({ id: 'components.drop-down-multi.labels.selectAll' }),
      labelShowAll: intl.formatMessage({ id: 'components.drop-down-multi.labels.showAll' }),
      labelUnselectAll: intl.formatMessage({ id: 'components.drop-down-multi.labels.unselectAll' }),
    };
    const { selectedItemIds } = this.state;
    const labelText = label ? intl.formatMessage({ id: label }) : '';
    const count = selectedItemIds.length;

    return (
      <div>
        {
          label &&
          <h4 className="dropdown-multi-selection__label label-text">{labelText}</h4>
        }
        <DetailsTooltip
          className={className(
            { 'dropdown-multi-selection__error': !_.isUndefined(error) },
          )}
          label={(
            <SelectionFilterLabel
              filterLabel={intl.formatMessage({ id: 'common.selected' })}
              count={count}
            />
          )}
          onToggle={() => {}}
          placement="bottom right"
          showClose={false}
          type="selection-list"
        >
          <SelectionList
            items={items}
            name="multi-select"
            onSelectAll={this.handleSelectAll}
            onValueChange={this.handleChange}
            searchPlaceholder={intl.formatMessage({ id: searchPlaceholder })}
            selectedItemIds={selectedItemIds}
            showSearchBox
            showSelectionOptions
            type={SelectionList.ListType.MULTI}
            {...LABELS}
          />
        </DetailsTooltip>
        {
          !_.isUndefined(error) &&
          <div className="input-message">
            <div className="input-message__text">
              <FormattedMessage id={error} />
            </div>
          </div>
        }
      </div>
    );
  }
}

DropDownMultiSelectionList.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  handleDropDownMultiChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  searchPlaceholder: PropTypes.string,
};

DropDownMultiSelectionList.defaultProps = {
  error: undefined,
  label: '',
  searchPlaceholder: 'common.searchPlaceholder',
};

export default injectIntl((DropDownMultiSelectionList));

