import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore, InMemoryWebStorage } from 'oidc-client';
import _ from 'lodash';

const {
  PF_HOST,
  PF_PORT,
  DADMIN_CLIENT_ID,
  AUTHENTICATE_WITH_PKCE,
  location,
} = window;
const PROFILE_SCOPE_ENABLED = _.get(window, 'PD_DADMIN_CONFIG.PROFILE_SCOPE_ENABLED', false);
const currentPath = `${location.origin}${location.pathname}`;
export const authEndpoint = `https://${PF_HOST}${PF_PORT ? `:${PF_PORT}` : ''}`;

const scopes = ['openid', 'urn:pingidentity:directory-delegated-admin', 'profile'];
const joinedScopes = scopes
  .filter(scope => scope !== 'profile' || !!PROFILE_SCOPE_ENABLED)
  .join(' ');

const responseType = AUTHENTICATE_WITH_PKCE ? 'code' : 'token id_token';

const userManagerConfig = {
  client_id: DADMIN_CLIENT_ID,
  redirect_uri: `${currentPath}#/callback`,
  response_type: responseType,
  scope: joinedScopes,
  authority: authEndpoint,
  silent_redirect_uri: `${currentPath}silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  metadata: {
    authorization_endpoint: `${authEndpoint}/as/authorization.oauth2`,
    token_endpoint: `${authEndpoint}/as/token.oauth2`,
    issuer: authEndpoint,
    jwks_uri: `${authEndpoint}/pf/JWKS`,
    ping_end_session_endpoint: `${authEndpoint}/idp/startSLO.ping`,
    userinfo_endpoint: `${authEndpoint}/idp/userinfo.openid`,
  },
};

if (process.env.NODE_ENV === 'test') {
  const stateStoreStorage = new InMemoryWebStorage();
  const userStoreStorage = new InMemoryWebStorage();

  userManagerConfig.stateStore = new WebStorageStateStore({ store: stateStoreStorage });
  userManagerConfig.userStore = new WebStorageStateStore({ store: userStoreStorage });
}

const userManager = createUserManager(userManagerConfig);

export default userManager;
