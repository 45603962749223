import React from 'react';
import PropTypes from 'prop-types';
import AttributeCategoryItem from '../AttributeCategoryItem/AttributeCategoryItem';
import ColumnLayout from '../../layout/ColumnLayout/ColumnLayout';

export const AttributeCategoryList = ({
  categoryObjects,
  attributes,
}) => (
  <ColumnLayout>
    {
      categoryObjects
        .filter(category => category.attributes.length)
        .map(category => (
          <AttributeCategoryItem
            key={category.name}
            category={category}
            attributes={attributes}
          />
        ))
    }
  </ColumnLayout>
);

AttributeCategoryList.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  categoryObjects: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      attributes: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
  ).isRequired,
};

export default AttributeCategoryList;
