import CustomError from './CustomError';

class AuthCallbackError extends CustomError {
  constructor(errorObj = {}) {
    super(errorObj.message);
    this.name = this.constructor.name;
    this.intlKey = 'custom-errors.auth-callback-error';
  }
}

export default AuthCallbackError;
