import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import LeftNavBarActions from 'ui-library/lib/components/panels/left-nav/Actions';
import { generatePath } from 'react-router';
import endpoints from '../../../utils/apiEndpoints';
import * as types from '../actionTypes';
import * as routes from '../../../utils/routes';
import {
  dispatchSuccessMessage,
  dispatchErrorMessage,
  generateHttpErrorMessage,
  generateAttributeErrors,
  logErrorToConsole,
} from '../../../utils/helpers';
import axios from '../../../utils/axiosInstance';
import { getResourceById } from '../../../utils/resourceTypes';
import { createAction, FAILURE, REQUEST, SUCCESS } from '../index';
import { MODEL_TYPES } from '../../../utils/modelTypes';
import generateModelFormAttributesPayload from '../../../utils/storeHelpers';
import { getUserAction } from '../userActions';
import { getGroupAction } from '../groups/groups';

export const genericsActions = {
  createGenericRequest: data => createAction(types.CREATE_GENERIC[REQUEST], { data }),
  createGenericSuccess: data => createAction(types.CREATE_GENERIC[SUCCESS], { data }),
  createGenericFailure: data => createAction(types.CREATE_GENERIC[FAILURE], { data }),
  getGenericRequest: data => createAction(types.GET_GENERIC[REQUEST], { data }),
  getGenericSuccess: data => createAction(types.GET_GENERIC[SUCCESS], { data }),
  getGenericFailure: data => createAction(types.GET_GENERIC[FAILURE], { data }),
  updateGenericRequest: data => createAction(types.UPDATE_GENERIC[REQUEST], { data }),
  updateGenericSuccess: data => createAction(types.UPDATE_GENERIC[SUCCESS], { data }),
  updateGenericFailure: data => createAction(types.UPDATE_GENERIC[FAILURE], { data }),
};

export function getGenericAction(id, type) {
  return async (dispatch) => {
    try {
      const endpoint = endpoints.endpointForResource(type, { id });

      dispatch(genericsActions.getGenericRequest({ type, id, endpoint }));
      const res = await axios.get(endpoint);

      dispatch(genericsActions.getGenericSuccess({
        generic: res.data.data,
        included: res.data.included,
        meta: res.data.meta,
      }));
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatch(genericsActions.getGenericFailure({ error: intlKey }));

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );
    }
  };
}

/**
 * @param {object} attributes contains data for all attributes
 * @param {object} relationshipOptions contains data values for selected relationship options
 * e.g. {
 *   genericDropdown: {
 *     id: '',
 *     label: '',
 *     type: '',
 *     value: ''
 *   },
 *   groupDropdown: {
 *     id: '',
 *     label: '',
 *     type: '',
 *     value: ''
 *   }
 * }
 * @param {boolean} skipMessage whether the global app message should be skipped
 * @returns {Promise} resolved or rejected promise
 */
export function createGenericAction(
  attributes,
  relationshipOptions,
  skipMessage = false,
) {
  return async (dispatch, getState) => {
    try {
      const selected = _.get(getState(), 'resourceTypes.create.selected');
      const resourceId = _.get(selected, 'id');
      const genericResource = getResourceById(resourceId);
      const endpoint = endpoints.endpointForResource(resourceId);
      const payload = generateModelFormAttributesPayload(attributes, relationshipOptions);

      dispatch(genericsActions.createGenericRequest({ payload }));

      const res = await axios.post(endpoint, {
        data: payload,
      });

      dispatch(genericsActions.createGenericSuccess({ generic: res.data.data }));

      // Set the left nav bar to not be on "Create Generic" item
      dispatch(LeftNavBarActions.selectItem(generatePath(routes.GENERICS_SEARCH_ROUTE, {
        model: MODEL_TYPES.GENERICS,
        type: _.get(genericResource, 'id'),
      })));

      if (!skipMessage) {
        dispatchSuccessMessage(
          dispatch,
          <FormattedMessage
            id="actions.create.success"
            values={{
              resourceType: _.get(genericResource, 'attributes.displayName'),
            }}
          />,
        );
      }

      return Promise.resolve(res);
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;
      const attributeErrors = generateAttributeErrors(error);

      dispatch(genericsActions.createGenericFailure({
        key: intlKey,
        attributeErrors,
      }));

      dispatch({
        type: types.MODEL_FORM_ERROR,
        data: {
          error: intlKey,
        },
      });

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );

      return Promise.reject(error);
    }
  };
}

export function editGenericAction(
  id,
  updatedAttributes,
  skipMessage = false,
) {
  return async (dispatch, getState) => {
    try {
      let extraInfo;
      const resourceId = _.get(getState(), 'resourceTypes.selected.id');
      const genericResource = getResourceById(resourceId);
      const endpoint = endpoints.endpointForResource(resourceId, { id });

      const payload = {
        attributes: {
          ...updatedAttributes,
        },
      };
      dispatch(genericsActions.updateGenericRequest(payload));
      const res = await axios.patch(endpoint, {
        data: payload,
      });

      const resData = _.get(res, 'data.data');
      const resId = resData.id;
      const resAttributes = resData.attributes;

      if (_.isUndefined(resAttributes) || _.isUndefined(resId)) throw new Error();

      if (resId !== id) {
        extraInfo = { newId: resId };
      }

      const data = {
        id,
        updatedAttributes: resAttributes,
        ...extraInfo,
      };
      dispatch(genericsActions.updateGenericSuccess(data));

      if (!skipMessage) {
        dispatchSuccessMessage(
          dispatch,
          <FormattedMessage
            id="actions.update.success"
            values={{
              resourceType: _.get(genericResource, 'attributes.displayName'),
            }}
          />,
        );
      }

      return Promise.resolve(res);
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;
      const attributeErrors = generateAttributeErrors(error);

      dispatch(genericsActions.updateGenericFailure({
        error: intlKey,
        attributeErrors,
      }));

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );

      return Promise.reject(error);
    }
  };
}

export function deleteGenericAction(id, type) {
  return async (dispatch, getState) => {
    try {
      const selected = _.get(getState(), 'resourceTypes.selected');
      const resourceId = _.get(selected, 'id');
      const genericResource = getResourceById(resourceId);
      const endpoint = endpoints.endpointForResource(type, { id });
      await axios.delete(endpoint);

      dispatchSuccessMessage(
        dispatch,
        <FormattedMessage
          id="actions.delete.success"
          values={{
            resourceType: _.get(genericResource, 'attributes.displayName'),
          }}
        />,
      );
      return 'success';
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );
      return 'error';
    }
  };
}

export function deleteCorrelatedGenericAction(id, type, displayName, pageModel, model) {
  return async (dispatch) => {
    try {
      const endpoint = endpoints.endpointForResource(type, { id });
      await axios.delete(endpoint);

      // Updating the store for correlated resource users linked to the generics or groups type
      if (pageModel === 'users') {
        await dispatch(getUserAction(model.id, model.type));
      } else {
        await dispatch(getGroupAction(model.id, model.type));
      }

      dispatchSuccessMessage(
        dispatch,
        <FormattedMessage
          id="actions.delete.success"
          values={{
            resourceType: displayName,
          }}
        />,
      );
      return 'success';
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );
      return 'error';
    }
  };
}
