import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import MessagesReducer from 'ui-library/lib/components/general/messages/MessagesReducer';
import LeftNavBarReducer from 'ui-library/lib/components/panels/left-nav/Reducer';
import { Reducer as MultiDragReducer } from 'ui-library/lib/components/panels/multi-drag';
import { reducer as OidcReducer } from 'redux-oidc';

import authentication from './authentication/authentication';
import groupsReducer from './groups/groups';
import reportReducer from './report/report';
import searchReducer from './searchReducer';
import userReducer from './userReducer';
import resourceTypes from './resourceTypes/resourceTypes';
import modal from './modal/modal';
import modelForm from './modelForm/modelFormReducer';
import generic from './generics';

const rootReducer = combineReducers({
  authentication,
  drag: MultiDragReducer,
  generic,
  group: groupsReducer,
  intl: intlReducer,
  leftNavBar: LeftNavBarReducer,
  messageContainer: MessagesReducer,
  modal,
  modelForm,
  oidc: OidcReducer,
  report: reportReducer,
  resourceTypes,
  search: searchReducer,
  user: userReducer,
});

export default rootReducer;
