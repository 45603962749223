import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import permissions, { PROP_PERMISSIONS } from '../../../../utils/permissions/permissions';
import { NOT_FOUND_ROUTE } from '../../../../utils/routes';
import { PROP_RESOURCE_TYPES } from '../../../../utils/resourceTypes';

export const LOGIC_OPERATORS = {
  AND: 'AND',
  OR: 'OR',
  NOT: 'NOT',
};

const Permission = ({
  children,
  requiredPermissions,
  resourceId,
  resourceType,
  redirect,
  permissionsLogic,
}) => {
  function checkPermission() {
    function checkAND() {
      if (resourceId) {
        return _.difference(requiredPermissions, permissions.permissionsFor(resourceId))
          .length === 0;
      } else if (resourceType) {
        return requiredPermissions
          .every(permission => permissions.hasPermissionForType(permission, resourceType));
      }
      return false;
    }

    function checkOR() {
      if (resourceId) {
        return permissions
          .permissionsFor(resourceId)
          .some(permission => requiredPermissions.includes(permission));
      } else if (resourceType) {
        return requiredPermissions
          .some(permission => permissions.hasPermissionForType(permission, resourceType));
      }
      return false;
    }

    switch (permissionsLogic) {
      case LOGIC_OPERATORS.AND: {
        // ALL required permissions exist
        return checkAND();
      }
      case LOGIC_OPERATORS.OR: {
        // AT LEAST ONE required permission exists
        return checkOR();
      }
      case LOGIC_OPERATORS.NOT: {
        // ZERO required permissions exist
        return !checkAND();
      }
      default:
        return false;
    }
  }

  const canRender = checkPermission();

  if (!canRender && redirect) return <Redirect to={NOT_FOUND_ROUTE} />;
  if (!canRender) return null;

  return (
    <>
      {children}
    </>
  );
};

Permission.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  requiredPermissions: PropTypes.arrayOf(PropTypes.oneOf(PROP_PERMISSIONS)).isRequired,
  resourceId: PropTypes.string,
  resourceType: PropTypes.oneOf(PROP_RESOURCE_TYPES),
  redirect: PropTypes.bool,
  permissionsLogic: PropTypes.oneOf(Object.values(LOGIC_OPERATORS)),
};

Permission.defaultProps = {
  redirect: true,
  resourceId: undefined,
  resourceType: undefined,
  permissionsLogic: 'AND',
};

export default Permission;
