import React, { Component } from 'react';

export class ErrorPage extends Component {
  componentDidMount() {
    document.body.classList.add('auth-page');
  }

  componentWillUnmount() {
    document.body.classList.remove('auth-page');
  }

  render() {
    // Don't bother internationalizing in case that is what caused the crash
    return (
      <div className="ErrorPage">
        <div className="centered-info-box">
          <h1>
            Uh oh! This is embarrassing...
          </h1>

          <p>
            The Delegated Admin application has experienced an application error.
            Please contact your server administrator and notify them about this.
          </p>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
