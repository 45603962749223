/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

const SearchDropdownItem = (props) => {
  const {
    handleOnClick,
    option,
  } = props;
  const {
    label,
    subLabel1,
    subLabel2,
  } = option;
  return (
    <>
      <li
        className="select-option"
        key={`option-${label}-cn`}
        onClick={() => handleOnClick(option)}
      >
        {
          label &&
          <div><span>{label}</span></div>
        }
        <div className="reference__results-sublabel">
          {
            subLabel1 &&
            <span>{subLabel1}</span>
          }
          {
            subLabel1 && subLabel2 &&
            <span> | </span>
          }
          {
            subLabel2 &&
            <span>{subLabel2}</span>
          }
        </div>
      </li>
    </>
  );
};

SearchDropdownItem.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  option: PropTypes.shape({
    label: PropTypes.string,
    subLabel1: PropTypes.string,
    subLabel2: PropTypes.string,
  }).isRequired,
};

export default SearchDropdownItem;
