import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SignOnForm from '../SignOnForm/SignOnForm';
import userManager from '../../../utils/userManager';
import { oidcConnectionFailure, unauthenticateUser } from '../../../store/actions/authentication/authentication';

import './SignOnPage.css';
import * as routes from '../../../utils/routes';

export class SignOnPage extends Component {
  constructor(props) {
    super(props);

    this.handleSignOnButtonClick = this.handleSignOnButtonClick.bind(this);
    this.authenticateRedirect = this.authenticateRedirect.bind(this);
  }

  componentDidMount() {
    const { authenticationError, resourceTypesError } = this.props;

    if (authenticationError || resourceTypesError) {
      document.body.classList.add('auth-page');
    } else {
      this.authenticateRedirect();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('auth-page');
  }

  async handleSignOnButtonClick() {
    const { existingUser } = this.props;
    document.body.classList.remove('auth-page');

    if (existingUser) {
      this.props.history.replace(routes.SIGNOUT_ROUTE);
    } else {
      await this.props.unauthenticateUser();
      this.authenticateRedirect();
    }
  }

  authenticateRedirect() {
    userManager.signinRedirect()
      .catch((e) => {
        this.props.oidcConnectionFailure(e);
      });
  }

  render() {
    const { authenticationError, resourceTypesError } = this.props;

    return (
      <div className="SignOnPage">
        <SignOnForm
          error={authenticationError || resourceTypesError}
          handleSignOnRedirect={this.handleSignOnButtonClick}
        />
      </div>
    );
  }
}

SignOnPage.propTypes = {
  oidcConnectionFailure: PropTypes.func.isRequired,
  unauthenticateUser: PropTypes.func.isRequired,
  authenticationError: PropTypes.string,
  existingUser: PropTypes.bool,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  resourceTypesError: PropTypes.string,
};

SignOnPage.defaultProps = {
  authenticationError: undefined,
  existingUser: false,
  resourceTypesError: undefined,
};

function mapStateToProps(state) {
  return {
    existingUser: state.oidc.user !== null && !state.oidc.user.expired,
    authenticationError: state.authentication.error,
    resourceTypesError: state.resourceTypes.error,
  };
}

export default connect(mapStateToProps, {
  oidcConnectionFailure,
  unauthenticateUser,
})(SignOnPage);
