import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import InlineMessage, { MessageTypes } from 'ui-library/lib/components/general/InlineMessage';
import PageSpinner from 'ui-library/lib/components/general/PageSpinner';

import './SignOnForm.css';

const SignOnForm = ({ error, handleSignOnRedirect }) => (
  <Fragment>
    {error ? (
      <div className="centered-info-box">
        <InlineMessage type={MessageTypes.ERROR}>
          <FormattedMessage id={error} />
        </InlineMessage>
        <button className="SubmitBtn primary" onClick={handleSignOnRedirect}>
          <FormattedMessage id="auth.retry-sign-on" />
        </button>
      </div>
    ) : (
      <PageSpinner show>
        <FormattedMessage id="auth.redirect-message" />
      </PageSpinner>
    )}
  </Fragment>
);

SignOnForm.propTypes = {
  error: PropTypes.string,
  handleSignOnRedirect: PropTypes.func.isRequired,
};

SignOnForm.defaultProps = {
  error: undefined,
};

export default SignOnForm;
