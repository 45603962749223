import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Toggle from 'ui-library/lib/components/forms/form-toggle';
import HelpHint from 'ui-library/lib/components/tooltips/HelpHint';

class EnableUserToggle extends Component {
  constructor(props) {
    super(props);

    this.statusIntlString = this.statusIntlString.bind(this);
    this.enableOrDisableUser = this.enableOrDisableUser.bind(this);
  }

  statusIntlString() {
    const { isLocked, isDisabled } = this.props;

    if (isLocked) {
      return 'user.status.locked';
    } else if (isDisabled) {
      return 'user.status.disabled';
    }

    return 'user.status.enabled';
  }

  enableOrDisableUser() {
    const {
      userId,
      isLocked,
      editUser,
    } = this.props;

    if (isLocked) {
      editUser(userId, {
        'ds-pwp-modifiable-state-json': {
          'account-is-failure-locked': false,
        },
      });
    } else {
      editUser(userId, { 'ds-pwp-account-disabled': [!this.props.isDisabled] });
    }
  }

  render() {
    const { isLocked, isDisabled } = this.props;
    const resetPasswordButton = 'components.admin-actions.reset-password-button';

    if (isLocked) {
      return (
        <div className="toggle-component">
          <span className="toggle-label">
            <FormattedMessage id={this.statusIntlString()} />
          </span>
          <HelpHint
            data-id="helphint-topplacement"
            placement="top"
            type={HelpHint.types.WARNING}
            hintText={<FormattedMessage id={`${resetPasswordButton}.buttons.unlock`} />}
          >
            <Toggle
              status={isLocked && Toggle.Status.LOCKED}
              toggled={!isDisabled && !isLocked}
              onToggle={this.enableOrDisableUser}
            />
          </HelpHint>
        </div>
      );
    }
    return (
      <div className="toggle-component">
        <span className="toggle-label">
          <FormattedMessage id={this.statusIntlString()} />
        </span>
        <Toggle
          status={isLocked && Toggle.Status.LOCKED}
          toggled={!isDisabled && !isLocked}
          onToggle={this.enableOrDisableUser}
        />
      </div>
    );
  }
}

EnableUserToggle.propTypes = {
  isLocked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  editUser: PropTypes.func.isRequired,
};

export default EnableUserToggle;
