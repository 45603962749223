import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Messages from 'ui-library/lib/components/general/messages/Messages';
import MessageActions from 'ui-library/lib/components/general/messages/MessagesActions';

export const MessageContainer = ({ messages, removeMessage, defaultMessageLayout }) => {
  const messageOptions = {};

  if (defaultMessageLayout) {
    messageOptions.defaultMessageLayout = defaultMessageLayout;
  }

  return (
    <div className="MessageContainer">
      <Messages
        messages={messages}
        onRemoveMessage={removeMessage}
        {...messageOptions}
      />
    </div>
  );
};

MessageContainer.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape()),
  removeMessage: PropTypes.func.isRequired,
  defaultMessageLayout: PropTypes.string,
};

MessageContainer.defaultProps = {
  messages: [],
  defaultMessageLayout: undefined,
};

function mapStateToProps(state) {
  return { messages: state.messageContainer.messages };
}

export default connect(
  mapStateToProps,
  {
    removeMessage: MessageActions.removeAt,
  },
)(MessageContainer);
