import _ from 'lodash';
import { getResourceTypes, getResourceById, RESOURCE_TYPES } from '../resourceTypes';

export const PERMISSIONS = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  MANAGE_GROUP_MEMBERSHIP: 'manage-group-membership',
  UPLOAD: 'upload',
  DOWNLOAD: 'download',
  REFERENCE: 'reference',
  RESET_PASSWORD: 'reset-password',
  UPDATE_PROFILE: 'update-profile',
};
export const PROP_PERMISSIONS = Object.values(PERMISSIONS);

// Useful for a particular resource
function hasPermission(permission, resourceId) {
  const resource = getResourceById(resourceId);
  const permissions = _.get(resource, 'attributes.permissions', []);
  return permissions.includes(permission);
}

function hasPermissions(permissionArray, resourceId, action) {
  const resource = getResourceById(resourceId);
  const permissions = _.get(resource, 'attributes.permissions', []);
  return permissions.length
    ? permissions[action || 'every'](permission => permissionArray.includes(permission))
    : false;
}

function getPermissions(resourceId) {
  const resource = getResourceById(resourceId);
  return _.get(resource, 'attributes.permissions', []);
}

// Useful for resources of a particular type
function hasPermissionForType(permission, resourceType) {
  const resourceTypes = getResourceTypes();
  const filteredResources = resourceTypes
    .filter(resource => resource.attributes.resourceType === resourceType);
  return filteredResources
    .some(resource => _.get(resource, 'attributes.permissions', []).includes(permission));
}

function hasPermissionsForModal(options = {}) {
  const { parentResourceType, groupResourceType } = options;
  const hasParentPermissions = (parentResourceType)
    ? hasPermissions([PERMISSIONS.READ, PERMISSIONS.REFERENCE], parentResourceType, 'some')
    : true;
  const hasGroupPermission = (groupResourceType)
    ? hasPermission(PERMISSIONS.MANAGE_GROUP_MEMBERSHIP, groupResourceType)
    : true;

  return ({
    hasPermission: (hasParentPermissions && hasGroupPermission),
    hasParentPermissions,
    hasGroupPermission,
  });
}

function getEditPermissions(resourceType) {
  const hasUpdatePermission = hasPermission(PERMISSIONS.UPDATE, resourceType);
  const hasManageGroupPermission = hasPermission(PERMISSIONS.MANAGE_GROUP_MEMBERSHIP, resourceType);
  const hasResetPasswordPermission = hasPermission(PERMISSIONS.RESET_PASSWORD, resourceType);
  const hasUpdateProfilePermission = hasPermission(PERMISSIONS.UPDATE_PROFILE, resourceType);

  return {
    hasUpdatePermission,
    hasManageGroupPermission,
    hasResetPasswordPermission,
    hasUpdateProfilePermission,
  };
}

const permissions = {
  manageUsers: () => hasPermissionForType(PERMISSIONS.READ, RESOURCE_TYPES.USER),
  manageGroups: () => hasPermissionForType(PERMISSIONS.READ, RESOURCE_TYPES.GROUP),
  manageGroupswithReferencePermission: () => hasPermissionForType(
    PERMISSIONS.REFERENCE,
    RESOURCE_TYPES.GROUP),
  manageGenerics: () => hasPermissionForType(PERMISSIONS.READ, RESOURCE_TYPES.GENERIC),
  permissionsFor: resourceId => getPermissions(resourceId), // Array: ["read", "create", ...]
  hasPermission: (permission, resourceId) => hasPermission(permission, resourceId),
  hasPermissions: (permissionArray, resourceId, action) => (
    hasPermissions(permissionArray, resourceId, action)
  ),
  hasPermissionForType: (permission, resourceType) => (
    hasPermissionForType(permission, resourceType)
  ),
  hasPermissionsForModal: options => hasPermissionsForModal(options),
  getEditPermissions: resourceType => getEditPermissions(resourceType),
};

export default permissions;
