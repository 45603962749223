import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmTooltip from 'ui-library/lib/components/tooltips/ConfirmTooltip';

const ConfirmationTooltip = (props) => {
  const {
    confirmationMessage,
    confirmationTitle,
    confirmationButtonType,
    onConfirm,
    onCancel,
    open,
  } = props;

  const [showConfirmation, setShowConfimation] = useState(open);

  const handleToggle = () => {
    onCancel();
    setShowConfimation(!showConfirmation);
  };

  const handleConfirm = () => {
    onConfirm();
    setShowConfimation(false);
  };

  const handleCancel = () => {
    onCancel();
    setShowConfimation(false);
  };

  return (
    <ConfirmTooltip
      title={confirmationTitle}
      open={showConfirmation}
      onToggle={handleToggle}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      buttonLabel="Confirm"
      cancelText="Cancel"
      buttonType={confirmationButtonType}
    >
      { confirmationMessage }
    </ConfirmTooltip>
  );
};

ConfirmationTooltip.propTypes = {
  confirmationMessage: PropTypes.string,
  confirmationTitle: PropTypes.string,
  confirmationButtonType: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

ConfirmationTooltip.defaultProps = {
  confirmationMessage: '',
  confirmationTitle: '',
  confirmationButtonType: 'primary',
};

export default ConfirmationTooltip;
