import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import HelpHint from 'ui-library/lib/components/tooltips/HelpHint';

import intlShape from '../../../../utils/intlPropType';
import { MODEL_TYPES, PROP_MODEL_TYPES } from '../../../../utils/modelTypes';
import { PERMISSIONS } from '../../../../utils/permissions/permissions';

export const ModifyMemberButton = (props) => {
  const {
    buttonProps,
    intl,
    rowProps,
    ControlButton,
    secondarySelectedPermissions,
  } = props;
  const { profileInfo } = rowProps;
  const { container, profilePageModel, target } = profileInfo;
  const isDynamicContainer = _.get(container, 'attributes.dadmin-is-dynamic-group[0]');
  const hasReadOnlyPermission =
    secondarySelectedPermissions.length === 1 &&
    secondarySelectedPermissions[0] === PERMISSIONS.READ;
  let isIndirectRelationship = false;

  if (profilePageModel !== MODEL_TYPES.GROUPS) {
    isIndirectRelationship = _.get(target, 'relationships.groups.data', [])
      .filter(group => !group.isDirectMember)
      .map(group => group.id)
      .includes(container.id);
  } else {
    isIndirectRelationship = _.get(container, 'relationships.members.data.indirectMembers', [])
      .map(member => member.id)
      .includes(target.id);
  }
  const isDisabled = isDynamicContainer || isIndirectRelationship || hasReadOnlyPermission;

  if (isDisabled) {
    let hintText;

    if (isDynamicContainer) {
      hintText = 'components.dual-column-search.shared.dynamic-group';
    } else if (isIndirectRelationship) {
      hintText = 'components.modify-member-button.indirect-member';
    } else {
      hintText = 'components.modify-member-button.insufficient-permissions';
    }

    return (
      <HelpHint
        hintText={intl.formatMessage({ id: hintText })}
        placement="left"
        type={HelpHint.types.WARNING}
      >
        <ControlButton
          {...buttonProps}
          disabled={isDisabled}
        />
      </HelpHint>
    );
  }

  return (
    <ControlButton {...buttonProps} />
  );
};

ModifyMemberButton.propTypes = {
  buttonProps: PropTypes.shape({}).isRequired,
  intl: intlShape.isRequired,
  rowProps: PropTypes.shape({
    profileInfo: PropTypes.shape({
      container: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        attributes: PropTypes.shape({}).isRequired,
        included: PropTypes.arrayOf(PropTypes.shape({})),
        relationships: PropTypes.shape({}),
      }),
      profilePageModel: PropTypes.oneOf(PROP_MODEL_TYPES).isRequired,
      target: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        attributes: PropTypes.shape({}).isRequired,
        included: PropTypes.arrayOf(PropTypes.shape({})),
        relationships: PropTypes.shape({}),
      }),
    }).isRequired,
  }).isRequired,
  ControlButton: PropTypes.elementType.isRequired,
  secondarySelectedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default injectIntl(ModifyMemberButton);
