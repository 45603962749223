import CustomError from './CustomError';

class OidcConnectionError extends CustomError {
  constructor(errorObj = {}) {
    super(errorObj.message);
    this.name = this.constructor.name;
    this.intlKey = 'oidc.connection-error';
  }
}

export default OidcConnectionError;
