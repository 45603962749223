import { getPrimarySearchAttributeKey, getTitleAttributeKey } from '../../utils/helpers';
import * as types from '../actions/actionTypes';
import { MODEL_TYPES } from '../../utils/modelTypes';
import { SUCCESS, REQUEST, FAILURE } from '../actions';

const defaultState = {
  generatedPasswords: {
    isLoading: false,
  },
  userGroups: undefined,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        newUser: {
          data: action.data.user,
        },
      };
    case types.CREATE_USER_ERROR:
      return {
        ...state,
        newUser: {
          data: {},
        },
        error: action.data.error,
      };
    case types.GET_USER: {
      const userSearchAttr = getPrimarySearchAttributeKey(action.data.meta);
      const groupTitleAttr = getTitleAttributeKey(action.data.meta.included.groups);

      return {
        ...state,
        error: undefined,
        groupTitleAttribute: groupTitleAttr,
        included: action.data.included,
        meta: action.data.meta,
        primarySearchAttribute: userSearchAttr,
        user: {
          data: action.data.user,
        },
      };
    }
    case types.GET_USER_ERROR: {
      return {
        ...state,
        error: action.data.error,
        user: {
          data: {},
        },
      };
    }
    case types.USER_UPDATED: {
      let userObj = {};
      let { id } = action.data;

      if (action.data.newId) {
        id = action.data.newId;
      }

      if (state.user) {
        userObj = {
          user: {
            ...state.user,
            data: {
              ...state.user.data,
              id,
              attributes: {
                ...action.data.updatedAttributes,
              },
            },
          },
        };

        /*
         * The response from resetting the password will typically return an empty object
         * so we need to make sure we keep most previous attributes
         */
        if (action.data.hasNewPassword) {
          userObj.user.data.attributes = {
            ...state.user.data.attributes,
            ...userObj.user.data.attributes,
          };
        }
      }

      return {
        ...state,
        ...userObj,
      };
    }
    case types.USER_UPDATED_ERROR: {
      return {
        ...state,
        error: action.data.error,
      };
    }
    case types.ADD_GROUP_MEMBER:
      if (action.data.profilePageModel === MODEL_TYPES.USERS) {
        let userGroupsObj;

        if (state.userGroups) {
          userGroupsObj = {
            ...state.userGroups,
            nonMembers: state.userGroups.nonMembers
              .filter(g => action.data.group.id !== g.id),
            members: [
              ...state.userGroups.members,
              action.data.group,
            ],
          };
        }

        return {
          ...state,
          userGroups: userGroupsObj,
          included: [
            ...state.included,
            action.data.group,
          ],
        };
      }

      return {
        ...state,
      };
    case types.ADD_GROUP_MEMBER_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    case types.REMOVE_GROUP_MEMBER: {
      if (action.data.profilePageModel === MODEL_TYPES.USERS) {
        let userGroupsObj;

        if (state.userGroups) {
          userGroupsObj = {
            ...state.userGroups,
            nonMembers: [
              action.data.group,
              ...state.userGroups.nonMembers,
            ],
            members: state.userGroups.members
              .filter(g => action.data.group.id !== g.id),
          };
        }

        return {
          ...state,
          userGroups: userGroupsObj,
          included: state.included.filter(g => action.data.group.id !== g.id),
        };
      }

      return {
        ...state,
      };
    }
    case types.REMOVE_GROUP_MEMBER_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    case types.SEARCH_RESULTS[SUCCESS]:
      if (
        action.data.subquery
        && action.data.dataType === MODEL_TYPES.GROUPS
        && action.data.profilePageModel === MODEL_TYPES.USERS
      ) {
        const includedIds = state.included.map(i => i.id);
        const resultsIds = action.data.results.map(i => i.id);
        const nonMembers = action.data.results
          .filter(g => !includedIds.includes(g.id));
        const members = state.included
          .filter(g => resultsIds.includes(g.id));

        return {
          ...state,
          userGroups: {
            ...state.userGroups,
            meta: action.data.meta,
            queryString: action.data.queryString,
            nonMembers,
            members,
            error: undefined,
          },
        };
      }

      return {
        ...state,
      };
    case types.CLEAR_SEARCH_RESULTS:
      if (action.data.subquery) {
        return {
          ...state,
          userGroups: undefined,
        };
      }

      return {
        ...state,
      };
    case types.CLEAR_NEW_USER:
      return {
        ...state,
        newUser: undefined,
      };
    case types.GENERATE_PASSWORD[REQUEST]:
      return {
        ...state,
        generatedPasswords: {
          isLoading: true,
        },
      };
    case types.GENERATE_PASSWORD[SUCCESS]:
    case types.GENERATE_PASSWORD[FAILURE]:
      return {
        ...state,
        generatedPasswords: {
          isLoading: false,
          ...action.data,
        },
      };
    case types.CLEAR_GENERATE_PASSWORD:
      return {
        ...state,
        generatedPasswords: {
          ...defaultState.generatedPasswords,
        },
      };
    default:
      return state;
  }
}
