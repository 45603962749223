import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import 'moment-jdateformatparser';
import LabelValuePairs from 'ui-library/lib/components/layout/LabelValuePairs';

import AttributeCategoryList from '../AttributeCategoryList/AttributeCategoryList';
import {
  blacklistedDisplayAttrs,
  getNonBlacklistedAttributes,
  categorizeAttributes,
  getDataPair,
} from '../../../../utils/helpers';
import intlShape from '../../../../utils/intlPropType';

export const ModelSummary = ({
  intl,
  attributes,
  blacklist,
  meta,
  showCategories,
}) => {
  function renderAttributes() {
    const filteredAttributes = getNonBlacklistedAttributes(meta.attributes, attributes, blacklist);
    const categories = meta.attributeCategories;
    const dataPairs = [];

    if (showCategories && categories && categories.length) {
      const categoryObjects = categorizeAttributes(categories, filteredAttributes, intl, true);
      return (
        <AttributeCategoryList
          categoryObjects={categoryObjects}
          attributes={attributes}
        />
      );
    }

    filteredAttributes
      .filter(([_attrName, attrMeta]) => attrMeta.includeInSummary)
      .forEach(([attrName, attrMeta]) => {
        const { dataType, dateTimeFormat } = attrMeta;
        const attrValue = (dataType === 'dateTime')
          ? attributes[attrName].map(date => moment(date).formatWithJDF(dateTimeFormat))
          : attributes[attrName];
        dataPairs.push(getDataPair(attrValue, attrMeta));
      });

    return <LabelValuePairs dataPairs={dataPairs} />;
  }

  return (
    <div className="ModelSummary">
      {renderAttributes()}
    </div>
  );
};

ModelSummary.propTypes = {
  intl: intlShape.isRequired,
  attributes: PropTypes.shape({
    'dadmin-account-locked': PropTypes.arrayOf(PropTypes.bool),
    'ds-pwp-account-disabled': PropTypes.arrayOf(PropTypes.bool),
  }).isRequired,
  blacklist: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.shape({
    attributes: PropTypes.shape({}).isRequired,
    attributeCategories: PropTypes.instanceOf(Array),
  }).isRequired,
  showCategories: PropTypes.bool,
};

ModelSummary.defaultProps = {
  blacklist: blacklistedDisplayAttrs,
  showCategories: true,
};

export default injectIntl(ModelSummary);
