import * as types from '../../actions/actionTypes';
import { REQUEST, SUCCESS, FAILURE, CANCEL } from '../../actions';

export const defaultState = {
  error: undefined,
  isFetching: false,
  download: {
    isFetching: false,
  },
  filters: {
    attributes: {},
    selectedFieldsAttrs: undefined,
    filterTypeAttrs: {},
    scimFilter: undefined,
  },
  results: {
    header: [],
    rows: [],
  },
  selected: {
    resourceEndpoint: undefined,
    groupId: undefined,
    resourceId: undefined,
  },
  sortOrder: undefined,
  upload: {
    error: undefined,
    errorTitle: undefined,
    isFetching: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.SET_REPORT_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          attributes: action.data.filters.attributes,
          filterTypeAttrs: action.data.filters.filterTypeAttrs,
          selectedFieldsAttrs: action.data.filters.selectedFieldsAttrs,
          scimFilter: action.data.filters.scimFilter,
        },
        sortOrder: action.data.sortOrder,
      };
    case types.REPORT_RESULTS[REQUEST]:
      return {
        ...state,
        error: defaultState.error,
        isFetching: true,
      };
    case types.REPORT_RESULTS[SUCCESS]:
      return {
        ...state,
        error: defaultState.error,
        isFetching: defaultState.isFetching,
        results: action.data.results || defaultState.results,
        selected: {
          resourceEndpoint: action.data.selected.resourceEndpoint,
          groupId: action.data.selected.groupId,
          resourceId: action.data.selected.resourceId,
        },
      };
    case types.REPORT_RESULTS[FAILURE]:
      return {
        ...state,
        error: action.data.error,
        isFetching: defaultState.isFetching,
        results: defaultState.results,
      };
    case types.CLEAR_REPORT:
      return {
        ...defaultState,
      };
    case types.CLEAR_REPORT_RESULTS:
      return {
        ...state,
        results: defaultState.results,
      };
    case types.REPORT_DOWNLOAD[REQUEST]:
      return {
        ...state,
        download: {
          ...state.download,
          isFetching: true,
        },
      };
    case types.REPORT_DOWNLOAD[SUCCESS]:
      return {
        ...state,
        download: {
          ...state.download,
          isFetching: false,
        },
      };
    case types.REPORT_UPLOAD[CANCEL]:
      return {
        ...state,
        upload: {
          ...state.upload,
          isFetching: false,
        },
      };
    case types.REPORT_UPLOAD[REQUEST]:
      return {
        ...state,
        upload: {
          ...state.upload,
          isFetching: true,
        },
      };
    case types.REPORT_UPLOAD[SUCCESS]:
      return {
        ...state,
        upload: {
          ...state.upload,
          isFetching: false,
        },
      };
    case types.REPORT_UPLOAD[FAILURE]:
      return {
        ...state,
        upload: {
          ...state.upload,
          error: action.error.intlKey,
          errorTitle: action.error.errorTitle,
          isFetching: false,
        },
      };
    default:
      return state;
  }
}
