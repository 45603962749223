import * as types from '../../actions/actionTypes';
import { MODEL_TYPES } from '../../../utils/modelTypes';
import { SUCCESS, FAILURE } from '../../actions';

const defaultState = {
  genericGroups: undefined,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.GET_GENERIC[SUCCESS]: {
      return {
        ...state,
        generic: {
          data: action.data.generic,
        },
        included: action.data.included,
        meta: action.data.meta,
        error: undefined,
      };
    }
    case types.GET_GENERIC[FAILURE]:
      return {
        ...state,
        generic: {
          data: {},
        },
        error: action.data.error,
      };
    case types.CREATE_GENERIC[SUCCESS]:
      return {
        ...state,
        newGeneric: {
          data: action.data.generic,
        },
      };
    case types.CREATE_GENERIC[FAILURE]:
      return {
        ...state,
        newGeneric: {
          data: {},
        },
        error: action.data.error,
      };
    case types.UPDATE_GENERIC[SUCCESS]: {
      let genericObj = {};
      let { id } = action.data;

      if (action.data.newId) {
        id = action.data.newId;
      }

      if (state.generic) {
        genericObj = {
          generic: {
            ...state.generic,
            data: {
              ...state.generic.data,
              id,
              attributes: {
                ...action.data.updatedAttributes,
              },
            },
          },
        };
      }

      return {
        ...state,
        ...genericObj,
      };
    }
    case types.UPDATE_GENERIC[FAILURE]:
      return {
        ...state,
        error: action.data.error,
      };
    case types.ADD_GROUP_MEMBER:
      if (action.data.profilePageModel === MODEL_TYPES.GENERICS) {
        let genericGroupsObj;

        if (state.genericGroups) {
          genericGroupsObj = {
            ...state.genericGroups,
            nonMembers: state.genericGroups.nonMembers
              .filter(g => action.data.group.id !== g.id),
            members: [
              ...state.genericGroups.members,
              action.data.group,
            ],
          };
        }

        return {
          ...state,
          genericGroups: genericGroupsObj,
          included: [
            ...state.included,
            action.data.group,
          ],
        };
      }

      return {
        ...state,
      };
    case types.ADD_GROUP_MEMBER_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    case types.REMOVE_GROUP_MEMBER: {
      if (action.data.profilePageModel === MODEL_TYPES.GENERICS) {
        let genericGroupsObj;

        if (state.genericGroups) {
          genericGroupsObj = {
            ...state.genericGroups,
            nonMembers: [
              action.data.group,
              ...state.genericGroups.nonMembers,
            ],
            members: state.genericGroups.members
              .filter(g => action.data.group.id !== g.id),
          };
        }

        return {
          ...state,
          genericGroups: genericGroupsObj,
          included: state.included.filter(g => action.data.group.id !== g.id),
        };
      }

      return {
        ...state,
      };
    }
    case types.REMOVE_GROUP_MEMBER_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    case types.SEARCH_RESULTS[SUCCESS]:
      if (
        action.data.subquery
        && action.data.dataType === MODEL_TYPES.GROUPS
        && action.data.profilePageModel === MODEL_TYPES.GENERICS
      ) {
        const includedIds = state.included.map(i => i.id);
        const resultsIds = action.data.results.map(i => i.id);
        const nonMembers = action.data.results
          .filter(g => !includedIds.includes(g.id));
        const members = state.included
          .filter(g => resultsIds.includes(g.id));

        return {
          ...state,
          genericGroups: {
            ...state.genericGroups,
            meta: action.data.meta,
            queryString: action.data.queryString,
            nonMembers,
            members,
            error: undefined,
          },
        };
      }

      return {
        ...state,
      };
    case types.CLEAR_SEARCH_RESULTS:
      if (action.data.subquery) {
        return {
          ...state,
          genericGroups: undefined,
        };
      }

      return {
        ...state,
      };
    default:
      return state;
  }
}
