import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Spinner from 'ui-library/lib/components/general/Spinner';
import InlineMessage from 'ui-library/lib/components/general/InlineMessage';
import { FormattedMessage } from 'react-intl';

import { checkURLResponse, convertStringToArray } from '../../../../utils/helpers';

/*
 * NOTE: Must be identical to actions defined in public/iframe.js
 * Can't share because files in public folder must be separate...
 */
export const SENT_MESSAGE_ACTIONS = {
  INITIALIZE_ATTRIBUTE: 'INITIALIZE_ATTRIBUTE',
  SET_ATTRIBUTE_DATA: 'SET_ATTRIBUTE_DATA',
  FORM_UPDATE: 'FORM_UPDATE',
};

export const RECEIVED_MESSAGE_ACTIONS = {
  IFRAME_LOADED: 'IFRAME_LOADED',
  IFRAME_INITIALIZED: 'IFRAME_INITIALIZED',
  SET_VALUE: 'SET_VALUE',
  SET_IS_VALID: 'SET_IS_VALID',
  RESIZE_IFRAME: 'RESIZE_IFRAME',
};

function customAttributePath(attribute, options = {}) {
  const resourceType = _.get(options, 'resourceType.id');

  if (resourceType) {
    return `${process.env.PUBLIC_URL}/customAttributes/${resourceType}/${attribute}.html`;
  }

  return `${process.env.PUBLIC_URL}/customAttributes/${attribute}.html`;
}

/**
 * Change single formValues into arrays. e.g. { a: 'test' } -> { a: ['test'] }
 *
 * @param {object} valuesObject - Contains attribute keys and data formValues
 * @returns {object} object of attribute names keys and data array formValues
 */
const singleValuesToArrays = valuesObject => (
  Object
    .entries(valuesObject)
    .reduce((result, [key, value]) => ({
      ...result,
      [key]: convertStringToArray(value),
    }), {})
);

class CustomAttributeFieldV2 extends Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();
    this.origin = window.location.origin;
    this.path = `${process.env.PUBLIC_URL}/customAttributes/${props.attr}.html`;

    this.handleResize = this.handleResize.bind(this);
    this.checkForTemplate = this.checkForTemplate.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.receiveMessage = this.receiveMessage.bind(this);

    this.state = {
      isLoading: true,
      templatePath: undefined,
      templateExists: false,
      isInitialized: false,
      contentHeight: 1,
    };
  }

  componentDidMount() {
    this.checkForTemplate(this.path);
  }

  componentDidUpdate(prevProps) {
    const { templateExists, isInitialized } = this.state;
    const { formValues, attributeErrors } = this.props;
    const didValsOrErrsUpdate = (
      prevProps.formValues !== formValues
      || prevProps.attributeErrors !== attributeErrors
    );

    if (templateExists) {
      window.addEventListener('message', this.receiveMessage);
    }

    if (isInitialized && didValsOrErrsUpdate) {
      this.sendMessage({
        action: SENT_MESSAGE_ACTIONS.FORM_UPDATE,
        data: {
          form: {
            attributes: singleValuesToArrays(formValues),
            errors: attributeErrors,
          },
        },
      });
    }
  }

  componentWillUnmount() {
    if (this.state.templateExists) {
      window.removeEventListener('message', this.receiveMessage);
    }
  }

  handleResize(pixels) {
    const { body, documentElement } = this.container.current.contentWindow.document;
    if (!body || !documentElement) return;
    const contentHeight = typeof pixels !== 'undefined'
      ? pixels
      : Math.max(
        body.clientHeight,
        body.offsetHeight,
        body.scrollHeight,
        documentElement.clientHeight,
        documentElement.offsetHeight,
        documentElement.scrollHeight,
      );
    if (contentHeight !== this.state.contentHeight) this.setState({ contentHeight });
  }

  /*
   * Specificity Rules: First check if a custom attribute file is defined for the resource type.
   * If one is not, then look for a more general custom attribute file. Lastly, if neither can be
   * found, define templateExists as false.
   */
  async checkForTemplate() {
    const { attr, resourceType } = this.props;
    const resourceSpecificPath = customAttributePath(attr, { resourceType });
    const generalPath = customAttributePath(attr);
    let generalFileExists;
    let templatePath;

    const resourceTypeFileExists = await checkURLResponse(resourceSpecificPath);
    if (!resourceTypeFileExists) {
      generalFileExists = await checkURLResponse(generalPath);
    }

    if (resourceTypeFileExists) {
      templatePath = resourceSpecificPath;
    } else if (generalFileExists) {
      templatePath = generalPath;
    }

    const templateExists = !!resourceTypeFileExists || !!generalFileExists;
    this.setState({ isLoading: false, templateExists, templatePath });
  }

  sendMessage(message) {
    const iframe = this.container.current;
    iframe.contentWindow.postMessage(message, this.origin);
  }

  receiveMessage(message) {
    const messageData = message && message.data;
    if (typeof messageData === 'undefined') return;

    const {
      attr,
      value,
      formValues,
      meta,
      attributeErrors,
    } = this.props;
    const { action, data, attribute } = messageData;

    if (!this.state.isInitialized) {
      if (action === RECEIVED_MESSAGE_ACTIONS.IFRAME_LOADED) {
        this.sendMessage({
          action: SENT_MESSAGE_ACTIONS.INITIALIZE_ATTRIBUTE,
          data: {
            attr,
            origin: this.origin,
          },
        });
        this.handleResize();
      }
    }

    if (attribute === attr) {
      if (action === RECEIVED_MESSAGE_ACTIONS.IFRAME_INITIALIZED) {
        this.setState({
          isInitialized: true,
        }, () => {
          this.sendMessage({
            action: SENT_MESSAGE_ACTIONS.SET_ATTRIBUTE_DATA,
            data: {
              value,
              metaData: meta,
            },
          });
          this.sendMessage({
            action: SENT_MESSAGE_ACTIONS.FORM_UPDATE,
            data: {
              form: {
                attributes: singleValuesToArrays(formValues),
                errors: attributeErrors,
              },
            },
          });
        });
      } else if (action === RECEIVED_MESSAGE_ACTIONS.SET_VALUE) {
        this.props.onValueChange(data);
      } else if (action === RECEIVED_MESSAGE_ACTIONS.SET_IS_VALID) {
        this.props.onValidationChange(data);
      } else if (action === RECEIVED_MESSAGE_ACTIONS.RESIZE_IFRAME) {
        this.handleResize(data);
      }
    }
  }

  render() {
    const { attr, meta } = this.props;
    const {
      isLoading,
      templateExists,
      templatePath,
      contentHeight,
    } = this.state;
    const template = (
      <iframe
        style={{
          width: '100%',
          height: `${contentHeight}px`,
          overflow: 'hidden',
        }}
        src={templatePath}
        title={attr}
        ref={this.container}
      />
    );

    const error = (
      <>
        <span className="label-text">{meta.displayName}</span>
        <InlineMessage type={InlineMessage.MessageTypes.ERROR}>
          <FormattedMessage id="components.editable-field.missing-template" />
        </InlineMessage>
      </>
    );

    return (
      <Spinner show={isLoading}>
        {
          templateExists
            ? template
            : error
        }
      </Spinner>
    );
  }
}

CustomAttributeFieldV2.propTypes = {
  attr: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  resourceType: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    dataType: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  formValues: PropTypes.objectOf(PropTypes).isRequired,
  attributeErrors: PropTypes.objectOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
  ),
};

CustomAttributeFieldV2.defaultProps = {
  value: undefined,
  attributeErrors: undefined,
};

export default CustomAttributeFieldV2;
