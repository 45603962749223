import _ from 'lodash';
import store from '../store';
import { getResourceById } from './resourceTypes';
import { MODEL_TYPES } from './modelTypes';

function getDynamicURL(storePath) {
  return _.get(store.getState(), storePath);
}

function getResourceURL(resourceId, options) {
  const resource = getResourceById(resourceId);
  let endpoint = _.get(resource, 'attributes.resourceEndpoint');
  if (endpoint && options.id) endpoint = `${endpoint}/${options.id}`;
  if (endpoint && options.creationGroups) endpoint = `${endpoint}/creationGroups`;
  if (endpoint && options.editMembers) endpoint = `${endpoint}/relationships/members`;
  if (endpoint && options.members) {
    endpoint = (options.groupResourceType === true) ? `${endpoint}/members?includeNonMembers=false` : `${endpoint}/members`;
  }
  if (endpoint && options.password) {
    endpoint = (options.userId) ? `${endpoint}/${options.userId}/password` : `${endpoint}/password`;
  }
  if (endpoint && options.report) {
    endpoint = (options.groupId) ? `${endpoint}/${options.groupId}/report/${options.resourceId}` : `${endpoint}/report`;
  }
  if (endpoint && options.report_preview) {
    endpoint = (options.groupId) ? `${endpoint}/${options.groupId}/report/preview/${options.resourceId}` : `${endpoint}/report/preview`;
  }
  if (endpoint && options.import) {
    const { relationshipOptions } = options;

    if (relationshipOptions) {
      let relationshipEndpoint = `${endpoint}/import?`;
      Object.entries(relationshipOptions).forEach(([key, value], index) => {
        const type = (key === MODEL_TYPES.GENERICS) ? 'orgs' : key;
        relationshipEndpoint = `${relationshipEndpoint}${(index > 0) ? '&' : ''}${type}=${value.type}/${value.id}`;
      });
      endpoint = relationshipEndpoint;
    } else {
      endpoint = `${endpoint}/import`;
    }
  }
  return endpoint;
}

export const RESOURCE_TYPES_ENDPOINT = 'resourceTypes';

const endpoints = {
  authenticatedUser: () => getDynamicURL('resourceTypes.links.authUser'),
  endpointForResource: (resourceId, options = {}) => getResourceURL(resourceId, options),
  resourceTypes: () => RESOURCE_TYPES_ENDPOINT,
};

export default endpoints;
