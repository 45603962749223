import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import LeftNavBarActions from 'ui-library/lib/components/panels/left-nav/Actions';
import endpoints from '../../../utils/apiEndpoints';
import * as types from '../actionTypes';
import { getUserAction } from '../userActions';
import {
  dispatchErrorMessage,
  dispatchSuccessMessage,
  generateHttpErrorMessage,
  generateAttributeErrors,
  logErrorToConsole,
  sortData,
} from '../../../utils/helpers';
import { MODEL_TYPES } from '../../../utils/modelTypes';
import * as routes from '../../../utils/routes';
import axios from '../../../utils/axiosInstance';
import { createAction, FAILURE, REQUEST, SUCCESS } from '../index';
import { RESOURCE_TYPES } from '../../../utils/resourceTypes';
import { getGenericAction } from '../generics';
import generateModelFormAttributesPayload from '../../../utils/storeHelpers';

export const groupsActions = {
  createGroupRequest: data => createAction(types.CREATE_GROUP[REQUEST], { data }),
  createGroupSuccess: data => createAction(types.CREATE_GROUP[SUCCESS], { data }),
  createGroupFailure: data => createAction(types.CREATE_GROUP[FAILURE], { data }),
  updateGroupRequest: data => createAction(types.UPDATE_GROUP[REQUEST], { data }),
  updateGroupSuccess: data => createAction(types.UPDATE_GROUP[SUCCESS], { data }),
  updateGroupFailure: data => createAction(types.UPDATE_GROUP[FAILURE], { data }),
};

export function getGroupAction(id, type) {
  return async (dispatch, getState) => {
    try {
      const resourceId = type || _.get(getState(), 'resourceTypes.selected.id');
      const endpoint = endpoints.endpointForResource(resourceId, { id });
      const res = await axios.get(endpoint);
      const { meta, included } = res.data;

      dispatch({
        type: types.GET_GROUP,
        data: {
          group: res.data.data,
          included,
          meta,
        },
      });
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatch({
        type: types.GET_GROUP_ERROR,
        data: {
          error: intlKey,
        },
      });

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );
    }
  };
}

/**
 * @param {object} attributes contains data for all attributes
 * @param {object} relationshipOptions contains data values for selected relationship options
 * e.g. {
 *   genericDropdown: {
 *     id: '',
 *     label: '',
 *     type: '',
 *     value: ''
 *   },
 *   groupDropdown: {
 *     id: '',
 *     label: '',
 *     type: '',
 *     value: ''
 *   }
 * }
 * @param {boolean} skipMessage whether the global app message should be skipped
 * @returns {Promise} resolved or rejected promise
 */
export function createGroupAction(
  attributes,
  relationshipOptions,
  skipMessage = false,
) {
  return async (dispatch, getState) => {
    try {
      const selected = _.get(getState(), 'resourceTypes.create.selected');
      const resourceId = _.get(selected, 'id');
      const endpoint = endpoints.endpointForResource(resourceId);
      const payload = generateModelFormAttributesPayload(attributes, relationshipOptions);

      dispatch(groupsActions.createGroupRequest({ payload }));

      const res = await axios.post(endpoint, {
        data: payload,
      });

      dispatch(groupsActions.createGroupSuccess({ group: res.data.data }));

      // Set the left nav bar to not be on "Create Group" item
      dispatch(LeftNavBarActions.selectItem(routes.GROUPS_SEARCH_ROUTE));

      if (!skipMessage) {
        dispatchSuccessMessage(
          dispatch,
          <FormattedMessage
            id="actions.create.success"
            values={{
              resourceType: _.capitalize(RESOURCE_TYPES.GROUP),
            }}
          />,
        );
      }

      return Promise.resolve(res);
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;
      const attributeErrors = generateAttributeErrors(error);

      dispatch(groupsActions.createGroupFailure({
        key: intlKey,
        attributeErrors,
      }));

      dispatch({
        type: types.MODEL_FORM_ERROR,
        data: {
          error: intlKey,
        },
      });

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );

      return Promise.reject(error);
    }
  };
}

export function editGroupAction(
  id,
  updatedAttributes,
  skipMessage = false,
) {
  return async (dispatch, getState) => {
    try {
      let extraInfo;
      const resourceId = _.get(getState(), 'resourceTypes.selected.id');
      const endpoint = endpoints.endpointForResource(resourceId, { id });

      const payload = {
        attributes: {
          ...updatedAttributes,
        },
      };
      dispatch(groupsActions.updateGroupRequest(payload));
      const res = await axios.patch(endpoint, {
        data: payload,
      });

      const resData = _.get(res, 'data.data');
      const resId = resData.id;
      const resAttributes = resData.attributes;

      if (_.isUndefined(resAttributes) || _.isUndefined(resId)) throw new Error();

      if (resId !== id) {
        extraInfo = { newId: resId };
      }

      const data = {
        id,
        updatedAttributes: resAttributes,
        ...extraInfo,
      };
      dispatch(groupsActions.updateGroupSuccess(data));

      if (!skipMessage) {
        dispatchSuccessMessage(
          dispatch,
          <FormattedMessage
            id="actions.update.success"
            values={{
              resourceType: _.capitalize(RESOURCE_TYPES.GROUP),
            }}
          />,
        );
      }

      return Promise.resolve(res);
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;
      const attributeErrors = generateAttributeErrors(error);

      dispatch(groupsActions.updateGroupFailure({
        error: intlKey,
        attributeErrors,
      }));

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );

      return Promise.reject(error);
    }
  };
}

export function deleteGroupAction(id, type) {
  return async (dispatch) => {
    try {
      const endpoint = endpoints.endpointForResource(type, { id });
      await axios.delete(endpoint);

      dispatchSuccessMessage(
        dispatch,
        <FormattedMessage
          id="actions.delete.success"
          values={{
            resourceType: _.capitalize(RESOURCE_TYPES.GROUP),
          }}
        />,
      );
      return 'success';
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );
      return 'error';
    }
  };
}

export function deleteCorrelatedGroupAction(id, type, _displayName, pageModel, model) {
  return async (dispatch) => {
    try {
      const endpoint = endpoints.endpointForResource(type, { id });
      await axios.delete(endpoint);

      // Updating the store for correlated resource users linked to the generics or groups type
      if (pageModel === 'generics') {
        await dispatch(getGenericAction(model.id, model.type));
      } else {
        await dispatch(getUserAction(model.id, model.type));
      }

      dispatchSuccessMessage(
        dispatch,
        <FormattedMessage
          id="actions.delete.success"
          values={{
            resourceType: _.capitalize(RESOURCE_TYPES.GROUP),
          }}
        />,
      );
      return 'success';
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );
      return 'error';
    }
  };
}

export function getGroupMembersAction(groupId) {
  return async (dispatch, getState) => {
    try {
      const resourceId = _.get(getState(), 'resourceTypes.selected.id');
      if (!resourceId) {
        throw Error();
      }
      const endpoint = endpoints.endpointForResource(resourceId, {
        id: groupId,
        members: true,
      });
      const res = await axios.get(endpoint);

      const { directMembers, indirectMembers } = res.data.data;
      const sort = { key: 'type', order: 'asc' };
      const allMembers = [...directMembers, ...indirectMembers];

      const members = sortData(allMembers, sort);

      dispatch({
        type: types.GET_GROUP_MEMBERS_SUCCESS,
        data: {
          groupId,
          members,
        },
      });
    } catch (error) {
      logErrorToConsole(error);
      const intlKey = 'custom-errors.get-group-members';

      dispatch({
        type: types.GET_GROUP_MEMBERS_ERROR,
        data: {
          groupId,
          error: intlKey,
        },
      });

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
        />,
      );
    }
  };
}

export function addGroupMemberAction(group, members = [], profilePageModel = MODEL_TYPES.USERS) {
  return async (dispatch) => {
    try {
      const sendData = members.map(m => (
        {
          type: m.type,
          id: m.id,
        }
      ));
      const endpoint = endpoints.endpointForResource(group.type, {
        id: group.id,
        editMembers: true,
      });
      const response = await axios.post(endpoint, {
        data: [...sendData],
      });

      dispatch({
        type: types.ADD_GROUP_MEMBER,
        data: {
          profilePageModel,
          members,
          group,
        },
      });

      dispatchSuccessMessage(
        dispatch,
        <FormattedMessage
          id="actions.member-added"
        />,
      );

      if (profilePageModel === MODEL_TYPES.GROUPS) {
        await dispatch(getGroupAction(group.id, group.type));
      } else if (profilePageModel === MODEL_TYPES.USERS && members.length === 1) {
        await dispatch(getUserAction(members[0].id, members[0].type));
      }

      return Promise.resolve(response);
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatch({
        type: types.ADD_GROUP_MEMBER_ERROR,
        data: {
          error: intlKey,
        },
      });

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );

      return Promise.reject(error);
    }
  };
}

export function removeGroupMemberAction(group, members = [], profilePageModel = MODEL_TYPES.USERS) {
  return async (dispatch) => {
    try {
      const sendData = members.map(m => (
        {
          type: m.type,
          id: m.id,
        }
      ));
      const endpoint = endpoints.endpointForResource(group.type, {
        id: group.id,
        editMembers: true,
      });
      await axios.delete(endpoint, {
        data: {
          data: [...sendData],
        },
      });

      dispatch({
        type: types.REMOVE_GROUP_MEMBER,
        data: {
          profilePageModel,
          members,
          group,
        },
      });

      dispatchSuccessMessage(
        dispatch,
        <FormattedMessage
          id="actions.member-removed"
        />,
      );

      if (profilePageModel === MODEL_TYPES.GROUPS) {
        await dispatch(getGroupAction(group.id, group.type));
      } else if (profilePageModel === MODEL_TYPES.USERS && members.length === 1) {
        await dispatch(getUserAction(members[0].id, members[0].type));
      } else if (profilePageModel === MODEL_TYPES.GENERICS && members.length === 1) {
        await dispatch(getGenericAction(members[0].id, members[0].type));
      }
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatch({
        type: types.REMOVE_GROUP_MEMBER_ERROR,
        data: {
          error: intlKey,
        },
      });

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );
    }
  };
}
