import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Spinner from 'ui-library/lib/components/general/Spinner';

import AdminActions from '../../shared/admin-actions/AdminActions/AdminActions';
import {
  getUserAction,
  editUserAction,
  clearNewUserAction,
} from '../../../store/actions/userActions';
import { unlinkObjects, linkObjects } from '../../../store/actions/correlatedResources/correlatedResources';
import { getSearchResults } from '../../../store/actions/search/search';
import EditModelPage from '../../shared/pages/EditModelPage/EditModelPage';
import { MODEL_TYPES } from '../../../utils/modelTypes';
import { getTitleOrPrimaryAttributeValue } from '../../../utils/helpers';
import * as routes from '../../../utils/routes';
import permissions from '../../../utils/permissions/permissions';

import './UserProfilePage.css';

export class UserProfilePage extends Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.handleUnlinkObjects = this.handleUnlinkObjects.bind(this);
    this.handleLinkObjects = this.handleLinkObjects.bind(this);
    this.getUser = this.getUser.bind(this);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getUser();
  }

  async getUser() {
    const { id, type } = this.props.match.params;

    try {
      await this.props.getUserAction(id, type);
      this.setState({ isLoading: false });
    } catch (e) {
      // Errors caught in actions
    }
  }

  handleUserEdit(...args) {
    this.props.editUserAction(args);
  }

  async handleUnlinkObjects(correlationCfg, secondaryObject) {
    const { user } = this.props;
    await this.props.unlinkObjects(correlationCfg, user, secondaryObject);
  }

  async handleLinkObjects(correlationCfg, docToBeLinked) {
    const { user } = this.props;
    await this.props.linkObjects(correlationCfg, user, docToBeLinked);
  }

  handleSearch(queryString = '', options = {}) {
    this.props.getSearchResults(queryString, {
      ...options,
    });
  }

  handleClearSearch(options = {}) {
    this.handleSearch('', {
      skipMessage: true,
      hideSpinner: true,
      ...options,
    });
  }

  render() {
    const {
      user,
      meta,
      userGroups,
      error,
    } = this.props;
    const {
      isLoading,
    } = this.state;
    let userName;
    let pageHeaderChildren;
    let canUpdate;
    let canManage;
    let showEnableToggle;
    let canManageWithReference;
    let canManageGroups = false;
    let showResetPassword;

    if (user) {
      const {
        hasUpdatePermission,
        hasResetPasswordPermission,
        hasUpdateProfilePermission,
      } = permissions.getEditPermissions(user.type);

      canUpdate = hasUpdatePermission || hasUpdateProfilePermission;
      showResetPassword = hasUpdatePermission || hasResetPasswordPermission;
      canManage = permissions.manageGroups();
      canManageWithReference = permissions.manageGroupswithReferencePermission();
      if (canManage || canManageWithReference) {
        canManageGroups = true;
      }
      userName = getTitleOrPrimaryAttributeValue(user.attributes, meta);
      if (meta) {
        showEnableToggle = Object.prototype.hasOwnProperty.call(meta.attributes, 'ds-pwp-account-disabled');
      }
      pageHeaderChildren = (
        <AdminActions
          userId={user.id}
          isLocked={user.attributes['dadmin-account-locked'] ? user.attributes['dadmin-account-locked'][0] : false}
          isDisabled={_.isUndefined(user.attributes['ds-pwp-account-disabled']) ? false : user.attributes['ds-pwp-account-disabled'][0]}
          showEnableToggle={showEnableToggle}
          showResetPasswordButton={showResetPassword}
        />
      );
    }

    return (
      <Spinner show={isLoading}>
        <EditModelPage
          profilePageModel={MODEL_TYPES.USERS}
          model={user}
          modelMeta={meta}
          modelDisplayAttributeValue={userName}
          membershipResults={userGroups}
          editModelAction={this.props.editUserAction}
          onSearch={this.handleSearch}
          onClearSearch={this.handleClearSearch}
          pageHeaderChildren={pageHeaderChildren}
          error={error}
          breadcrumbPath={routes.USERS_SEARCH_ROUTE}
          canUpdateModel={canUpdate}
          canManageMembershipForModel={canManageGroups}
          onUnlink={this.handleUnlinkObjects}
          onLink={this.handleLinkObjects}
          getModelType={this.getUser}
        />
      </Spinner>
    );
  }
}

UserProfilePage.propTypes = {
  editUserAction: PropTypes.func.isRequired,
  getUserAction: PropTypes.func.isRequired,
  getSearchResults: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      'dadmin-account-locked': PropTypes.instanceOf(Array),
      'ds-pwp-account-disabled': PropTypes.instanceOf(Array),
    }).isRequired,
    included: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  meta: PropTypes.shape({
    attributes: PropTypes.shape({}).isRequired,
    included: PropTypes.shape({}),
  }),
  error: PropTypes.string,
  userGroups: PropTypes.shape({
    dataType: PropTypes.string,
    queryString: PropTypes.string.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({})),
    included: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({}),
    primarySearchAttribute: PropTypes.string,
    groupTitleAttribute: PropTypes.string,
    error: PropTypes.string,
  }),
  newUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      'dadmin-generated-password': PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }),
  unlinkObjects: PropTypes.func.isRequired,
  linkObjects: PropTypes.func.isRequired,
};

UserProfilePage.defaultProps = {
  user: undefined,
  error: undefined,
  meta: undefined,
  userGroups: undefined,
  newUser: undefined,
};

function mapStateToProps(state) {
  const {
    user,
    included,
    userGroups,
    error,
    meta,
    newUser,
  } = state.user;

  // Ensure we have a valid user to display
  const requiredUserKeys = ['id', 'type', 'attributes'];
  let completeUser;
  if (user && requiredUserKeys.every(key => key in user.data)) {
    completeUser = { ...user.data, included };
  }

  return {
    error,
    meta,
    userGroups,
    user: completeUser,
    newUser: _.get(newUser, 'data'),
  };
}

export default connect(
  mapStateToProps,
  {
    getUserAction,
    editUserAction,
    clearNewUserAction,
    getSearchResults,
    unlinkObjects,
    linkObjects,
  },
)(UserProfilePage);
