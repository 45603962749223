import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import endpoints from '../../../utils/apiEndpoints';
import * as types from '../actionTypes';
import {
  dispatchErrorMessage,
  dispatchInfoMessage,
  dispatchWarningMessage,
  generateHttpErrorMessage,
  getPrimarySearchAttributeKey,
  getTitleAttributeKey,
  sortData,
  logErrorToConsole,
} from '../../../utils/helpers';
import { getResourceById } from '../../../utils/resourceTypes';
import { MODEL_TYPES } from '../../../utils/modelTypes';
import axios from '../../../utils/axiosInstance';
import { createAction, createActionType, FAILURE, REQUEST, SUCCESS } from '../index';
import { setModelFormAction } from '../modelForm/modelFormActions';

export const searchActions = {
  request: REFERENCE => createAction(types[`${createActionType(REFERENCE)}SEARCH_RESULTS`][REQUEST], { REFERENCE }),
  success: (data, REFERENCE) => createAction(types[`${createActionType(REFERENCE)}SEARCH_RESULTS`][SUCCESS], { data, REFERENCE }),
  failure: (data, REFERENCE) => createAction(types[`${createActionType(REFERENCE)}SEARCH_RESULTS`][FAILURE], { data, REFERENCE }),
};

export function getSearchResults(queryString, options = {}) {
  return async (dispatch, getState) => {
    const subquery = _.get(options, 'subquery', false);
    const resourceTypeId = _.get(options, 'resourceTypeId', false);
    const profilePageModel = _.get(options, 'profilePageModel', undefined);
    const createSelected = _.get(options, 'createSelected');
    const REFERENCE = _.get(options, 'REFERENCE', '');
    const reference = _.get(options, 'reference', null);
    let resourceId = subquery
      ? _.get(getState(), 'resourceTypes.secondarySelected.id')
      : _.get(getState(), 'resourceTypes.selected.id');
    resourceId = createSelected
      ? _.get(getState(), 'resourceTypes.create.selected.id')
      : resourceId;
    resourceId = resourceTypeId || resourceId;
    const endpoint = endpoints.endpointForResource(resourceId);
    const resource = getResourceById(resourceId);
    const resourceType = _.get(resource, 'attributes.resourceType', undefined);
    const dataType = resourceType && `${resourceType}s`;
    const includeGroupMeta = _.get(options, 'includeGroupMeta', false);

    try {
      dispatch(searchActions.request(REFERENCE));

      const params = {
        filter: queryString,
      };

      if (includeGroupMeta) {
        params.includeGroupMeta = includeGroupMeta;
      }

      const response = await axios.get(endpoint, {
        params,
      });

      let results = response.data.data;
      const { meta, included } = response.data;
      const metaAttrs = meta && Object.keys(meta.attributes);
      const searchAttrs = metaAttrs.filter(attr => meta.attributes[attr].searchAttribute === true);

      if (subquery) {
        const sortKey = dataType === MODEL_TYPES.USERS
          ? getPrimarySearchAttributeKey(meta)
          : getTitleAttributeKey(meta);
        const sort = { key: sortKey, order: 'asc' };
        results = sortData(results, sort);
      }

      if (reference) {
        const { attr, errors } = options;
        const isEmpty = _.isEmpty(results.filter(item => item.id === reference.id));
        if (isEmpty) errors[attr] = isEmpty;
        dispatch(setModelFormAction({ reference: { errors } }));
      }

      dispatch(searchActions.success({
        dataType,
        queryString,
        results,
        included,
        meta,
        subquery,
        profilePageModel,
      }, REFERENCE));

      if (response.data.data.length === 0 && !options.skipMessage) {
        if (REFERENCE !== '') {
          dispatch({
            type: types.MODEL_FORM_SET,
            data: {
              message: REFERENCE,
            },
          });
        }

        dispatchInfoMessage(
          dispatch,
          <FormattedMessage
            id="page.search.warning.no-results"
            values={{
              queryString,
            }}
          />,
        );
      }

      if (
        _.isUndefined(getPrimarySearchAttributeKey(meta))
        && _.isUndefined(getTitleAttributeKey(meta))
        && searchAttrs.length === 0
      ) {
        dispatchWarningMessage(
          dispatch,
          <FormattedMessage
            id="user.missing-display-search-attributes"
          />,
          0,
        );
      }

      return Promise.resolve(response);
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatch(searchActions.failure({
        dataType,
        queryString,
        error: intlKey,
      }, REFERENCE));

      if (!options.skipMessage) {
        dispatchErrorMessage(
          dispatch,
          <FormattedMessage
            id={intlKey}
            values={{
              statusCode,
            }}
          />,
        );
      }

      return Promise.reject(error);
    }
  };
}

export function clearSearchResultsAction(options = {}) {
  return async (dispatch) => {
    dispatch({
      type: types.CLEAR_SEARCH_RESULTS,
      data: {
        subquery: ('subquery' in options && options.subquery),
      },
    });
  };
}

export function sortSearchResultsAction(sortBy, dataType = 'users') {
  return async (dispatch) => {
    dispatch({
      type: types.SORT_SEARCH_RESULTS,
      data: {
        dataType,
        sortBy,
      },
    });
  };
}

export function searchGroupMembersAction(
  queryString, groupId, resourceType, orgs, groupResourceType = false,
) {
  const requestParams = {
    filter: queryString,
    resourceType,
    orgs,
  };
  Object.keys(requestParams).forEach(
    key => (requestParams[key] == null || !requestParams[key].length) && delete requestParams[key]);
  return async (dispatch, getState) => {
    try {
      const resourceId = _.get(getState(), 'resourceTypes.selected.id');
      const endpoint = endpoints.endpointForResource(resourceId, {
        id: groupId,
        members: true,
        groupResourceType,
      });
      const res = await axios.get(endpoint, {
        params: requestParams,
      });

      const { meta } = res.data;
      const { directMembers, indirectMembers } = res.data.data;
      const primary = getPrimarySearchAttributeKey(meta);
      const sort = { key: primary, order: 'asc' };
      const allMembers = [...directMembers, ...indirectMembers];

      const members = sortData(allMembers, sort);
      const nonMembers = res.data.data?.nonMembers ? sortData(res.data.data.nonMembers, sort) : [];

      dispatch({
        type: types.SEARCH_GROUP_MEMBERS_SUCCESS,
        data: {
          groupId,
          queryString,
          resourceType,
          orgs,
          members,
          nonMembers,
          meta,
        },
      });
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatch({
        type: types.SEARCH_GROUP_MEMBERS_ERROR,
        data: {
          groupId,
          queryString,
          error: intlKey,
        },
      });

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );
    }
  };
}
