// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// UI-Library
import Button from 'ui-library/lib/components/buttons/Button';
import Modal from 'ui-library/lib/components/general/Modal';
import AlertModal from '../AlertModal/AlertModal';

// Store
import { setModalAction } from '../../../../store/actions/modal/modal';
import { clearModelFormAction } from '../../../../store/actions/modelForm/modelFormActions';

// Utils
import intlShape from '../../../../utils/intlPropType';
import { removeKeyValue } from '../../../../utils/helpers';

// Styling
import './ModalWithButton.css';

const initialState = {
  hasAlertSave: false,
  isAlertModalOpen: false,
  isModalOpen: false,
};

export class ModalWithButton extends Component {
  state = initialState

  setAlertSave = () => {
    this.setState({ hasAlertSave: true });
  }

  openModal = () => {
    this.props.setModalAction({ open: true });
    this.setState({ isModalOpen: true });
  }

  closeModal = () => {
    const { dirty } = this.props;
    if (dirty) {
      this.openAlertModal();
    } else {
      this.props.setModalAction({ open: false });
      this.setState(initialState);
    }
  }

  openAlertModal = () => {
    this.setState({ isAlertModalOpen: true });
  }

  closeAlertModal = () => {
    this.setState({
      hasAlertSave: false,
      isAlertModalOpen: false,
    });
  }

  render() {
    const { hasAlertSave, isAlertModalOpen, isModalOpen } = this.state;
    const {
      intl,
      buttonProps,
      children,
      className,
      closeOnBgClick,
      maximize,
      modalTitle,
      showButton,
      valid,
    } = this.props;
    const { label } = buttonProps;

    return (
      <>
        <AlertModal
          isOpen={isAlertModalOpen}
          closeText={intl.formatMessage({ id: 'common.cancel' })}
          cancelText={intl.formatMessage({ id: 'common.discard-changes' })}
          saveText={intl.formatMessage({ id: 'common.save' })}
          modalDescription={intl.formatMessage({ id: 'containers.model-form.alert-modal.message' })}
          modalTitle={intl.formatMessage({ id: 'containers.model-form.alert-modal.title' })}
          onClose={this.closeAlertModal}
          onCancel={async () => {
            await this.props.setModalAction({ dirty: false });
            this.closeAlertModal();
            this.closeModal();
          }}
          onSave={this.setAlertSave}
          preventSave={!valid}
        />

        {
          showButton &&
          <Button
            {...buttonProps}
            label={(label) ? intl.formatMessage({ id: label.id }, removeKeyValue(label, 'id')) : ''}
            onClick={this.openModal}
          />
        }

        <Modal
          modalTitle={intl.formatMessage({ id: modalTitle.id }, removeKeyValue(modalTitle, 'id'))}
          className={className}
          closeOnBgClick={closeOnBgClick}
          expanded={isModalOpen}
          onClose={this.closeModal}
          maximize={maximize}
        >
          {React.cloneElement(
            children,
            {
              closeAlertModal: this.closeAlertModal,
              closeModal: this.closeModal,
              openAlertModal: this.openAlertModal,
              hasAlertSave,
              isAlertModalOpen,
              isModalOpen,
            },
          )}
        </Modal>
      </>
    );
  }
}

ModalWithButton.propTypes = {
  intl: intlShape.isRequired,
  buttonProps: PropTypes.shape({
    label: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  clearModelFormAction: PropTypes.func.isRequired,
  closeOnBgClick: PropTypes.bool,
  error: PropTypes.string,
  dirty: PropTypes.bool.isRequired,
  setModalAction: PropTypes.func.isRequired,
  maximize: PropTypes.bool,
  modalTitle: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  showButton: PropTypes.bool,
  valid: PropTypes.bool.isRequired,
};

ModalWithButton.defaultProps = {
  buttonProps: {
    label: { id: 'common.view' },
  },
  className: '',
  closeOnBgClick: false,
  error: undefined,
  maximize: false,
  modalTitle: {
    id: 'components.modal.title',
    title: '',
  },
  showButton: true,
};

function mapStateToProps(state) {
  const { dirty, valid } = state.modal;

  return {
    dirty,
    valid,
  };
}

export default injectIntl(connect(
  mapStateToProps,
  {
    clearModelFormAction,
    setModalAction,
  },
)(ModalWithButton));

