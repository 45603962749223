import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { BASE_ROUTE, NOT_FOUND_ROUTE } from '../../../utils/routes';
import permissions, { PERMISSIONS } from '../../../utils/permissions/permissions';
import './RequireAuth.css';

/**
 * Higher order component used to hide a component if a user is not signed in.
 * Will redirect the user to the signin route if not signed in.
 * Useful for showing components that only should be seen by signed in users.
 */

export class Authentication extends Component {
  componentDidMount() {
    const {
      authenticated,
      isLoadingUser,
      redirect,
      history,
    } = this.props;

    if (!authenticated && !isLoadingUser && redirect) {
      history.replace(BASE_ROUTE);
    }
  }

  shouldComponentUpdate(nextProps) {
    /*
     * Do not update if we are simply refetching the user for silent renewal
     * purposes. Otherwise, child components may re-render and change without
     * action by the user.
     */
    return !(this.props.authenticated
      && nextProps.authenticated
      && !this.props.isLoadingUser
      && nextProps.isLoadingUser);
  }

  componentDidUpdate() {
    const {
      authenticated,
      isLoadingUser,
      redirect,
      history,
    } = this.props;

    if (!authenticated && !isLoadingUser && redirect) {
      history.replace(BASE_ROUTE);
    }
  }

  render() {
    const {
      authenticated,
      composedComponent,
      isLoadingUser,
      isMessageContainer,
      history,
      match: {
        params,
      },
      modelForm,
      redirect,
      signoutPage,
    } = this.props;
    const ComposedComponent = composedComponent;
    let authComponent = authenticated && !isLoadingUser ?
      <ComposedComponent {...this.props} /> : null;
    const hasModelFormMessages = modelForm.error || modelForm.message;

    if (!_.isEmpty(params)) {
      const { model, type } = params;
      const modelType = _.capitalize(model);
      const hasPermissions = (modelType)
        ? permissions[`manage${modelType}`]()
        : permissions.hasPermission(PERMISSIONS.READ, type);

      if (!hasPermissions) {
        history.replace(NOT_FOUND_ROUTE);
        authComponent = null;
      }
    }

    if (isMessageContainer && hasModelFormMessages) {
      authComponent = null;
    }

    return (
      <div className={`${redirect && !signoutPage ? 'page-component' : 'non-page-component'}`}>
        {authComponent}
      </div>
    );
  }
}

Authentication.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  authenticated: PropTypes.bool.isRequired,
  isLoadingUser: PropTypes.bool.isRequired,
  isMessageContainer: PropTypes.bool,
  composedComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      model: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
  modelForm: PropTypes.shape({
    error: PropTypes.string,
    message: PropTypes.string,
  }),
  redirect: PropTypes.bool,
  signoutPage: PropTypes.bool,
};

Authentication.defaultProps = {
  isMessageContainer: false,
  modelForm: {
    error: undefined,
    message: undefined,
  },
  redirect: true,
  signoutPage: false,
};

export default function RequireAuth(
  ComposedComponent,
  redirect = true,
  signoutPage = false,
  isMessageContainer = false,
) {
  function mapStateToProps(state) {
    return {
      authenticated: state.oidc.user !== null
        && !state.oidc.user.expired
        && (signoutPage ? true : !!state.authentication.isAuthenticated),
      isLoadingUser: state.oidc.isLoadingUser,
      isMessageContainer,
      composedComponent: ComposedComponent,
      modelForm: state.modelForm,
      signoutPage,
      redirect,
    };
  }

  const connectedComponent = connect(mapStateToProps)(Authentication);
  return withRouter(connectedComponent);
}
