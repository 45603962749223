// Vendors
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';

// UI-Library
import Spinner from 'ui-library/lib/components/general/Spinner';
import RockerButton from 'ui-library/lib/components/forms/RockerButton';

// Components
import ModelSummary from '../../shared/summaries/ModelSummary/ModelSummary';
import UserGroupsSummary from '../../shared/summaries/UserGroupsSummary/UserGroupsSummary';
import AccountInfoSummary from '../../shared/summaries/AccountInfoSummary/AccountInfoSummary';
import GroupMembershipSummary from '../../shared/summaries/GroupMembershipSummary/GroupMembershipSummary';

// Store
import { getUserAction } from '../../../store/actions/userActions';
import { getGroupAction, getGroupMembersAction } from '../../../store/actions/groups/groups';
import { getGenericAction } from '../../../store/actions/generics';

// Utils
import { blacklistedDisplayAttrs, getTitleOrPrimaryAttributeKey, hasExceededMemberLimit } from '../../../utils/helpers';
import permissions from '../../../utils/permissions/permissions';
import { MODEL_TYPES, PROP_MODEL_TYPES } from '../../../utils/modelTypes';
import { getResourceById, RESOURCE_TYPES } from '../../../utils/resourceTypes';
import intlShape from '../../../utils/intlPropType';

// Styling
import './AccordionProfile.css';

export class AccordionProfile extends Component {
  constructor(props) {
    super(props);

    this.labels = [
      props.intl.formatMessage({ id: 'components.rocker-button.profile' }),
      props.intl.formatMessage({ id: 'components.rocker-button.groups' }),
    ];

    this.getGroups = this.getGroups.bind(this);
    this.handleButtonChange = this.handleButtonChange.bind(this);
    this.profileSwitch = this.profileSwitch.bind(this);

    this.state = {
      selectedIndex: 0,
      isLoading: false,
    };
  }

  async getGroups() {
    const { id, type } = this.props.item;
    await this.props.getUserAction(id, type);
    this.setState({ isLoading: false });
  }

  async getGroupMembers() {
    const { id, type } = this.props.item;
    await this.props.getGroupAction(id, type);
    await this.props.getGroupMembersAction(id);
    this.setState({ isLoading: false });
  }

  async getGenericGroups() {
    const { id, type } = this.props.item;
    await this.props.getGenericAction(id, type);
    this.setState({ isLoading: false });
  }

  handleButtonChange(e) {
    const selectedIndex = _.get(e, 'index', 0);
    let isLoading = false;
    const { groupsPermission, referencePermission } = this.props;
    if (selectedIndex === 1 && (groupsPermission || referencePermission)) {
      isLoading = true;
      this.props.modelType === MODEL_TYPES.USERS && this.getGroups();
      this.props.modelType === MODEL_TYPES.GROUPS && this.getGroupMembers();
      this.props.modelType === MODEL_TYPES.GENERICS && this.getGenericGroups();
    }

    this.setState({ selectedIndex, isLoading });
  }

  profileContent() {
    const { blacklist, meta } = this.props;
    const { attributes } = this.props.item;
    return (
      <ModelSummary
        attributes={attributes}
        blacklist={blacklist}
        meta={meta}
      />
    );
  }

  groupsContent() {
    const { included } = this.props.item;
    const {
      groupsPermission,
      referencePermission,
      meta,
    } = this.props;
    const groups =
      included &&
      included.length &&
      included.filter((obj) => {
        const resource = getResourceById(obj.type);
        return resource.attributes.resourceType === RESOURCE_TYPES.GROUP;
      });

    if (groups && groups.length) {
      return (
        <UserGroupsSummary
          groups={groups}
          groupsMeta={meta.included}
        />
      );
    }

    const groupsMessage = (groupsPermission || referencePermission)
      ? 'components.accordion-profile.no-groups' : 'components.accordion-profile.no-groups-permission';

    return (
      <div className="groups-content no-groups">
        <FormattedMessage id={this.props.modelType === MODEL_TYPES.USERS
          ? groupsMessage
          : 'components.accordion-profile.generic-no-groups'}
        />
      </div>
    );
  }

  passwordContent() {
    const {
      item: { attributes },
      meta,
    } = this.props;

    const passwordHistory = attributes['ds-pwp-state-json'];
    return (
      <AccountInfoSummary
        name={attributes[getTitleOrPrimaryAttributeKey(meta)][0]}
        passwordHistory={passwordHistory}
      />
    );
  }

  membersContent() {
    const {
      groupMembers,
      groupMembersMeta,
      item,
      meta,
    } = this.props;
    const memberObj = groupMembers.find(member => member.groupId === item.id);
    const memberList = memberObj && memberObj.members;
    const membersExceeded = hasExceededMemberLimit({ meta: groupMembersMeta });

    return (
      <GroupMembershipSummary
        groupMeta={meta}
        memberList={memberList}
        model={this.props.item}
        sizeLimitExceeded={membersExceeded}
      />
    );
  }

  displayAccordionContent() {
    const { selectedIndex, isLoading } = this.state;
    let content;
    const { groupsPermission, referencePermission } = this.props;
    if (selectedIndex === 0) {
      content = this.profileContent();
    } else if (selectedIndex === 1 && (groupsPermission || referencePermission)) {
      if (this.props.modelType === MODEL_TYPES.GROUPS) {
        content = this.membersContent();
      } else {
        content = this.groupsContent();
      }
    } else {
      content = this.passwordContent();
    }

    return (
      <Spinner show={isLoading}>
        {content}
      </Spinner>
    );
  }

  profileSwitch() {
    const {
      intl,
      modelType,
      groupsPermission,
      referencePermission,
    } = this.props;

    if (modelType === MODEL_TYPES.USERS) {
      if (!this.labels.includes('Account Information')) {
        this.labels.push(intl.formatMessage({ id: 'components.rocker-button.account' }));
      }
    }

    /*
     * if user does not have group permissions or if reference is disabled,
     * remove the group membership tab
     */
    if (!groupsPermission && !referencePermission) {
      const index = this.labels.indexOf('Group Membership');
      if (index > -1) {
        this.labels.splice(index, 1);
      }
    }

    return (
      <RockerButton
        selectedIndex={this.state.selectedIndex}
        onValueChange={this.handleButtonChange}
        labels={this.labels}
      />
    );
  }

  render() {
    return (
      <Fragment>
        {this.profileSwitch()}
        <div className="AccordionProfile">
          {this.displayAccordionContent()}
        </div>
      </Fragment>
    );
  }
}

AccordionProfile.propTypes = {
  intl: intlShape.isRequired,
  modelType: PropTypes.oneOf(PROP_MODEL_TYPES).isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      'ds-pwp-state-json': PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    included: PropTypes.arrayOf(PropTypes.shape({})),
    relationships: PropTypes.shape({
      members: PropTypes.shape({}),
    }),
  }).isRequired,
  blacklist: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.shape({
    attributes: PropTypes.shape({}).isRequired,
    included: PropTypes.shape({}),
  }).isRequired,
  getUserAction: PropTypes.func.isRequired,
  getGroupAction: PropTypes.func.isRequired,
  getGroupMembersAction: PropTypes.func.isRequired,
  getGenericAction: PropTypes.func.isRequired,
  groupsPermission: PropTypes.bool.isRequired,
  groupMembers: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    error: PropTypes.string,
  })),
  groupMembersMeta: PropTypes.shape({
    directMembers: PropTypes.shape({}),
    indirectMembers: PropTypes.shape({}),
  }),
  referencePermission: PropTypes.bool.isRequired,
};

AccordionProfile.defaultProps = {
  blacklist: blacklistedDisplayAttrs,
  groupMembers: [],
  groupMembersMeta: undefined,
};

function mapStateToProps(state) {
  const { groupMembers } = state.group;
  const groupMembersMeta = _.get(state, 'group.group.data.relationships.members.meta');
  return {
    groupsPermission: permissions.manageGroups(),
    groupMembers,
    groupMembersMeta,
  };
}

export default injectIntl(connect(
  mapStateToProps,
  {
    getUserAction,
    getGroupAction,
    getGroupMembersAction,
    getGenericAction,
  },
)(AccordionProfile));
