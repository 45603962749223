import React from 'react';
import PropTypes from 'prop-types';

import './RowButtons.css';
import ModalWithButton from '../../modals/ModalWithButton/ModalWithButton';
import ModifyMemberButton from '../ModifyMemberButton/ModifyMemberButton';
import ModelSummary from '../../summaries/ModelSummary/ModelSummary';
import { PROP_MODEL_TYPES } from '../../../../utils/modelTypes';

const RowButtons = (props) => {
  const {
    buttonProps,
    rowProps,
    ControlButton,
    secondarySelectedPermissions,
  } = props;
  const { modalInfo } = rowProps;
  const baseLabel = 'components.modal';

  return (
    <div className="RowButtons">
      <ModalWithButton
        buttonProps={{
          inline: true,
          label: {
            id: `${baseLabel}.button.label`,
            label: 'View Profile',
          },
        }}
        modalTitle={{
          id: `${baseLabel}.title`,
          title: 'Profile',
        }}
      >
        <ModelSummary
          id={modalInfo.item.id}
          attributes={modalInfo.item.attributes}
          meta={modalInfo.attributeMeta}
          showCategories={false}
        />
      </ModalWithButton>
      <ModifyMemberButton
        buttonProps={buttonProps}
        rowProps={rowProps}
        ControlButton={ControlButton}
        secondarySelectedPermissions={secondarySelectedPermissions}
      />
    </div>
  );
};

RowButtons.propTypes = {
  buttonProps: PropTypes.shape({}).isRequired,
  rowProps: PropTypes.shape({
    modalInfo: PropTypes.shape({
      attributeMeta: PropTypes.shape({}).isRequired,
      displayName: PropTypes.string.isRequired,
      item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        attributes: PropTypes.shape({}).isRequired,
      }).isRequired,
    }).isRequired,
    profileInfo: PropTypes.shape({
      container: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        attributes: PropTypes.shape({}).isRequired,
        included: PropTypes.arrayOf(PropTypes.shape({})),
        relationships: PropTypes.shape({}),
      }),
      profilePageModel: PropTypes.oneOf(PROP_MODEL_TYPES).isRequired,
      target: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        attributes: PropTypes.shape({}).isRequired,
        included: PropTypes.arrayOf(PropTypes.shape({})),
        relationships: PropTypes.shape({}),
      }),
    }).isRequired,
  }).isRequired,
  ControlButton: PropTypes.elementType.isRequired,
  secondarySelectedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RowButtons;
