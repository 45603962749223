import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PillButton } from 'ui-library/lib/components/rows/expandable-row/Accessories';
import ConfirmTooltip from 'ui-library/lib/components/tooltips/ConfirmTooltip';

const PillButtonWithConfirmation = (props) => {
  const {
    buttonLabel,
    confirmationMessage,
    confirmationTitle,
    confirmationButtonType,
    onConfirm,
  } = props;

  const [showConfirmation, setShowConfimation] = useState(false);

  const handleToggle = () => setShowConfimation(!showConfirmation);

  const handleConfirm = () => {
    onConfirm();

    setShowConfimation(false);
  };

  return (
    <div>
      <ConfirmTooltip
        label={<PillButton label={buttonLabel} />}
        title={confirmationTitle}
        open={showConfirmation}
        onToggle={handleToggle}
        onCancel={handleToggle}
        onConfirm={handleConfirm}
        buttonLabel="Confirm"
        cancelText="Cancel"
        buttonType={confirmationButtonType}
      >
        { confirmationMessage }
      </ConfirmTooltip>
    </div>
  );
};

PillButtonWithConfirmation.propTypes = {
  buttonLabel: PropTypes.string,
  confirmationMessage: PropTypes.string,
  confirmationTitle: PropTypes.string,
  confirmationButtonType: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
};

PillButtonWithConfirmation.defaultProps = {
  buttonLabel: '',
  confirmationMessage: '',
  confirmationTitle: '',
  confirmationButtonType: 'primary',
};

export default PillButtonWithConfirmation;
