import AuthCallbackError from '../../../errors/AuthCallbackError';
import OidcConnectionError from '../../../errors/OidcConnectionError';
import { logErrorToConsole } from '../../../utils/helpers';
import * as types from '../actionTypes';
import { createAction, FAILURE, REQUEST, SUCCESS } from '../index';
import { authEndpoint } from '../../../utils/userManager';

const { DADMIN_CLIENT_ID } = window;

export const authenticationActions = {
  request: () => createAction(types.AUTHENTICATION[REQUEST]),
  success: data => createAction(types.AUTHENTICATION[SUCCESS], { data }),
  failure: error => createAction(types.AUTHENTICATION[FAILURE], { error }),
  unauthenticate: () => createAction(types.UNAUTHENTICATE_USER),
};

function getOidcTokenInfo() {
  const oidc = sessionStorage.getItem(`oidc.user:${authEndpoint}:${DADMIN_CLIENT_ID}`);

  try {
    return oidc;
  } catch (error) {
    logErrorToConsole(error);
    return null;
  }
}

export function setIsAuthenticatedAction(oidc = getOidcTokenInfo()) {
  if (oidc === null) {
    return async (dispatch) => {
      dispatch(authenticationActions.failure('missing_oidc_token'));
    };
  }

  return async (dispatch) => {
    dispatch(authenticationActions.success());
  };
}

export function unauthenticateUser() {
  return async (dispatch) => {
    dispatch(authenticationActions.unauthenticate());
  };
}

export function oidcConnectionFailure(error) {
  logErrorToConsole(error);

  return async (dispatch) => {
    dispatch(authenticationActions.failure(new OidcConnectionError().intlKey));
  };
}

export function authCallbackErrorAction(error) {
  logErrorToConsole(error);

  return async (dispatch) => {
    dispatch(authenticationActions.failure(new AuthCallbackError().intlKey));
  };
}
