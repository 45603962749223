import React from 'react';
import Section from 'ui-library/lib/components/general/Section';

const CategorizedFormFields = (props) => {
  const {
    categorizedAttrs,
    createFormFields,
    hasUserPassword,
    isUserPasswordCategorized,
  } = props;
  let passwordField;

  if (hasUserPassword && !isUserPasswordCategorized) {
    passwordField = createFormFields('userPassword', 0);
  }

  const categorizedFields = categorizedAttrs.map(category => (
    <div className="attribute-category" key={category.name}>
      <Section
        title={category.name}
        initialState={{
            expanded: true,
          }}
      >
        {
            category.attributes.map((item, index) => {
              const attr = item.name;
              const key = index;
              return createFormFields(attr, key);
            })
          }
      </Section>
    </div>
  ));

  return passwordField ? [passwordField, ...categorizedFields] : categorizedFields;
};

export default CategorizedFormFields;
